import {
  Container,
  Box,
  Flex,
  Stack,
  Circle,
  useMediaQuery,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

const footerLinks = [
  {
    segment: [
      {
        wording: 'Terms and Conditions',
        url: 'https://passentry.com/terms-and-conditions/',
      },
      // { wording: 'How It Works', url: '/how-it-works' },
    ],
  },
  {
    segment: [
      {
        wording: 'Privacy Policy',
        url: 'https://passentry.com/privacy-policy/',
      },
      { wording: 'Cookie Policy', url: 'https://passentry.com/cookie-policy/' },
      // { wording: 'How It Works', url: '/how-it-works' },
    ],
  },
  {
    segment: [
      // { wording: 'FAQ', url: '/faq' },
      {
        wording: 'API Documentation',
        url: 'https://developers.passentry.com/',
      },
      { wording: 'API Status', url: 'https://passentry.appsignal-status.com/' },
      { wording: 'Contact Us', url: 'https://passentry.com/contact-us/' },
    ],
  },
];

const Footer = (props) => {
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');

  return (
    <Box as="footer" position="relative">
      <Container maxW={props.fullWidth ? 'full' : '1192px'}>
        <Flex
          flexDirection={{ base: 'column-reverse', xl: 'row' }}
          alignItems="center"
          justifyContent="right"
          pb={10}
          pt={14}
          // public footer
          position={props.public ? 'absolute' : ''}
          zIndex="10"
          bottom="0"
          left="0"
          right={{ base: '0', lg: '3rem' }}
          marginRight={props.public ? 'auto' : ''}
          marginLeft={props.public ? 'auto' : ''}
        >
          {/* <Box
            fontSize="12px"
            mt={{ base: '12px', xl: '0px' }}
            textAlign={{ base: 'center', md: 'left' }}
            color={modeTextColor}
          >
            © {new Date().getFullYear()},&nbsp;PassEntry™&nbsp;🍍
            -&nbsp;Patent&nbsp;Pending
          </Box> */}
          <Flex
            flexDirection={{ base: 'column', md: 'row' }}
            justifyContent={{ base: 'center', md: 'left' }}
            className="footerNav"
          >
            {footerLinks.map((footerSegment, index) => {
              return (
                <Stack
                  key={index}
                  flexShrink={0}
                  direction="row"
                  justifyContent={{ base: 'center', md: 'left' }}
                  spacing={{ base: '24px', xl: '30px' }}
                  mb={{ base: '12px', xl: '0px' }}
                  mr={{ base: '0', md: index !== 2 ? '30px' : 0 }}
                >
                  {footerSegment.segment.map((link, index) => {
                    return (
                      <NavLink
                        key={index}
                        to={link.url}
                        className={({ isActive }) =>
                          isActive ? 'active' : 'inactive'
                        }
                      >
                        <Box
                          color={
                            props.footerColorMode === 'dark'
                              ? 'white'
                              : 'secondaryDark'
                          }
                          fontSize="12px"
                          pos="relative"
                        >
                          {link.wording}
                          {index < footerSegment.segment.length - 1 &&
                            !isLargerThanMD && (
                              <Circle
                                size="4px"
                                bg="brand"
                                pos="absolute"
                                right="-14px"
                                top="6px"
                              />
                            )}
                        </Box>
                      </NavLink>
                    );
                  })}
                </Stack>
              );
            })}
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default Footer;
