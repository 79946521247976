import {
  IconButton,
  Link,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Grid,
  GridItem,
  Box,
  Divider,
  Text,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
import moment from 'moment';
import { CustomChevronLeftIcon } from '../../../theme/icons/CustomChevronLeftIcon';

function LiveEventDataListMoreInfo({ cell, children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const modeIconColor = useColorModeValue('secondaryDark08', '#DDDDDD');
  const modeBoxBg = useColorModeValue('white', 'secondaryDark');
  const modeTextBg = useColorModeValue('white', 'primaryDark06');
  const modeLabelBg = useColorModeValue('white', 'primaryDark03');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');

  function handleMoreInfoClick(e) {
    e.stopPropagation();
    onOpen();
  }

  return (
    <>
      <IconButton
        onClick={handleMoreInfoClick}
        icon={
          <CustomChevronLeftIcon
            w="20px"
            h="20px"
            color={modeIconColor}
            transform="rotate(180deg)"
          />
        }
        variant="white"
        size="sm"
        w="36px"
        mr={1}
      />
      <Drawer onClose={onClose} isOpen={isOpen} size="md">
        <DrawerOverlay />
        <DrawerContent bg={modeBoxBg}>
          <DrawerCloseButton />
          <DrawerHeader
            borderBottomWidth="1px"
            borderBottomColor={modeBorderColor}
            pt={{ base: '16px', lg: '56px' }}
            pb={3}
            bg={modeTextBg}
          >
            Live reader taps overview
          </DrawerHeader>
          <DrawerBody p="0" bg={modeLabelBg}>
            <Grid templateColumns={{ base: '150px 1fr', md: '192px 1fr' }} gap={0}>
              <GridItem
                fontFamily="Inter Medium, sans-serif"
                fontSize="12px"
                color={modeIconColor}
                textTransform="uppercase"
                lineHeight="21px"
                padding="16px 4px 16px 22px"
              >
                Pass
              </GridItem>
              <GridItem
                fontFamily="Inter Bold, sans-serif"
                fontSize="14px"
                padding="16px 4px 16px 22px"
                bg="quinaryBackground"
              >
                <Text wordBreak="break-word">{cell.row.original.passHolderName}</Text>
              </GridItem>
              <GridItem colSpan={2} w="full">
                <Divider borderColor={modeBorderColor} />
              </GridItem>

              <GridItem
                fontFamily="Inter Medium, sans-serif"
                fontSize="12px"
                color={modeIconColor}
                textTransform="uppercase"
                lineHeight="21px"
                padding="16px 4px 16px 22px"
              >
                Event name
              </GridItem>
              <GridItem
                fontSize="14px"
                padding="16px 4px 16px 22px"
                bg="quinaryBackground"
              >
                <Text wordBreak="break-word">{cell.row.original.eventName}</Text>
              </GridItem>
              <GridItem colSpan={2} w="full">
                <Divider borderColor={modeBorderColor} />
              </GridItem>

              <GridItem
                fontFamily="Inter Medium, sans-serif"
                fontSize="12px"
                color={modeIconColor}
                textTransform="uppercase"
                lineHeight="21px"
                padding="16px 4px 16px 22px"
              >
                Reader name
              </GridItem>
              <GridItem
                fontSize="14px"
                padding="16px 4px 16px 22px"
                bg="quinaryBackground"
              >
                <Text wordBreak="break-word">{cell.row.original.readerName}</Text>
              </GridItem>
              <GridItem colSpan={2} w="full">
                <Divider borderColor={modeBorderColor} />
              </GridItem>

              <GridItem
                fontFamily="Inter Medium, sans-serif"
                fontSize="12px"
                color={modeIconColor}
                textTransform="uppercase"
                lineHeight="21px"
                padding="16px 4px 16px 22px"
              >
                Reader ID
              </GridItem>
              <GridItem
                fontSize="14px"
                padding="16px 4px 16px 22px"
                bg="quinaryBackground"
              >
                <Text wordBreak="break-word">{cell.row.original.readerId}</Text>
              </GridItem>
              <GridItem colSpan={2} w="full">
                <Divider borderColor={modeBorderColor} />
              </GridItem>

              <GridItem
                fontFamily="Inter Medium, sans-serif"
                fontSize="12px"
                color={modeIconColor}
                textTransform="uppercase"
                lineHeight="21px"
                padding="16px 4px 16px 22px"
              >
                Status
              </GridItem>
              <GridItem
                fontSize="14px"
                padding="16px 4px 16px 22px"
                bg="quinaryBackground"
              >
                <Box display="inline-block">{children}</Box>
              </GridItem>

              <GridItem colSpan={2} w="full">
                <Divider borderColor={modeBorderColor} />
              </GridItem>

              <GridItem
                fontFamily="Inter Medium, sans-serif"
                fontSize="12px"
                color={modeIconColor}
                textTransform="uppercase"
                lineHeight="21px"
                padding="16px 4px 16px 22px"
              >
                Time
              </GridItem>
              <GridItem
                fontSize="14px"
                padding="16px 4px 16px 22px"
                bg="quinaryBackground"
                wordBreak="break-word"
              >
                {cell.value !== null ? cell.row.original.time : ''}
              </GridItem>

              <GridItem colSpan={2} w="full">
                <Divider borderColor={modeBorderColor} />
              </GridItem>
            </Grid>
          </DrawerBody>
          <DrawerFooter
            borderTopWidth="1px"
            borderTopColor={modeBorderColor}
            bg={modeTextBg}
          >
            <Link color={modeIconColor} fontSize="14px" onClick={onClose} mr="30px">
              <Text textStyle="headingFamilyMedium">Back to list</Text>
            </Link>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default LiveEventDataListMoreInfo;
