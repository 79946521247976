import React, { useContext } from 'react';
import {
  Button,
  Flex,
  Heading,
  Spacer,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { MeContext } from '../../../context/MeContext';
import { Link as RouterLink } from 'react-router-dom';

function MessagesHeader() {
  const meCtx = useContext(MeContext);
  const isSetUpConfirmed = meCtx?.isSetUpConfirmed() ?? true;
  const { filterEntity } = meCtx.state;
  const multipleEntities = meCtx?.state?.entities?.length > 1;
  const bgTooltip = useColorModeValue('secondaryDark', '#dddddd');
  const textTooltip = useColorModeValue('#ffffff', 'primaryDark');

  return (
    <>
      <Flex
        flexDirection={{ base: 'column', sm: 'row' }}
        flexWrap="wrap"
        mt="15px"
        pt="9px"
        minH="54px"
      >
        <Flex
          gap={{ base: '10px' }}
          alignItems="center"
          justifyContent="flex-start"
          flexWrap="wrap"
          mb={{ base: '10px', sm: '0px' }}
        >
          <Heading alignSelf={{ base: 'flex-start', sm: 'center' }}>
            New message
          </Heading>
        </Flex>
      </Flex>
    </>
  );
}

export default MessagesHeader;
