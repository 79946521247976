import { createContext, useEffect, useState } from 'react';
import {
  LOGO_BASE64,
  ICON_BASE64,
  BANNER_BASE64,
  THUMBNAIL_BASE64,
  MONOCHROME_BADGE_BASE64,
} from '../../utils/base64';
import { NUMBER_KEYS } from '../../utils/consts';
import placeholderLogo from '../../assets/images/logo.png';
import placeholderIcon from '../../assets/images/icon.png';
import placeholderBanner from '../../assets/images/banner.png';
import placeholderThumbnail from '../../assets/images/thumbnail.png';
import placeholderMonochromeBadge from '../../assets/images/monochrome-badge.png';

const initialIosLogo = {
  src: null,
  crop: { x: 0, y: 0 },
  zoom: 1,
  // aspect: 16 / 5,
  aspect: 360 / 110,
  mimeType: null,
};

const initialAndroidLogo = {
  src: null,
  crop: { x: 0, y: 0 },
  zoom: 1,
  aspect: 1,
  mimeType: null,
};

const initialThumbnailLogo = {
  src: null,
  crop: { x: 0, y: 0 },
  zoom: 1,
  aspect: 1,
  mimeType: null,
};

const initialBannerLogo = {
  src: null,
  crop: { x: 0, y: 0 },
  zoom: 1,
  // aspect: 1097 / 288, // old aspect ratio
  // aspect: 1032 / 336, // preferred aspect ratio for banner image based on google api guidelines
  // aspect: 1125 / 432, // new adjustments for apple banner
  aspect: 1125 / 294, // new adjustments for apple banner 1132/294
  mimeType: null,
};

const initialGoogleBannerLogo = {
  src: null,
  crop: { x: 0, y: 0 },
  zoom: 1,
  aspect: 1125 / 294,
  mimeType: null,
};

const initialMonochromeBadgeLogo = {
  src: null,
  crop: { x: 0, y: 0 },
  zoom: 1,
  aspect: 1,
  mimeType: null,
};

const PassTemplateContext = createContext({
  templateName: '',
  uuid: '',
  updateTemplateName: () => {},
  templateEntity: '',
  updateTemplateEntity: () => {},
  passType: '',
  updatePassType: () => {},
  description: '',
  updateDescription: () => {},
  headerOneId: '',
  updateHeaderOneId: () => {},
  headerOneLabel: '',
  updateHeaderOneLabel: () => {},
  headerOneValue: '',
  updateHeaderOneValue: () => {},
  headerTwoId: '',
  updateHeaderTwoId: () => {},
  headerTwoLabel: '',
  updateHeaderTwoLabel: () => {},
  headerTwoValue: '',
  updateHeaderTwoValue: () => {},
  headerThreeId: '',
  updateHeaderThreeId: () => {},
  headerThreeLabel: '',
  updateHeaderThreeLabel: () => {},
  headerThreeValue: '',
  updateHeaderThreeValue: () => {},
  centralTitle: '',
  updateCentralTitle: () => {},
  primaryOneId: '',
  updatePrimaryOneId: () => {},
  primaryOneLabel: '',
  updatePrimaryOneLabel: () => {},
  primaryOneValue: '',
  updatePrimaryOneValue: () => {},
  primaryTwoId: '',
  updatePrimaryTwoId: () => {},
  primaryTwoLabel: '',
  updatePrimaryTwoLabel: () => {},
  primaryTwoValue: '',
  updatePrimaryTwoValue: () => {},
  iosLogo: initialIosLogo,
  updateIosLogo: () => {},
  resetIosLogo: () => {},
  thumbnailLogo: initialThumbnailLogo,
  updateThumbnailLogo: () => {},
  resetThumbnailLogo: () => {},
  monochromeBadgeLogo: initialMonochromeBadgeLogo,
  updateMonochromeBadgeLogo: () => {},
  resetMonochromeBadgeLogo: () => {},
  androidLogo: initialAndroidLogo,
  updateAndroidLogo: () => {},
  resetAndroidLogo: () => {},
  bannerLogo: initialBannerLogo,
  updateBannerLogo: () => {},
  resetBannerLogo: () => {},
  googleBannerLogo: initialGoogleBannerLogo,
  updateGoogleBannerLogo: () => {},
  resetGoogleBannerLogo: () => {},
  logoImage: '',
  updateLogoImage: () => {},
  thumbnailImage: '',
  updateThumbnailImage: () => {},
  monochromeBadge: '',
  updateMonochromeBadge: () => {},
  iconLogo: '',
  updateIconLogo: () => {},
  bannerImage: '',
  updateBannerImage: () => {},
  googleBannerImage: '',
  updateGoogleBannerImage: () => {},
  secOneId: '',
  updateSecOneId: () => {},
  secOneLabel: '',
  updateSecOneLabel: () => {},
  secOneValue: '',
  updateSecOneValue: () => {},
  secTwoId: '',
  updateSecTwoId: () => {},
  secTwoLabel: '',
  updateSecTwoLabel: () => {},
  secTwoValue: '',
  updateSecTwoValue: () => {},
  secThreeId: '',
  updateSecThreeId: () => {},
  secThreeLabel: '',
  updateSecThreeLabel: () => {},
  secThreeValue: '',
  updateSecThreeValue: () => {},
  auxOneId: '',
  updateAuxOneId: () => {},
  auxOneLabel: '',
  updateAuxOneLabel: () => {},
  auxOneValue: '',
  updateAuxOneValue: () => {},
  auxTwoId: '',
  updateAuxTwoId: () => {},
  auxTwoLabel: '',
  updateAuxTwoLabel: () => {},
  auxTwoValue: '',
  updateAuxTwoValue: () => {},
  auxThreeId: '',
  updateAuxThreeId: () => {},
  auxThreeLabel: '',
  updateAuxThreeLabel: () => {},
  auxThreeValue: '',
  updateAuxThreeValue: () => {},
  backOneId: '',
  updateBackOneId: () => {},
  backOneLabel: '',
  updateBackOneLabel: () => {},
  backOneValue: '',
  updateBackOneValue: () => {},
  backTwoId: '',
  updateBackTwoId: () => {},
  backTwoLabel: '',
  updateBackTwoLabel: () => {},
  backTwoValue: '',
  updateBackTwoValue: () => {},
  backThreeId: '',
  updateBackThreeId: () => {},
  backThreeLabel: '',
  updateBackThreeLabel: () => {},
  backThreeValue: '',
  updateBackThreeValue: () => {},
  backFourId: '',
  updateBackFourId: () => {},
  backFourLabel: '',
  updateBackFourLabel: () => {},
  backFourValue: '',
  updateBackFourValue: () => {},
  backFiveId: '',
  updateBackFiveId: () => {},
  backFiveLabel: '',
  updateBackFiveLabel: () => {},
  backFiveValue: '',
  updateBackFiveValue: () => {},
  passBackgroundColor: '',
  updatePassBackgroundColor: () => {},
  titleFontColor: '',
  updateTitleFontColor: () => {},
  bodyFontColor: '',
  updateBodyFontColor: () => {},
  // userEntities: '',
  // updateUserEntities: () => {},
  updateAllData: () => {},
  // client: null,
  googleNfcEnabled: true,
  updateGoogleNfcEnabled: () => {},
  notificationHeader: '',
  updateNotificationHeader: () => {},
  appleAppLink: '',
  updateAppleAppLink: () => {},
  googleAppLinkPlayStoreUrl: '',
  updateGoogleAppLinkPlayStoreUrl: () => {},
  googleAppLinkPackageName: '',
  updateGoogleAppLinkPackageName: () => {},
  googleAppLinkWebAppUrl: '',
  updateGoogleAppLinkWebAppUrl: () => {},
  // Stamp card
  stampCount: 2,
  updateStampCount: () => {},
  stampShortKey: '',
  updateStampShortKey: () => {},
  stampShortKeyField: '',
  updateStampShortKeyField: () => {},
  stampLongKey: '',
  updateStampLongKey: () => {},
  stampLongKeyField: '',
  updateStampLongKeyField: () => {},
  // Stamp card message
  stampZeroMessage: '',
  updateStampZeroMessage: () => {},
  stampOneMessage: '',
  updateStampOneMessage: () => {},
  stampTwoMessage: '',
  updateStampTwoMessage: () => {},
  stampThreeMessage: '',
  updateStampThreeMessage: () => {},
  stampFourMessage: '',
  updateStampFourMessage: () => {},
  stampFiveMessage: '',
  updateStampFiveMessage: () => {},
  stampSixMessage: '',
  updateStampSixMessage: () => {},
  stampSevenMessage: '',
  updateStampSevenMessage: () => {},
  stampEightMessage: '',
  updateStampEightMessage: () => {},
  stampNineMessage: '',
  updateStampNineMessage: () => {},
  stampTenMessage: '',
  updateStampTenMessage: () => {},
  stampElevenMessage: '',
  updateStampElevenMessage: () => {},
  stampTwelveMessage: '',
  updateStampTwelveMessage: () => {},
  // Short value
  stampZeroShortValue: '',
  updateStampZeroShortValue: () => {},
  stampOneShortValue: '',
  updateStampOneShortValue: () => {},
  stampTwoShortValue: '',
  updateStampTwoShortValue: () => {},
  stampThreeShortValue: '',
  updateStampThreeShortValue: () => {},
  stampFourShortValue: '',
  updateStampFourShortValue: () => {},
  stampFiveShortValue: '',
  updateStampFiveShortValue: () => {},
  stampSixShortValue: '',
  updateStampSixShortValue: () => {},
  stampSevenShortValue: '',
  updateStampSevenShortValue: () => {},
  stampEightShortValue: '',
  updateStampEightShortValue: () => {},
  stampNineShortValue: '',
  updateStampNineShortValue: () => {},
  stampTenShortValue: '',
  updateStampTenShortValue: () => {},
  stampElevenShortValue: '',
  updateStampElevenShortValue: () => {},
  stampTwelveShortValue: '',
  updateStampTwelveShortValue: () => {},
  // Long value
  stampZeroLongValue: '',
  updateStampZeroLongValue: () => {},
  stampOneLongValue: '',
  updateStampOneLongValue: () => {},
  stampTwoLongValue: '',
  updateStampTwoLongValue: () => {},
  stampThreeLongValue: '',
  updateStampThreeLongValue: () => {},
  stampFourLongValue: '',
  updateStampFourLongValue: () => {},
  stampFiveLongValue: '',
  updateStampFiveLongValue: () => {},
  stampSixLongValue: '',
  updateStampSixLongValue: () => {},
  stampSevenLongValue: '',
  updateStampSevenLongValue: () => {},
  stampEightLongValue: '',
  updateStampEightLongValue: () => {},
  stampNineLongValue: '',
  updateStampNineLongValue: () => {},
  stampTenLongValue: '',
  updateStampTenLongValue: () => {},
  stampElevenLongValue: '',
  updateStampElevenLongValue: () => {},
  stampTwelveLongValue: '',
  updateStampTwelveLongValue: () => {},
  // Stamp images
  stampZeroImage: '',
  updateStampZeroImage: () => {},
  stampOneImage: '',
  updateStampOneImage: () => {},
  stampTwoImage: '',
  updateStampTwoImage: () => {},
  stampThreeImage: '',
  updateStampThreeImage: () => {},
  stampFourImage: '',
  updateStampFourImage: () => {},
  stampFiveImage: '',
  updateStampFiveImage: () => {},
  stampSixImage: '',
  updateStampSixImage: () => {},
  stampSevenImage: '',
  updateStampSevenImage: () => {},
  stampEightImage: '',
  updateStampEightImage: () => {},
  stampNineImage: '',
  updateStampNineImage: () => {},
  stampTenImage: '',
  updateStampTenImage: () => {},
  stampElevenImage: '',
  updateStampElevenImage: () => {},
  stampTwelveImage: '',
  updateStampTwelveImage: () => {},
  stampZeroLogo: initialBannerLogo,
  updateStampZeroLogo: () => {},
  resetStampZeroLogo: () => {},
  stampOneLogo: initialBannerLogo,
  updateStampOneLogo: () => {},
  resetStampOneLogo: () => {},
  stampTwoLogo: initialBannerLogo,
  updateStampTwoLogo: () => {},
  resetStampTwoLogo: () => {},
  stampThreeLogo: initialBannerLogo,
  updateStampThreeLogo: () => {},
  resetStampThreeLogo: () => {},
  stampFourLogo: initialBannerLogo,
  updateStampFourLogo: () => {},
  resetStampFourLogo: () => {},
  stampFiveLogo: initialBannerLogo,
  updateStampFiveLogo: () => {},
  resetStampFiveLogo: () => {},
  stampSixLogo: initialBannerLogo,
  updateStampSixLogo: () => {},
  resetStampSixLogo: () => {},
  stampSevenLogo: initialBannerLogo,
  updateStampSevenLogo: () => {},
  resetStampSevenLogo: () => {},
  stampEightLogo: initialBannerLogo,
  updateStampEightLogo: () => {},
  resetStampEightLogo: () => {},
  stampNineLogo: initialBannerLogo,
  updateStampNineLogo: () => {},
  resetStampNineLogo: () => {},
  stampTenLogo: initialBannerLogo,
  updateStampTenLogo: () => {},
  resetStampTenLogo: () => {},
  stampElevenLogo: initialBannerLogo,
  updateStampElevenLogo: () => {},
  resetStampElevenLogo: () => {},
  stampTwelveLogo: initialBannerLogo,
  updateStampTwelveLogo: () => {},
  resetStampTwelveLogo: () => {},
  // Stamp watermark attributes
  stampZeroWatermarkColor: '',
  updateStampZeroWatermarkColor: () => {},
  stampZeroWatermarkPosition: '',
  updateStampZeroWatermarkPosition: () => {},
  stampOneWatermarkColor: '',
  updateStampOneWatermarkColor: () => {},
  stampOneWatermarkPosition: '',
  updateStampOneWatermarkPosition: () => {},
  stampTwoWatermarkColor: '',
  updateStampTwoWatermarkColor: () => {},
  stampTwoWatermarkPosition: '',
  updateStampTwoWatermarkPosition: () => {},
  stampThreeWatermarkColor: '',
  updateStampThreeWatermarkColor: () => {},
  stampThreeWatermarkPosition: '',
  updateStampThreeWatermarkPosition: () => {},
  stampFourWatermarkColor: '',
  updateStampFourWatermarkColor: () => {},
  stampFourWatermarkPosition: '',
  updateStampFourWatermarkPosition: () => {},
  stampFiveWatermarkColor: '',
  updateStampFiveWatermarkColor: () => {},
  stampFiveWatermarkPosition: '',
  updateStampFiveWatermarkPosition: () => {},
  stampSixWatermarkColor: '',
  updateStampSixWatermarkColor: () => {},
  stampSixWatermarkPosition: '',
  updateStampSixWatermarkPosition: () => {},
  stampSevenWatermarkColor: '',
  updateStampSevenWatermarkColor: () => {},
  stampSevenWatermarkPosition: '',
  updateStampSevenWatermarkPosition: () => {},
  stampEightWatermarkColor: '',
  updateStampEightWatermarkColor: () => {},
  stampEightWatermarkPosition: '',
  updateStampEightWatermarkPosition: () => {},
  stampNineWatermarkColor: '',
  updateStampNineWatermarkColor: () => {},
  stampNineWatermarkPosition: '',
  updateStampNineWatermarkPosition: () => {},
  stampTenWatermarkColor: '',
  updateStampTenWatermarkColor: () => {},
  stampTenWatermarkPosition: '',
  updateStampTenWatermarkPosition: () => {},
  stampElevenWatermarkColor: '',
  updateStampElevenWatermarkColor: () => {},
  stampElevenWatermarkPosition: '',
  updateStampElevenWatermarkPosition: () => {},
  stampTwelveWatermarkColor: '',
  updateStampTwelveWatermarkColor: () => {},
  stampTwelveWatermarkPosition: '',
  updateStampTwelveWatermarkPosition: () => {},
  // locations
  locations: [],
  updateLocations: () => {},
  locationCount: 0,
  updateLocationCount: () => {},
  // beacons
  beacons: [],
  updateBeacons: () => {},
  beaconCount: 0,
  updateBeaconCount: () => {},
  activeStep: 0,
  // watermark
  watermarkColor: '',
  updateWatermarkColor: () => {},
  watermarkPosition: '',
  updateWatermarkPosition: () => {},
  googleBannerWatermarkColor: '',
  updateGoogleBannerWatermarkColor: () => {},
  googleBannerWatermarkPosition: '',
  updateGoogleBannerWatermarkPosition: () => {},
  displayBannerWatermarkPreview: true,
  updateDisplayBannerWatermarkPreview: () => {},
  displayGoogleBannerWatermarkPreview: true,
  updateDisplayGoogleBannerWatermarkPreview: () => {},
  // loyalty balance
  loyaltyConfigEnabled: false,
  updateLoyaltyConfigEnabled: () => {},
  loyaltyConfigNumberType: '',
  updateLoyaltyConfigNumberType: () => {},
});

export function PassTemplateContextProvider(props) {
  const [templateName, setTemplateName] = useState('');
  const [uuid, setUuid] = useState('');
  const [templateEntity, setTemplateEntity] = useState('');
  const [passType, setPassType] = useState('storeCard');
  const [headerOneId, setHeaderOneId] = useState('headerLabel');
  const [headerOneLabel, setHeaderOneLabel] = useState('Header label');
  const [headerOneValue, setHeaderOneValue] = useState('');
  const [headerTwoId, setHeaderTwoId] = useState('header2');
  const [headerTwoLabel, setHeaderTwoLabel] = useState('');
  const [headerTwoValue, setHeaderTwoValue] = useState('');
  const [headerThreeId, setHeaderThreeId] = useState('header3');
  const [headerThreeLabel, setHeaderThreeLabel] = useState('');
  const [headerThreeValue, setHeaderThreeValue] = useState('');
  const [centralTitle, setCentralTitle] = useState('Central title');
  const [googleNfcEnabled, setGoogleNfcEnabled] = useState(true);
  const [primaryOneId, setPrimaryOneId] = useState('primary1');
  const [primaryOneLabel, setPrimaryOneLabel] = useState('Central label');
  const [primaryOneValue, setPrimaryOneValue] = useState('');
  const [primaryTwoId, setPrimaryTwoId] = useState('primary2');
  const [primaryTwoLabel, setPrimaryTwoLabel] = useState('');
  const [primaryTwoValue, setPrimaryTwoValue] = useState('');
  const [iosLogo, setIosLogo] = useState(initialIosLogo);
  const [androidLogo, setAndroidLogo] = useState(initialAndroidLogo);
  const [bannerLogo, setBannerLogo] = useState(initialBannerLogo);
  const [googleBannerLogo, setGoogleBannerLogo] = useState(
    initialGoogleBannerLogo
  );
  const [thumbnailLogo, setThumbnailLogo] = useState(initialThumbnailLogo);
  const [monochromeBadgeLogo, setMonochromeBadgeLogo] = useState(
    initialMonochromeBadgeLogo
  );
  const [logoImage, setLogoImage] = useState(LOGO_BASE64);
  const [thumbnailImage, setThumbnailImage] = useState(THUMBNAIL_BASE64);
  const [monochromeBadge, setMonochromeBadge] = useState(
    MONOCHROME_BADGE_BASE64
  );
  const [iconLogo, setIconLogo] = useState(ICON_BASE64);
  const [bannerImage, setBannerImage] = useState(BANNER_BASE64);
  const [googleBannerImage, setGoogleBannerImage] = useState('');
  const [stampZeroImage, setStampZeroImage] = useState(BANNER_BASE64);
  const [stampOneImage, setStampOneImage] = useState(BANNER_BASE64);
  const [stampTwoImage, setStampTwoImage] = useState(BANNER_BASE64);
  const [stampThreeImage, setStampThreeImage] = useState(BANNER_BASE64);
  const [stampFourImage, setStampFourImage] = useState(BANNER_BASE64);
  const [stampFiveImage, setStampFiveImage] = useState(BANNER_BASE64);
  const [stampSixImage, setStampSixImage] = useState(BANNER_BASE64);
  const [stampSevenImage, setStampSevenImage] = useState(BANNER_BASE64);
  const [stampEightImage, setStampEightImage] = useState(BANNER_BASE64);
  const [stampNineImage, setStampNineImage] = useState(BANNER_BASE64);
  const [stampTenImage, setStampTenImage] = useState(BANNER_BASE64);
  const [stampElevenImage, setStampElevenImage] = useState(BANNER_BASE64);
  const [stampTwelveImage, setStampTwelveImage] = useState(BANNER_BASE64);
  const [secOneId, setSecOneId] = useState('label1');
  const [secOneLabel, setSecOneLabel] = useState('Label 1');
  const [secOneValue, setSecOneValue] = useState('');
  const [secTwoId, setSecTwoId] = useState('label2');
  const [secTwoLabel, setSecTwoLabel] = useState('Label 2');
  const [secTwoValue, setSecTwoValue] = useState('');
  const [secThreeId, setSecThreeId] = useState('label3');
  const [secThreeLabel, setSecThreeLabel] = useState('Label 3');
  const [secThreeValue, setSecThreeValue] = useState('');
  const [description, setDescription] = useState('');
  const [auxOneId, setAuxOneId] = useState('label4');
  const [auxOneLabel, setAuxOneLabel] = useState('Label 4');
  const [auxOneValue, setAuxOneValue] = useState('');
  const [auxTwoId, setAuxTwoId] = useState(
    passType === 'eventTicket' || passType === 'generic' ? 'label5' : ''
  );
  const [auxTwoLabel, setAuxTwoLabel] = useState(
    passType === 'eventTicket' || passType === 'generic' ? 'Label 5' : ''
  );
  const [auxTwoValue, setAuxTwoValue] = useState('');
  const [auxThreeId, setAuxThreeId] = useState(
    passType === 'eventTicket' || passType === 'generic' ? 'label6' : ''
  );
  const [auxThreeLabel, setAuxThreeLabel] = useState(
    passType === 'eventTicket' || passType === 'generic' ? 'Label 6' : ''
  );
  const [auxThreeValue, setAuxThreeValue] = useState('');
  const [backOneId, setBackOneId] = useState('');
  const [backOneLabel, setBackOneLabel] = useState('');
  const [backOneValue, setBackOneValue] = useState('');
  const [backTwoId, setBackTwoId] = useState('');
  const [backTwoLabel, setBackTwoLabel] = useState('');
  const [backTwoValue, setBackTwoValue] = useState('');
  const [backThreeId, setBackThreeId] = useState('');
  const [backThreeLabel, setBackThreeLabel] = useState('');
  const [backThreeValue, setBackThreeValue] = useState('');
  const [backFourId, setBackFourId] = useState('');
  const [backFourLabel, setBackFourLabel] = useState('');
  const [backFourValue, setBackFourValue] = useState('');
  const [backFiveId, setBackFiveId] = useState('');
  const [backFiveLabel, setBackFiveLabel] = useState('');
  const [backFiveValue, setBackFiveValue] = useState('');
  const [passBackgroundColor, setPassBackgroundColor] = useState('#EFEFEF');
  const [titleFontColor, setTitleFontColor] = useState('#FF8477');
  const [bodyFontColor, setBodyFontColor] = useState('#29323A');
  const [notificationHeader, setNotificationHeader] = useState('');
  const [appleAppLink, setAppleAppLink] = useState('');
  const [googleAppLinkPlayStoreUrl, setGoogleAppLinkPlayStoreUrl] =
    useState('');
  const [googleAppLinkPackageName, setGoogleAppLinkPackageName] = useState('');
  const [googleAppLinkWebAppUrl, setGoogleAppLinkWebAppUrl] = useState('');
  const [locations, setLocations] = useState([]);
  const [locationCount, setLocationCount] = useState(0);
  const [beacons, setBeacons] = useState([]);
  const [beaconCount, setBeaconCount] = useState(0);

  // Stamp card
  const [stampCount, setStampCount] = useState(2);
  const [stampShortKey, setStampShortKey] = useState('');
  const [stampShortKeyField, setStampShortKeyField] = useState('');
  const [stampLongKey, setStampLongKey] = useState('');
  const [stampLongKeyField, setStampLongKeyField] = useState('');
  const [stampZeroMessage, setStampZeroMessage] = useState('');
  const [stampOneMessage, setStampOneMessage] = useState('');
  const [stampTwoMessage, setStampTwoMessage] = useState('');
  const [stampThreeMessage, setStampThreeMessage] = useState('');
  const [stampFourMessage, setStampFourMessage] = useState('');
  const [stampFiveMessage, setStampFiveMessage] = useState('');
  const [stampSixMessage, setStampSixMessage] = useState('');
  const [stampSevenMessage, setStampSevenMessage] = useState('');
  const [stampEightMessage, setStampEightMessage] = useState('');
  const [stampNineMessage, setStampNineMessage] = useState('');
  const [stampTenMessage, setStampTenMessage] = useState('');
  const [stampElevenMessage, setStampElevenMessage] = useState('');
  const [stampTwelveMessage, setStampTwelveMessage] = useState('');
  const [stampZeroShortValue, setStampZeroShortValue] = useState('');
  const [stampOneShortValue, setStampOneShortValue] = useState('');
  const [stampTwoShortValue, setStampTwoShortValue] = useState('');
  const [stampThreeShortValue, setStampThreeShortValue] = useState('');
  const [stampFourShortValue, setStampFourShortValue] = useState('');
  const [stampFiveShortValue, setStampFiveShortValue] = useState('');
  const [stampSixShortValue, setStampSixShortValue] = useState('');
  const [stampSevenShortValue, setStampSevenShortValue] = useState('');
  const [stampEightShortValue, setStampEightShortValue] = useState('');
  const [stampNineShortValue, setStampNineShortValue] = useState('');
  const [stampTenShortValue, setStampTenShortValue] = useState('');
  const [stampElevenShortValue, setStampElevenShortValue] = useState('');
  const [stampTwelveShortValue, setStampTwelveShortValue] = useState('');
  const [stampZeroLongValue, setStampZeroLongValue] = useState('');
  const [stampOneLongValue, setStampOneLongValue] = useState('');
  const [stampTwoLongValue, setStampTwoLongValue] = useState('');
  const [stampThreeLongValue, setStampThreeLongValue] = useState('');
  const [stampFourLongValue, setStampFourLongValue] = useState('');
  const [stampFiveLongValue, setStampFiveLongValue] = useState('');
  const [stampSixLongValue, setStampSixLongValue] = useState('');
  const [stampSevenLongValue, setStampSevenLongValue] = useState('');
  const [stampEightLongValue, setStampEightLongValue] = useState('');
  const [stampNineLongValue, setStampNineLongValue] = useState('');
  const [stampTenLongValue, setStampTenLongValue] = useState('');
  const [stampElevenLongValue, setStampElevenLongValue] = useState('');
  const [stampTwelveLongValue, setStampTwelveLongValue] = useState('');
  const [stampZeroLogo, setStampZeroLogo] = useState(initialBannerLogo);
  const [stampOneLogo, setStampOneLogo] = useState(initialBannerLogo);
  const [stampTwoLogo, setStampTwoLogo] = useState(initialBannerLogo);
  const [stampThreeLogo, setStampThreeLogo] = useState(initialBannerLogo);
  const [stampFourLogo, setStampFourLogo] = useState(initialBannerLogo);
  const [stampFiveLogo, setStampFiveLogo] = useState(initialBannerLogo);
  const [stampSixLogo, setStampSixLogo] = useState(initialBannerLogo);
  const [stampSevenLogo, setStampSevenLogo] = useState(initialBannerLogo);
  const [stampEightLogo, setStampEightLogo] = useState(initialBannerLogo);
  const [stampNineLogo, setStampNineLogo] = useState(initialBannerLogo);
  const [stampTenLogo, setStampTenLogo] = useState(initialBannerLogo);
  const [stampElevenLogo, setStampElevenLogo] = useState(initialBannerLogo);
  const [stampTwelveLogo, setStampTwelveLogo] = useState(initialBannerLogo);
  const [stampZeroWatermarkColor, setStampZeroWatermarkColor] =
    useState('light');
  const [stampZeroWatermarkPosition, setStampZeroWatermarkPosition] =
    useState('bottomRight');
  const [stampOneWatermarkColor, setStampOneWatermarkColor] = useState('light');
  const [stampOneWatermarkPosition, setStampOneWatermarkPosition] =
    useState('bottomRight');
  const [stampTwoWatermarkColor, setStampTwoWatermarkColor] = useState('light');
  const [stampTwoWatermarkPosition, setStampTwoWatermarkPosition] =
    useState('bottomRight');
  const [stampThreeWatermarkColor, setStampThreeWatermarkColor] =
    useState('light');
  const [stampThreeWatermarkPosition, setStampThreeWatermarkPosition] =
    useState('bottomRight');
  const [stampFourWatermarkColor, setStampFourWatermarkColor] =
    useState('light');
  const [stampFourWatermarkPosition, setStampFourWatermarkPosition] =
    useState('bottomRight');
  const [stampFiveWatermarkColor, setStampFiveWatermarkColor] =
    useState('light');
  const [stampFiveWatermarkPosition, setStampFiveWatermarkPosition] =
    useState('bottomRight');
  const [stampSixWatermarkColor, setStampSixWatermarkColor] = useState('light');
  const [stampSixWatermarkPosition, setStampSixWatermarkPosition] =
    useState('bottomRight');
  const [stampSevenWatermarkColor, setStampSevenWatermarkColor] =
    useState('light');
  const [stampSevenWatermarkPosition, setStampSevenWatermarkPosition] =
    useState('bottomRight');
  const [stampEightWatermarkColor, setStampEightWatermarkColor] =
    useState('light');
  const [stampEightWatermarkPosition, setStampEightWatermarkPosition] =
    useState('bottomRight');
  const [stampNineWatermarkColor, setStampNineWatermarkColor] =
    useState('light');
  const [stampNineWatermarkPosition, setStampNineWatermarkPosition] =
    useState('bottomRight');
  const [stampTenWatermarkColor, setStampTenWatermarkColor] = useState('light');
  const [stampTenWatermarkPosition, setStampTenWatermarkPosition] =
    useState('bottomRight');
  const [stampElevenWatermarkColor, setStampElevenWatermarkColor] =
    useState('light');
  const [stampElevenWatermarkPosition, setStampElevenWatermarkPosition] =
    useState('bottomRight');
  const [stampTwelveWatermarkColor, setStampTwelveWatermarkColor] =
    useState('light');
  const [stampTwelveWatermarkPosition, setStampTwelveWatermarkPosition] =
    useState('bottomRight');

  const [activeStep, setActiveStep] = useState(0);
  const [watermarkColor, setWatermarkColor] = useState('light');
  const [watermarkPosition, setWatermarkPosition] = useState('bottomRight');
  const [googleBannerWatermarkColor, setGoogleBannerWatermarkColor] =
    useState('light');
  const [googleBannerWatermarkPosition, setGoogleBannerWatermarkPosition] =
    useState('bottomRight');
  const [displayBannerWatermarkPreview, setDisplayBannerWatermarkPreview] =
    useState(true);
  const [
    displayGoogleBannerWatermarkPreview,
    setDisplayGoogleBannerWatermarkPreview,
  ] = useState(true);
  const [loyaltyConfigEnabled, setLoyaltyConfigEnabled] = useState(false);
  const [loyaltyConfigNumberType, setLoyaltyConfigNumberType] =
    useState('integer');

  // const [client, setClient] = useState(null);
  // const [userEntities, setUserEntities] = useState([templateName]);

  const calculateBannerAspectRatio = () => {
    let baseImageAspectRatio;
    if (passType === 'eventTicket') {
      baseImageAspectRatio = 1125 / 294;
    } else if (passType === 'storeCard' || passType === 'stampCard') {
      baseImageAspectRatio = 1125 / 432;
    } else {
      baseImageAspectRatio = 1125 / 370;
    }
    return baseImageAspectRatio;
  };

  useEffect(() => {
    const aspectRatio = calculateBannerAspectRatio();
    initialBannerLogo.aspect = aspectRatio;
    initialGoogleBannerLogo.aspect = aspectRatio;
    setBannerLogo(initialBannerLogo);
    setGoogleBannerLogo(initialGoogleBannerLogo);
    if (passType === 'stampCard') {
      setStampZeroLogo(initialBannerLogo);
      setStampOneLogo(initialBannerLogo);
      setStampTwoLogo(initialBannerLogo);
      setStampThreeLogo(initialBannerLogo);
      setStampFourLogo(initialBannerLogo);
      setStampFiveLogo(initialBannerLogo);
      setStampSixLogo(initialBannerLogo);
      setStampSevenLogo(initialBannerLogo);
      setStampEightLogo(initialBannerLogo);
      setStampNineLogo(initialBannerLogo);
      setStampTenLogo(initialBannerLogo);
      setStampElevenLogo(initialBannerLogo);
      setStampTwelveLogo(initialBannerLogo);
    }
  }, [passType]);

  function updateTemplateNameHandler(name) {
    setTemplateName(name);
  }

  function updateUuidHandler(uuid) {
    setUuid(uuid);
  }

  function updateTemplateEntityHandler(entity) {
    setTemplateEntity(entity);
  }

  function updatePassTypeHandler(type) {
    setPassType(type);
  }

  function updateHeaderOneIdHandler(field) {
    setHeaderOneId(field);
  }

  function updateHeaderOneLabelHandler(field) {
    setHeaderOneLabel(field);
  }

  function updateHeaderOneValueHandler(field) {
    setHeaderOneValue(field);
  }

  function updateHeaderTwoIdHandler(field) {
    setHeaderTwoId(field);
  }

  function updateHeaderTwoLabelHandler(field) {
    setHeaderTwoLabel(field);
  }

  function updateHeaderTwoValueHandler(field) {
    setHeaderTwoValue(field);
  }

  function updateHeaderThreeIdHandler(field) {
    setHeaderThreeId(field);
  }

  function updateHeaderThreeLabelHandler(field) {
    setHeaderThreeLabel(field);
  }

  function updateHeaderThreeValueHandler(field) {
    setHeaderThreeValue(field);
  }

  function updateCentralTitleHandler(title) {
    setCentralTitle(title);
  }

  function updateGoogleNfcEnabledHandler(value) {
    setGoogleNfcEnabled(value);
  }

  function updatePrimaryOneIdHandler(field) {
    setPrimaryOneId(field);
  }

  function updatePrimaryOneLabelHandler(field) {
    setPrimaryOneLabel(field);
  }

  function updatePrimaryOneValueHandler(field) {
    setPrimaryOneValue(field);
  }

  function updatePrimaryTwoIdHandler(field) {
    setPrimaryTwoId(field);
  }

  function updatePrimaryTwoLabelHandler(field) {
    setPrimaryTwoLabel(field);
  }

  function updatePrimaryTwoValueHandler(field) {
    setPrimaryTwoValue(field);
  }

  function updateIosLogoHandler(logo) {
    setIosLogo(logo);
  }
  function resetIosLogoHandler() {
    setIosLogo(initialIosLogo);
  }

  function updateAndroidLogoHandler(logo) {
    setAndroidLogo(logo);
  }
  function resetAndroidLogoHandler() {
    setAndroidLogo(initialAndroidLogo);
  }

  function updateThumbnailLogoHandler(logo) {
    setThumbnailLogo(logo);
  }
  function resetThumbnailLogoHandler() {
    setThumbnailLogo(initialThumbnailLogo);
  }

  function updateMonochromeBadgeLogoHandler(logo) {
    setMonochromeBadgeLogo(logo);
  }

  function resetMonochromeBadgeLogoHandler() {
    setMonochromeBadgeLogo(initialMonochromeBadgeLogo);
  }

  function updateBannerLogoHandler(logo) {
    setBannerLogo(logo);
  }
  function resetBannerLogoHandler() {
    setBannerLogo(initialBannerLogo);
  }

  function updateGoogleBannerLogoHandler(logo) {
    setGoogleBannerLogo(logo);
  }
  function resetGoogleBannerLogoHandler() {
    setGoogleBannerLogo(initialGoogleBannerLogo);
  }

  function updateThumbnailImageHandler(src) {
    setThumbnailImage(src);
  }

  function updateMonochromeBadgeHandler(src) {
    setMonochromeBadge(src);
  }

  function updateLogoImageHandler(src) {
    setLogoImage(src);
  }

  function updateIconLogoHandler(src) {
    setIconLogo(src);
  }

  function updateBannerImageHandler(src) {
    setBannerImage(src);
  }

  function updateGoogleBannerImageHandler(src) {
    setGoogleBannerImage(src);
  }

  function updateStampZeroImageHandler(src) {
    setStampZeroImage(src);
  }

  function updateStampOneImageHandler(src) {
    setStampOneImage(src);
  }

  function updateStampTwoImageHandler(src) {
    setStampTwoImage(src);
  }

  function updateStampThreeImageHandler(src) {
    setStampThreeImage(src);
  }

  function updateStampFourImageHandler(src) {
    setStampFourImage(src);
  }

  function updateStampFiveImageHandler(src) {
    setStampFiveImage(src);
  }

  function updateStampSixImageHandler(src) {
    setStampSixImage(src);
  }

  function updateStampSevenImageHandler(src) {
    setStampSevenImage(src);
  }

  function updateStampEightImageHandler(src) {
    setStampEightImage(src);
  }

  function updateStampNineImageHandler(src) {
    setStampNineImage(src);
  }

  function updateStampTenImageHandler(src) {
    setStampTenImage(src);
  }

  function updateStampElevenImageHandler(src) {
    setStampElevenImage(src);
  }

  function updateStampTwelveImageHandler(src) {
    setStampTwelveImage(src);
  }

  function updateStampZeroLogoHandler(logo) {
    setStampZeroLogo(logo);
  }

  function resetStampZeroLogoHandler() {
    setStampZeroLogo(initialBannerLogo);
  }

  function updateStampOneLogoHandler(logo) {
    setStampOneLogo(logo);
  }

  function resetStampOneLogoHandler() {
    setStampOneLogo(initialBannerLogo);
  }

  function updateStampTwoLogoHandler(logo) {
    setStampTwoLogo(logo);
  }

  function resetStampTwoLogoHandler() {
    setStampTwoLogo(initialBannerLogo);
  }

  function updateStampThreeLogoHandler(logo) {
    setStampThreeLogo(logo);
  }

  function resetStampThreeLogoHandler() {
    setStampThreeLogo(initialBannerLogo);
  }

  function updateStampFourLogoHandler(logo) {
    setStampFourLogo(logo);
  }

  function resetStampFourLogoHandler() {
    setStampFourLogo(initialBannerLogo);
  }

  function updateStampFiveLogoHandler(logo) {
    setStampFiveLogo(logo);
  }

  function resetStampFiveLogoHandler() {
    setStampFiveLogo(initialBannerLogo);
  }

  function updateStampSixLogoHandler(logo) {
    setStampSixLogo(logo);
  }

  function resetStampSixLogoHandler() {
    setStampSixLogo(initialBannerLogo);
  }

  function updateStampSevenLogoHandler(logo) {
    setStampSevenLogo(logo);
  }

  function resetStampSevenLogoHandler() {
    setStampSevenLogo(initialBannerLogo);
  }

  function updateStampEightLogoHandler(logo) {
    setStampEightLogo(logo);
  }

  function resetStampEightLogoHandler() {
    setStampEightLogo(initialBannerLogo);
  }

  function updateStampNineLogoHandler(logo) {
    setStampNineLogo(logo);
  }

  function resetStampNineLogoHandler() {
    setStampNineLogo(initialBannerLogo);
  }

  function updateStampTenLogoHandler(logo) {
    setStampTenLogo(logo);
  }

  function resetStampTenLogoHandler() {
    setStampTenLogo(initialBannerLogo);
  }

  function updateStampElevenLogoHandler(logo) {
    setStampElevenLogo(logo);
  }

  function resetStampElevenLogoHandler() {
    setStampElevenLogo(initialBannerLogo);
  }

  function updateStampTwelveLogoHandler(logo) {
    setStampTwelveLogo(logo);
  }

  function resetStampTwelveLogoHandler() {
    setStampTwelveLogo(initialBannerLogo);
  }

  function updateSecOneIdHandler(field) {
    setSecOneId(field);
  }

  function updateSecOneLabelHandler(field) {
    setSecOneLabel(field);
  }

  function updateSecOneValueHandler(field) {
    setSecOneValue(field);
  }

  function updateSecTwoIdHandler(field) {
    setSecTwoId(field);
  }

  function updateSecTwoLabelHandler(field) {
    setSecTwoLabel(field);
  }

  function updateSecTwoValueHandler(field) {
    setSecTwoValue(field);
  }

  function updateSecThreeIdHandler(field) {
    setSecThreeId(field);
  }

  function updateSecThreeLabelHandler(field) {
    setSecThreeLabel(field);
  }

  function updateSecThreeValueHandler(field) {
    setSecThreeValue(field);
  }

  function updateDescriptionHandler(field) {
    setDescription(field);
  }

  function updateAuxOneIdHandler(field) {
    setAuxOneId(field);
  }

  function updateAuxOneLabelHandler(field) {
    setAuxOneLabel(field);
  }

  function updateAuxOneValueHandler(field) {
    setAuxOneValue(field);
  }

  function updateAuxTwoIdHandler(field) {
    setAuxTwoId(field);
  }

  function updateAuxTwoLabelHandler(field) {
    setAuxTwoLabel(field);
  }

  function updateAuxTwoValueHandler(field) {
    setAuxTwoValue(field);
  }

  function updateAuxThreeIdHandler(field) {
    setAuxThreeId(field);
  }

  function updateAuxThreeLabelHandler(field) {
    setAuxThreeLabel(field);
  }

  function updateAuxThreeValueHandler(field) {
    setAuxThreeValue(field);
  }

  function updateBackOneIdHandler(field) {
    setBackOneId(field);
  }

  function updateBackOneLabelHandler(field) {
    setBackOneLabel(field);
  }

  function updateBackOneValueHandler(field) {
    setBackOneValue(field);
  }

  function updateBackTwoIdHandler(field) {
    setBackTwoId(field);
  }

  function updateBackTwoLabelHandler(field) {
    setBackTwoLabel(field);
  }

  function updateBackTwoValueHandler(field) {
    setBackTwoValue(field);
  }

  function updateBackThreeIdHandler(field) {
    setBackThreeId(field);
  }

  function updateBackThreeLabelHandler(field) {
    setBackThreeLabel(field);
  }

  function updateBackThreeValueHandler(field) {
    setBackThreeValue(field);
  }

  function updateBackFourIdHandler(field) {
    setBackFourId(field);
  }

  function updateBackFourLabelHandler(field) {
    setBackFourLabel(field);
  }

  function updateBackFourValueHandler(field) {
    setBackFourValue(field);
  }

  function updateBackFiveIdHandler(field) {
    setBackFiveId(field);
  }

  function updateBackFiveLabelHandler(field) {
    setBackFiveLabel(field);
  }

  function updateBackFiveValueHandler(field) {
    setBackFiveValue(field);
  }

  function updatePassBackgroundColorHandler(hexcode) {
    setPassBackgroundColor(hexcode);
  }

  function updateTitleFontColorHandler(hexcode) {
    setTitleFontColor(hexcode);
  }

  function updateBodyFontColorHandler(hexcode) {
    setBodyFontColor(hexcode);
  }

  function updateNotificationHeaderHandler(header) {
    setNotificationHeader(header);
  }

  function updateAppleAppLinkHandler(link) {
    setAppleAppLink(link);
  }

  function updateGoogleAppLinkPlayStoreUrlHandler(url) {
    setGoogleAppLinkPlayStoreUrl(url);
  }

  function updateGoogleAppLinkPackageNameHandler(name) {
    setGoogleAppLinkPackageName(name);
  }

  function updateGoogleAppLinkWebAppUrlHandler(url) {
    setGoogleAppLinkWebAppUrl(url);
  }

  function updateStampCountHandler(num) {
    setStampCount(num);
  }

  function updateStampShortKeyHandler(key) {
    setStampShortKey(key);
  }

  function updateStampShortKeyFieldHandler(field) {
    setStampShortKeyField(field);
  }

  function updateStampLongKeyHandler(key) {
    setStampLongKey(key);
  }

  function updateStampLongKeyFieldHandler(field) {
    setStampLongKeyField(field);
  }

  function updateStampZeroMessageHandler(message) {
    setStampZeroMessage(message);
  }

  function updateStampOneMessageHandler(message) {
    setStampOneMessage(message);
  }

  function updateStampTwoMessageHandler(message) {
    setStampTwoMessage(message);
  }

  function updateStampThreeMessageHandler(message) {
    setStampThreeMessage(message);
  }

  function updateStampFourMessageHandler(message) {
    setStampFourMessage(message);
  }

  function updateStampFiveMessageHandler(message) {
    setStampFiveMessage(message);
  }

  function updateStampSixMessageHandler(message) {
    setStampSixMessage(message);
  }

  function updateStampSevenMessageHandler(message) {
    setStampSevenMessage(message);
  }

  function updateStampEightMessageHandler(message) {
    setStampEightMessage(message);
  }

  function updateStampNineMessageHandler(message) {
    setStampNineMessage(message);
  }

  function updateStampTenMessageHandler(message) {
    setStampTenMessage(message);
  }

  function updateStampElevenMessageHandler(message) {
    setStampElevenMessage(message);
  }

  function updateStampTwelveMessageHandler(message) {
    setStampTwelveMessage(message);
  }

  function updateStampZeroShortValueHandler(value) {
    setStampZeroShortValue(value);
  }

  function updateStampOneShortValueHandler(value) {
    setStampOneShortValue(value);
  }

  function updateStampTwoShortValueHandler(value) {
    setStampTwoShortValue(value);
  }

  function updateStampThreeShortValueHandler(value) {
    setStampThreeShortValue(value);
  }

  function updateStampFourShortValueHandler(value) {
    setStampFourShortValue(value);
  }

  function updateStampFiveShortValueHandler(value) {
    setStampFiveShortValue(value);
  }

  function updateStampSixShortValueHandler(value) {
    setStampSixShortValue(value);
  }

  function updateStampSevenShortValueHandler(value) {
    setStampSevenShortValue(value);
  }

  function updateStampEightShortValueHandler(value) {
    setStampEightShortValue(value);
  }

  function updateStampNineShortValueHandler(value) {
    setStampNineShortValue(value);
  }

  function updateStampTenShortValueHandler(value) {
    setStampTenShortValue(value);
  }

  function updateStampElevenShortValueHandler(value) {
    setStampElevenShortValue(value);
  }

  function updateStampTwelveShortValueHandler(value) {
    setStampTwelveShortValue(value);
  }

  function updateStampZeroLongValueHandler(value) {
    setStampZeroLongValue(value);
  }

  function updateStampOneLongValueHandler(value) {
    setStampOneLongValue(value);
  }

  function updateStampTwoLongValueHandler(value) {
    setStampTwoLongValue(value);
  }

  function updateStampThreeLongValueHandler(value) {
    setStampThreeLongValue(value);
  }

  function updateStampFourLongValueHandler(value) {
    setStampFourLongValue(value);
  }

  function updateStampFiveLongValueHandler(value) {
    setStampFiveLongValue(value);
  }

  function updateStampSixLongValueHandler(value) {
    setStampSixLongValue(value);
  }

  function updateStampSevenLongValueHandler(value) {
    setStampSevenLongValue(value);
  }

  function updateStampEightLongValueHandler(value) {
    setStampEightLongValue(value);
  }

  function updateStampNineLongValueHandler(value) {
    setStampNineLongValue(value);
  }

  function updateStampTenLongValueHandler(value) {
    setStampTenLongValue(value);
  }

  function updateStampElevenLongValueHandler(value) {
    setStampElevenLongValue(value);
  }

  function updateStampTwelveLongValueHandler(value) {
    setStampTwelveLongValue(value);
  }

  function updateStampZeroWatermarkColorHandler(color) {
    setStampZeroWatermarkColor(color);
  }

  function updateStampZeroWatermarkPositionHandler(position) {
    setStampZeroWatermarkPosition(position);
  }

  function updateStampOneWatermarkColorHandler(color) {
    setStampOneWatermarkColor(color);
  }

  function updateStampOneWatermarkPositionHandler(position) {
    setStampOneWatermarkPosition(position);
  }

  function updateStampTwoWatermarkColorHandler(color) {
    setStampTwoWatermarkColor(color);
  }

  function updateStampTwoWatermarkPositionHandler(position) {
    setStampTwoWatermarkPosition(position);
  }

  function updateStampThreeWatermarkColorHandler(color) {
    setStampThreeWatermarkColor(color);
  }

  function updateStampThreeWatermarkPositionHandler(position) {
    setStampThreeWatermarkPosition(position);
  }

  function updateStampFourWatermarkColorHandler(color) {
    setStampFourWatermarkColor(color);
  }

  function updateStampFourWatermarkPositionHandler(position) {
    setStampFourWatermarkPosition(position);
  }

  function updateStampFiveWatermarkColorHandler(color) {
    setStampFiveWatermarkColor(color);
  }

  function updateStampFiveWatermarkPositionHandler(position) {
    setStampFiveWatermarkPosition(position);
  }

  function updateStampSixWatermarkColorHandler(color) {
    setStampSixWatermarkColor(color);
  }

  function updateStampSixWatermarkPositionHandler(position) {
    setStampSixWatermarkPosition(position);
  }

  function updateStampSevenWatermarkColorHandler(color) {
    setStampSevenWatermarkColor(color);
  }

  function updateStampSevenWatermarkPositionHandler(position) {
    setStampSevenWatermarkPosition(position);
  }

  function updateStampEightWatermarkColorHandler(color) {
    setStampEightWatermarkColor(color);
  }

  function updateStampEightWatermarkPositionHandler(position) {
    setStampEightWatermarkPosition(position);
  }

  function updateStampNineWatermarkColorHandler(color) {
    setStampNineWatermarkColor(color);
  }

  function updateStampNineWatermarkPositionHandler(position) {
    setStampNineWatermarkPosition(position);
  }

  function updateStampTenWatermarkColorHandler(color) {
    setStampTenWatermarkColor(color);
  }

  function updateStampTenWatermarkPositionHandler(position) {
    setStampTenWatermarkPosition(position);
  }

  function updateStampElevenWatermarkColorHandler(color) {
    setStampElevenWatermarkColor(color);
  }

  function updateStampElevenWatermarkPositionHandler(position) {
    setStampElevenWatermarkPosition(position);
  }

  function updateStampTwelveWatermarkColorHandler(color) {
    setStampTwelveWatermarkColor(color);
  }

  function updateStampTwelveWatermarkPositionHandler(position) {
    setStampTwelveWatermarkPosition(position);
  }

  function updateLocationsHandler(locations) {
    setLocations(locations);
  }

  function updateLocationCountHandler(count) {
    setLocationCount(count);
  }

  function updateBeaconsHandler(beacons) {
    setBeacons(beacons);
  }

  function updateBeaconCountHandler(count) {
    setBeaconCount(count);
  }

  function updateActiveStepHandler(step) {
    setActiveStep(step);
  }

  function clearAllStampKeyValuesHandler(keyType) {
    Object.keys(NUMBER_KEYS).forEach((key) => {
      const setterFunction = `updateStamp${NUMBER_KEYS[key]}${keyType}Value`;
      if (typeof context[setterFunction] === 'function') {
        context[setterFunction]('');
      }
    });
  }

  function updateWatermarkColorHandler(type) {
    setWatermarkColor(type);
  }

  function updateWatermarkPositionHandler(position) {
    setWatermarkPosition(position);
  }

  function updateGoogleBannerWatermarkColorHandler(type) {
    setGoogleBannerWatermarkColor(type);
  }

  function updateGoogleBannerWatermarkPositionHandler(position) {
    setGoogleBannerWatermarkPosition(position);
  }

  function updateDisplayBannerWatermarkPreviewHandler(display) {
    setDisplayBannerWatermarkPreview(display);
  }

  function updateDisplayGoogleBannerWatermarkPreviewHandler(display) {
    setDisplayGoogleBannerWatermarkPreview(display);
  }

  function updateLoyaltyConfigEnabledHandler(value) {
    setLoyaltyConfigEnabled(value);
  }

  function updateLoyaltyConfigNumberTypeHandler(type) {
    setLoyaltyConfigNumberType(type);
  }

  function updateAllDataHandler(data) {
    const {
      bannerImage,
      googleBannerImage,
      bodyFontColor,
      thumbnailImage,
      monochromeBadge,
      iconLogo,
      logoImage,
      templateName,
      passBackgroundColor,
      titleFontColor,
      uuid,
      templateEntity,
      passType,
      headerOneId,
      headerOneLabel,
      headerOneValue,
      headerTwoId,
      headerTwoLabel,
      headerTwoValue,
      headerThreeId,
      headerThreeLabel,
      headerThreeValue,
      centralTitle,
      googleNfcEnabled,
      primaryOneId,
      primaryOneLabel,
      primaryOneValue,
      primaryTwoId,
      primaryTwoLabel,
      primaryTwoValue,
      secOneId,
      secOneLabel,
      secOneValue,
      secTwoId,
      secTwoLabel,
      secTwoValue,
      secThreeId,
      secThreeLabel,
      secThreeValue,
      description,
      auxOneId,
      auxOneLabel,
      auxOneValue,
      auxTwoId,
      auxTwoLabel,
      auxTwoValue,
      auxThreeId,
      auxThreeLabel,
      auxThreeValue,
      backOneId,
      backOneLabel,
      backOneValue,
      backTwoId,
      backTwoLabel,
      backTwoValue,
      backThreeId,
      backThreeLabel,
      backThreeValue,
      backFourId,
      backFourLabel,
      backFourValue,
      backFiveId,
      backFiveLabel,
      backFiveValue,
      notificationHeader,
      appleAppLink,
      googleAppLinkPlayStoreUrl,
      googleAppLinkPackageName,
      googleAppLinkWebAppUrl,
      stampCount,
      stampShortKey,
      stampLongKey,
      stampZeroMessage,
      stampOneMessage,
      stampTwoMessage,
      stampThreeMessage,
      stampFourMessage,
      stampFiveMessage,
      stampSixMessage,
      stampSevenMessage,
      stampEightMessage,
      stampNineMessage,
      stampTenMessage,
      stampElevenMessage,
      stampTwelveMessage,
      stampZeroShortValue,
      stampOneShortValue,
      stampTwoShortValue,
      stampThreeShortValue,
      stampFourShortValue,
      stampFiveShortValue,
      stampSixShortValue,
      stampSevenShortValue,
      stampEightShortValue,
      stampNineShortValue,
      stampTenShortValue,
      stampElevenShortValue,
      stampTwelveShortValue,
      stampZeroLongValue,
      stampOneLongValue,
      stampTwoLongValue,
      stampThreeLongValue,
      stampFourLongValue,
      stampFiveLongValue,
      stampSixLongValue,
      stampSevenLongValue,
      stampEightLongValue,
      stampNineLongValue,
      stampTenLongValue,
      stampElevenLongValue,
      stampTwelveLongValue,
      stampZeroImage,
      stampOneImage,
      stampTwoImage,
      stampThreeImage,
      stampFourImage,
      stampFiveImage,
      stampSixImage,
      stampSevenImage,
      stampEightImage,
      stampNineImage,
      stampTenImage,
      stampElevenImage,
      stampTwelveImage,
      stampZeroWatermarkColor,
      stampZeroWatermarkPosition,
      stampOneWatermarkColor,
      stampOneWatermarkPosition,
      stampTwoWatermarkColor,
      stampTwoWatermarkPosition,
      stampThreeWatermarkColor,
      stampThreeWatermarkPosition,
      stampFourWatermarkColor,
      stampFourWatermarkPosition,
      stampFiveWatermarkColor,
      stampFiveWatermarkPosition,
      stampSixWatermarkColor,
      stampSixWatermarkPosition,
      stampSevenWatermarkColor,
      stampSevenWatermarkPosition,
      stampEightWatermarkColor,
      stampEightWatermarkPosition,
      stampNineWatermarkColor,
      stampNineWatermarkPosition,
      stampTenWatermarkColor,
      stampTenWatermarkPosition,
      stampElevenWatermarkColor,
      stampElevenWatermarkPosition,
      stampTwelveWatermarkColor,
      stampTwelveWatermarkPosition,
      locations,
      locationCount,
      beacons,
      beaconCount,
      activeStep,
      watermarkColor,
      watermarkPosition,
      googleBannerWatermarkColor,
      googleBannerWatermarkPosition,
      loyaltyConfigEnabled,
      loyaltyConfigNumberType,
    } = data;

    const stampZeroImageUrl = stampZeroImage ?? placeholderBanner;
    const stampOneImageUrl = stampOneImage ?? placeholderBanner;
    const stampTwoImageUrl = stampTwoImage ?? placeholderBanner;
    const stampThreeImageUrl = stampThreeImage ?? placeholderBanner;
    const stampFourImageUrl = stampFourImage ?? placeholderBanner;
    const stampFiveImageUrl = stampFiveImage ?? placeholderBanner;
    const stampSixImageUrl = stampSixImage ?? placeholderBanner;
    const stampSevenImageUrl = stampSevenImage ?? placeholderBanner;
    const stampEightImageUrl = stampEightImage ?? placeholderBanner;
    const stampNineImageUrl = stampNineImage ?? placeholderBanner;
    const stampTenImageUrl = stampTenImage ?? placeholderBanner;
    const stampElevenImageUrl = stampElevenImage ?? placeholderBanner;
    const stampTwelveImageUrl = stampTwelveImage ?? placeholderBanner;

    const bannerImageUrl = bannerImage
      ? bannerImage
      : passType === 'generic'
        ? ''
        : placeholderBanner;
    const googleBannerImageUrl = googleBannerImage || '';
    const thumbnailImageUrl = thumbnailImage
      ? thumbnailImage
      : passType === 'generic'
        ? ''
        : placeholderThumbnail;
    const monochromeBadgeUrl = monochromeBadge
      ? monochromeBadge
      : placeholderMonochromeBadge;
    const iconLogoUrl = iconLogo ?? placeholderIcon;
    const logoImageUrl = logoImage ?? placeholderLogo;

    setTemplateName(templateName);
    setUuid(uuid);
    setTemplateEntity(templateEntity);
    setPassType(passType);
    setHeaderOneId(headerOneId);
    setHeaderOneLabel(headerOneLabel);
    setHeaderOneValue(headerOneValue);
    setHeaderTwoId(headerTwoId);
    setHeaderTwoLabel(headerTwoLabel);
    setHeaderTwoValue(headerTwoValue);
    setHeaderThreeId(headerThreeId);
    setHeaderThreeLabel(headerThreeLabel);
    setHeaderThreeValue(headerThreeValue);
    setCentralTitle(centralTitle);
    setGoogleNfcEnabled(googleNfcEnabled);
    setPrimaryOneId(primaryOneId);
    setPrimaryOneLabel(primaryOneLabel);
    setPrimaryOneValue(primaryOneValue);
    setPrimaryTwoId(primaryTwoId);
    setPrimaryTwoLabel(primaryTwoLabel);
    setPrimaryTwoValue(primaryTwoValue);
    setBannerImage(bannerImageUrl);
    setGoogleBannerImage(googleBannerImageUrl);
    setBodyFontColor(bodyFontColor);
    setThumbnailImage(thumbnailImageUrl);
    setMonochromeBadge(monochromeBadgeUrl);
    setIconLogo(iconLogoUrl);
    setLogoImage(logoImageUrl);
    setSecOneId(secOneId);
    setSecOneLabel(secOneLabel);
    setSecOneValue(secOneValue);
    setSecTwoId(secTwoId);
    setSecTwoLabel(secTwoLabel);
    setSecTwoValue(secTwoValue);
    setSecThreeId(secThreeId);
    setSecThreeLabel(secThreeLabel);
    setSecThreeValue(secThreeValue);
    setAuxOneId(auxOneId);
    setAuxOneLabel(auxOneLabel);
    setAuxOneValue(auxOneValue);
    setAuxTwoId(auxTwoId);
    setAuxTwoLabel(auxTwoLabel);
    setAuxTwoValue(auxTwoValue);
    setAuxThreeId(auxThreeId);
    setAuxThreeLabel(auxThreeLabel);
    setAuxThreeValue(auxThreeValue);
    setDescription(description);
    setBackOneId(backOneId);
    setBackOneLabel(backOneLabel);
    setBackOneValue(backOneValue);
    setBackTwoId(backTwoId);
    setBackTwoLabel(backTwoLabel);
    setBackTwoValue(backTwoValue);
    setBackThreeId(backThreeId);
    setBackThreeLabel(backThreeLabel);
    setBackThreeValue(backThreeValue);
    setBackFourId(backFourId);
    setBackFourLabel(backFourLabel);
    setBackFourValue(backFourValue);
    setBackFiveId(backFiveId);
    setBackFiveLabel(backFiveLabel);
    setBackFiveValue(backFiveValue);
    setPassBackgroundColor(passBackgroundColor);
    setTitleFontColor(titleFontColor);
    setNotificationHeader(notificationHeader);
    setAppleAppLink(appleAppLink);
    setGoogleAppLinkPlayStoreUrl(googleAppLinkPlayStoreUrl);
    setGoogleAppLinkPackageName(googleAppLinkPackageName);
    setGoogleAppLinkWebAppUrl(googleAppLinkWebAppUrl);
    setStampCount(stampCount);
    setStampShortKey(stampShortKey);
    setStampLongKey(stampLongKey);
    setStampZeroMessage(stampZeroMessage);
    setStampOneMessage(stampOneMessage);
    setStampTwoMessage(stampTwoMessage);
    setStampThreeMessage(stampThreeMessage);
    setStampFourMessage(stampFourMessage);
    setStampFiveMessage(stampFiveMessage);
    setStampSixMessage(stampSixMessage);
    setStampSevenMessage(stampSevenMessage);
    setStampEightMessage(stampEightMessage);
    setStampNineMessage(stampNineMessage);
    setStampTenMessage(stampTenMessage);
    setStampElevenMessage(stampElevenMessage);
    setStampTwelveMessage(stampTwelveMessage);
    setStampZeroShortValue(stampZeroShortValue);
    setStampOneShortValue(stampOneShortValue);
    setStampTwoShortValue(stampTwoShortValue);
    setStampThreeShortValue(stampThreeShortValue);
    setStampFourShortValue(stampFourShortValue);
    setStampFiveShortValue(stampFiveShortValue);
    setStampSixShortValue(stampSixShortValue);
    setStampSevenShortValue(stampSevenShortValue);
    setStampEightShortValue(stampEightShortValue);
    setStampNineShortValue(stampNineShortValue);
    setStampTenShortValue(stampTenShortValue);
    setStampElevenShortValue(stampElevenShortValue);
    setStampTwelveShortValue(stampTwelveShortValue);
    setStampZeroLongValue(stampZeroLongValue);
    setStampOneLongValue(stampOneLongValue);
    setStampTwoLongValue(stampTwoLongValue);
    setStampThreeLongValue(stampThreeLongValue);
    setStampFourLongValue(stampFourLongValue);
    setStampFiveLongValue(stampFiveLongValue);
    setStampSixLongValue(stampSixLongValue);
    setStampSevenLongValue(stampSevenLongValue);
    setStampEightLongValue(stampEightLongValue);
    setStampNineLongValue(stampNineLongValue);
    setStampTenLongValue(stampTenLongValue);
    setStampElevenLongValue(stampElevenLongValue);
    setStampTwelveLongValue(stampTwelveLongValue);
    setStampZeroImage(stampZeroImageUrl);
    setStampOneImage(stampOneImageUrl);
    setStampTwoImage(stampTwoImageUrl);
    setStampThreeImage(stampThreeImageUrl);
    setStampFourImage(stampFourImageUrl);
    setStampFiveImage(stampFiveImageUrl);
    setStampSixImage(stampSixImageUrl);
    setStampSevenImage(stampSevenImageUrl);
    setStampEightImage(stampEightImageUrl);
    setStampNineImage(stampNineImageUrl);
    setStampTenImage(stampTenImageUrl);
    setStampElevenImage(stampElevenImageUrl);
    setStampTwelveImage(stampTwelveImageUrl);
    setStampZeroWatermarkColor(stampZeroWatermarkColor);
    setStampZeroWatermarkPosition(stampZeroWatermarkPosition);
    setStampOneWatermarkColor(stampOneWatermarkColor);
    setStampOneWatermarkPosition(stampOneWatermarkPosition);
    setStampTwoWatermarkColor(stampTwoWatermarkColor);
    setStampTwoWatermarkPosition(stampTwoWatermarkPosition);
    setStampThreeWatermarkColor(stampThreeWatermarkColor);
    setStampThreeWatermarkPosition(stampThreeWatermarkPosition);
    setStampFourWatermarkColor(stampFourWatermarkColor);
    setStampFourWatermarkPosition(stampFourWatermarkPosition);
    setStampFiveWatermarkColor(stampFiveWatermarkColor);
    setStampFiveWatermarkPosition(stampFiveWatermarkPosition);
    setStampSixWatermarkColor(stampSixWatermarkColor);
    setStampSixWatermarkPosition(stampSixWatermarkPosition);
    setStampSevenWatermarkColor(stampSevenWatermarkColor);
    setStampSevenWatermarkPosition(stampSevenWatermarkPosition);
    setStampEightWatermarkColor(stampEightWatermarkColor);
    setStampEightWatermarkPosition(stampEightWatermarkPosition);
    setStampNineWatermarkColor(stampNineWatermarkColor);
    setStampNineWatermarkPosition(stampNineWatermarkPosition);
    setStampTenWatermarkColor(stampTenWatermarkColor);
    setStampTenWatermarkPosition(stampTenWatermarkPosition);
    setStampElevenWatermarkColor(stampElevenWatermarkColor);
    setStampElevenWatermarkPosition(stampElevenWatermarkPosition);
    setStampTwelveWatermarkColor(stampTwelveWatermarkColor);
    setStampTwelveWatermarkPosition(stampTwelveWatermarkPosition);
    setLocations(locations);
    setLocationCount(locationCount);
    setBeacons(beacons);
    setBeaconCount(beaconCount);
    setActiveStep(activeStep);
    setWatermarkColor(watermarkColor);
    setWatermarkPosition(watermarkPosition);
    setGoogleBannerWatermarkColor(googleBannerWatermarkColor);
    setGoogleBannerWatermarkPosition(googleBannerWatermarkPosition);
    setLoyaltyConfigEnabled(loyaltyConfigEnabled);
    setLoyaltyConfigNumberType(loyaltyConfigNumberType);

    // setUserEntities(userEntities);

    setThumbnailLogo({
      ...initialThumbnailLogo,
      src: iconLogoUrl,
    });

    setMonochromeBadgeLogo({
      ...initialMonochromeBadgeLogo,
      src: monochromeBadgeUrl,
    });

    setAndroidLogo({
      ...initialAndroidLogo,
      src: iconLogoUrl,
    });

    setIosLogo({
      ...initialIosLogo,
      src: logoImageUrl,
    });

    setBannerLogo({
      ...initialBannerLogo,
      src: bannerImageUrl,
      aspect: calculateBannerAspectRatio(),
    });

    setGoogleBannerLogo({
      ...initialGoogleBannerLogo,
      src: googleBannerImageUrl,
      aspect: calculateBannerAspectRatio(),
    });

    setStampZeroLogo({
      ...initialBannerLogo,
      src: stampZeroImageUrl,
      aspect: calculateBannerAspectRatio(),
    });

    setStampOneLogo({
      ...initialBannerLogo,
      src: stampOneImageUrl,
      aspect: calculateBannerAspectRatio(),
    });

    setStampTwoLogo({
      ...initialBannerLogo,
      src: stampTwoImageUrl,
      aspect: calculateBannerAspectRatio(),
    });

    setStampThreeLogo({
      ...initialBannerLogo,
      src: stampThreeImageUrl,
      aspect: calculateBannerAspectRatio(),
    });

    setStampFourLogo({
      ...initialBannerLogo,
      src: stampFourImageUrl,
      aspect: calculateBannerAspectRatio(),
    });

    setStampFiveLogo({
      ...initialBannerLogo,
      src: stampFiveImageUrl,
      aspect: calculateBannerAspectRatio(),
    });

    setStampSixLogo({
      ...initialBannerLogo,
      src: stampSixImageUrl,
      aspect: calculateBannerAspectRatio(),
    });

    setStampSevenLogo({
      ...initialBannerLogo,
      src: stampSevenImageUrl,
      aspect: calculateBannerAspectRatio(),
    });

    setStampEightLogo({
      ...initialBannerLogo,
      src: stampEightImageUrl,
      aspect: calculateBannerAspectRatio(),
    });

    setStampNineLogo({
      ...initialBannerLogo,
      src: stampNineImageUrl,
      aspect: calculateBannerAspectRatio(),
    });

    setStampTenLogo({
      ...initialBannerLogo,
      src: stampTenImageUrl,
      aspect: calculateBannerAspectRatio(),
    });

    setStampElevenLogo({
      ...initialBannerLogo,
      src: stampElevenImageUrl,
      aspect: calculateBannerAspectRatio(),
    });

    setStampTwelveLogo({
      ...initialBannerLogo,
      src: stampTwelveImageUrl,
      aspect: calculateBannerAspectRatio(),
    });
  }
  const context = {
    templateName,
    updateTemplateName: updateTemplateNameHandler,
    uuid,
    updateUuid: updateUuidHandler,
    templateEntity,
    updateTemplateEntity: updateTemplateEntityHandler,
    passType,
    updatePassType: updatePassTypeHandler,
    headerOneId,
    updateHeaderOneId: updateHeaderOneIdHandler,
    headerOneLabel,
    updateHeaderOneLabel: updateHeaderOneLabelHandler,
    headerOneValue,
    updateHeaderOneValue: updateHeaderOneValueHandler,
    headerTwoId,
    updateHeaderTwoId: updateHeaderTwoIdHandler,
    headerTwoLabel,
    updateHeaderTwoLabel: updateHeaderTwoLabelHandler,
    headerTwoValue,
    updateHeaderTwoValue: updateHeaderTwoValueHandler,
    headerThreeId,
    updateHeaderThreeId: updateHeaderThreeIdHandler,
    headerThreeLabel,
    updateHeaderThreeLabel: updateHeaderThreeLabelHandler,
    headerThreeValue,
    updateHeaderThreeValue: updateHeaderThreeValueHandler,
    centralTitle,
    updateCentralTitle: updateCentralTitleHandler,
    googleNfcEnabled,
    updateGoogleNfcEnabled: updateGoogleNfcEnabledHandler,
    primaryOneId,
    updatePrimaryOneId: updatePrimaryOneIdHandler,
    primaryOneLabel,
    updatePrimaryOneLabel: updatePrimaryOneLabelHandler,
    primaryOneValue,
    updatePrimaryOneValue: updatePrimaryOneValueHandler,
    primaryTwoId,
    updatePrimaryTwoId: updatePrimaryTwoIdHandler,
    primaryTwoLabel,
    updatePrimaryTwoLabel: updatePrimaryTwoLabelHandler,
    primaryTwoValue,
    updatePrimaryTwoValue: updatePrimaryTwoValueHandler,
    iosLogo,
    updateIosLogo: updateIosLogoHandler,
    resetIosLogo: resetIosLogoHandler,
    thumbnailLogo,
    updateThumbnailLogo: updateThumbnailLogoHandler,
    resetThumbnailLogo: resetThumbnailLogoHandler,
    monochromeBadgeLogo,
    updateMonochromeBadgeLogo: updateMonochromeBadgeLogoHandler,
    resetMonochromeBadgeLogo: resetMonochromeBadgeLogoHandler,
    androidLogo,
    updateAndroidLogo: updateAndroidLogoHandler,
    resetAndroidLogo: resetAndroidLogoHandler,
    bannerLogo,
    updateBannerLogo: updateBannerLogoHandler,
    resetBannerLogo: resetBannerLogoHandler,
    googleBannerLogo,
    updateGoogleBannerLogo: updateGoogleBannerLogoHandler,
    resetGoogleBannerLogo: resetGoogleBannerLogoHandler,
    logoImage: logoImage,
    updateLogoImage: updateLogoImageHandler,
    thumbnailImage: thumbnailImage,
    updateThumbnailImage: updateThumbnailImageHandler,
    monochromeBadge: monochromeBadge,
    updateMonochromeBadge: updateMonochromeBadgeHandler,
    iconLogo: iconLogo,
    updateIconLogo: updateIconLogoHandler,
    bannerImage: bannerImage,
    updateBannerImage: updateBannerImageHandler,
    googleBannerImage: googleBannerImage,
    updateGoogleBannerImage: updateGoogleBannerImageHandler,
    secOneId: secOneId,
    updateSecOneId: updateSecOneIdHandler,
    secOneLabel: secOneLabel,
    updateSecOneLabel: updateSecOneLabelHandler,
    secOneValue: secOneValue,
    updateSecOneValue: updateSecOneValueHandler,
    secTwoId: secTwoId,
    updateSecTwoId: updateSecTwoIdHandler,
    secTwoLabel: secTwoLabel,
    updateSecTwoLabel: updateSecTwoLabelHandler,
    secTwoValue: secTwoValue,
    updateSecTwoValue: updateSecTwoValueHandler,
    secThreeId: secThreeId,
    updateSecThreeId: updateSecThreeIdHandler,
    secThreeLabel: secThreeLabel,
    updateSecThreeLabel: updateSecThreeLabelHandler,
    secThreeValue: secThreeValue,
    updateSecThreeValue: updateSecThreeValueHandler,
    description: description,
    updateDescription: updateDescriptionHandler,
    auxOneId: auxOneId,
    updateAuxOneId: updateAuxOneIdHandler,
    auxOneLabel: auxOneLabel,
    updateAuxOneLabel: updateAuxOneLabelHandler,
    auxOneValue: auxOneValue,
    updateAuxOneValue: updateAuxOneValueHandler,
    auxTwoId: auxTwoId,
    updateAuxTwoId: updateAuxTwoIdHandler,
    auxTwoLabel: auxTwoLabel,
    updateAuxTwoLabel: updateAuxTwoLabelHandler,
    auxTwoValue: auxTwoValue,
    updateAuxTwoValue: updateAuxTwoValueHandler,
    auxThreeId: auxThreeId,
    updateAuxThreeId: updateAuxThreeIdHandler,
    auxThreeLabel: auxThreeLabel,
    updateAuxThreeLabel: updateAuxThreeLabelHandler,
    auxThreeValue: auxThreeValue,
    updateAuxThreeValue: updateAuxThreeValueHandler,
    backOneId: backOneId,
    updateBackOneId: updateBackOneIdHandler,
    backOneLabel: backOneLabel,
    updateBackOneLabel: updateBackOneLabelHandler,
    backOneValue: backOneValue,
    updateBackOneValue: updateBackOneValueHandler,
    backTwoId: backTwoId,
    updateBackTwoId: updateBackTwoIdHandler,
    backTwoLabel: backTwoLabel,
    updateBackTwoLabel: updateBackTwoLabelHandler,
    backTwoValue: backTwoValue,
    updateBackTwoValue: updateBackTwoValueHandler,
    backThreeId: backThreeId,
    updateBackThreeId: updateBackThreeIdHandler,
    backThreeLabel: backThreeLabel,
    updateBackThreeLabel: updateBackThreeLabelHandler,
    backThreeValue: backThreeValue,
    updateBackThreeValue: updateBackThreeValueHandler,
    backFourId: backFourId,
    updateBackFourId: updateBackFourIdHandler,
    backFourLabel: backFourLabel,
    updateBackFourLabel: updateBackFourLabelHandler,
    backFourValue: backFourValue,
    updateBackFourValue: updateBackFourValueHandler,
    backFiveId: backFiveId,
    updateBackFiveId: updateBackFiveIdHandler,
    backFiveLabel: backFiveLabel,
    updateBackFiveLabel: updateBackFiveLabelHandler,
    backFiveValue: backFiveValue,
    updateBackFiveValue: updateBackFiveValueHandler,
    passBackgroundColor: passBackgroundColor,
    updatePassBackgroundColor: updatePassBackgroundColorHandler,
    titleFontColor: titleFontColor,
    updateTitleFontColor: updateTitleFontColorHandler,
    bodyFontColor: bodyFontColor,
    updateBodyFontColor: updateBodyFontColorHandler,
    updateAllData: updateAllDataHandler,
    notificationHeader: notificationHeader,
    updateNotificationHeader: updateNotificationHeaderHandler,
    appleAppLink: appleAppLink,
    updateAppleAppLink: updateAppleAppLinkHandler,
    googleAppLinkPlayStoreUrl: googleAppLinkPlayStoreUrl,
    updateGoogleAppLinkPlayStoreUrl: updateGoogleAppLinkPlayStoreUrlHandler,
    googleAppLinkPackageName: googleAppLinkPackageName,
    updateGoogleAppLinkPackageName: updateGoogleAppLinkPackageNameHandler,
    googleAppLinkWebAppUrl: googleAppLinkWebAppUrl,
    updateGoogleAppLinkWebAppUrl: updateGoogleAppLinkWebAppUrlHandler,
    stampCount: stampCount,
    updateStampCount: updateStampCountHandler,
    stampShortKey: stampShortKey,
    updateStampShortKey: updateStampShortKeyHandler,
    stampShortKeyField: stampShortKeyField,
    updateStampShortKeyField: updateStampShortKeyFieldHandler,
    stampLongKey: stampLongKey,
    updateStampLongKey: updateStampLongKeyHandler,
    stampLongKeyField: stampLongKeyField,
    updateStampLongKeyField: updateStampLongKeyFieldHandler,
    stampZeroMessage: stampZeroMessage,
    updateStampZeroMessage: updateStampZeroMessageHandler,
    stampOneMessage: stampOneMessage,
    updateStampOneMessage: updateStampOneMessageHandler,
    stampTwoMessage: stampTwoMessage,
    updateStampTwoMessage: updateStampTwoMessageHandler,
    stampThreeMessage: stampThreeMessage,
    updateStampThreeMessage: updateStampThreeMessageHandler,
    stampFourMessage: stampFourMessage,
    updateStampFourMessage: updateStampFourMessageHandler,
    stampFiveMessage: stampFiveMessage,
    updateStampFiveMessage: updateStampFiveMessageHandler,
    stampSixMessage: stampSixMessage,
    updateStampSixMessage: updateStampSixMessageHandler,
    stampSevenMessage: stampSevenMessage,
    updateStampSevenMessage: updateStampSevenMessageHandler,
    stampEightMessage: stampEightMessage,
    updateStampEightMessage: updateStampEightMessageHandler,
    stampNineMessage: stampNineMessage,
    updateStampNineMessage: updateStampNineMessageHandler,
    stampTenMessage: stampTenMessage,
    updateStampTenMessage: updateStampTenMessageHandler,
    stampElevenMessage: stampElevenMessage,
    updateStampElevenMessage: updateStampElevenMessageHandler,
    stampTwelveMessage: stampTwelveMessage,
    updateStampTwelveMessage: updateStampTwelveMessageHandler,
    stampZeroShortValue: stampZeroShortValue,
    updateStampZeroShortValue: updateStampZeroShortValueHandler,
    stampOneShortValue: stampOneShortValue,
    updateStampOneShortValue: updateStampOneShortValueHandler,
    stampTwoShortValue: stampTwoShortValue,
    updateStampTwoShortValue: updateStampTwoShortValueHandler,
    stampThreeShortValue: stampThreeShortValue,
    updateStampThreeShortValue: updateStampThreeShortValueHandler,
    stampFourShortValue: stampFourShortValue,
    updateStampFourShortValue: updateStampFourShortValueHandler,
    stampFiveShortValue: stampFiveShortValue,
    updateStampFiveShortValue: updateStampFiveShortValueHandler,
    stampSixShortValue: stampSixShortValue,
    updateStampSixShortValue: updateStampSixShortValueHandler,
    stampSevenShortValue: stampSevenShortValue,
    updateStampSevenShortValue: updateStampSevenShortValueHandler,
    stampEightShortValue: stampEightShortValue,
    updateStampEightShortValue: updateStampEightShortValueHandler,
    stampNineShortValue: stampNineShortValue,
    updateStampNineShortValue: updateStampNineShortValueHandler,
    stampTenShortValue: stampTenShortValue,
    updateStampTenShortValue: updateStampTenShortValueHandler,
    stampElevenShortValue: stampElevenShortValue,
    updateStampElevenShortValue: updateStampElevenShortValueHandler,
    stampTwelveShortValue: stampTwelveShortValue,
    updateStampTwelveShortValue: updateStampTwelveShortValueHandler,
    stampZeroLongValue: stampZeroLongValue,
    updateStampZeroLongValue: updateStampZeroLongValueHandler,
    stampOneLongValue: stampOneLongValue,
    updateStampOneLongValue: updateStampOneLongValueHandler,
    stampTwoLongValue: stampTwoLongValue,
    updateStampTwoLongValue: updateStampTwoLongValueHandler,
    stampThreeLongValue: stampThreeLongValue,
    updateStampThreeLongValue: updateStampThreeLongValueHandler,
    stampFourLongValue: stampFourLongValue,
    updateStampFourLongValue: updateStampFourLongValueHandler,
    stampFiveLongValue: stampFiveLongValue,
    updateStampFiveLongValue: updateStampFiveLongValueHandler,
    stampSixLongValue: stampSixLongValue,
    updateStampSixLongValue: updateStampSixLongValueHandler,
    stampSevenLongValue: stampSevenLongValue,
    updateStampSevenLongValue: updateStampSevenLongValueHandler,
    stampEightLongValue: stampEightLongValue,
    updateStampEightLongValue: updateStampEightLongValueHandler,
    stampNineLongValue: stampNineLongValue,
    updateStampNineLongValue: updateStampNineLongValueHandler,
    stampTenLongValue: stampTenLongValue,
    updateStampTenLongValue: updateStampTenLongValueHandler,
    stampElevenLongValue: stampElevenLongValue,
    updateStampElevenLongValue: updateStampElevenLongValueHandler,
    stampTwelveLongValue: stampTwelveLongValue,
    updateStampTwelveLongValue: updateStampTwelveLongValueHandler,
    stampZeroImage: stampZeroImage,
    updateStampZeroImage: updateStampZeroImageHandler,
    stampOneImage: stampOneImage,
    updateStampOneImage: updateStampOneImageHandler,
    stampTwoImage: stampTwoImage,
    updateStampTwoImage: updateStampTwoImageHandler,
    stampThreeImage: stampThreeImage,
    updateStampThreeImage: updateStampThreeImageHandler,
    stampFourImage: stampFourImage,
    updateStampFourImage: updateStampFourImageHandler,
    stampFiveImage: stampFiveImage,
    updateStampFiveImage: updateStampFiveImageHandler,
    stampSixImage: stampSixImage,
    updateStampSixImage: updateStampSixImageHandler,
    stampSevenImage: stampSevenImage,
    updateStampSevenImage: updateStampSevenImageHandler,
    stampEightImage: stampEightImage,
    updateStampEightImage: updateStampEightImageHandler,
    stampNineImage: stampNineImage,
    updateStampNineImage: updateStampNineImageHandler,
    stampTenImage: stampTenImage,
    updateStampTenImage: updateStampTenImageHandler,
    stampElevenImage: stampElevenImage,
    updateStampElevenImage: updateStampElevenImageHandler,
    stampTwelveImage: stampTwelveImage,
    updateStampTwelveImage: updateStampTwelveImageHandler,
    stampZeroLogo: stampZeroLogo,
    updateStampZeroLogo: updateStampZeroLogoHandler,
    resetStampZeroLogo: resetStampZeroLogoHandler,
    stampOneLogo: stampOneLogo,
    updateStampOneLogo: updateStampOneLogoHandler,
    resetStampOneLogo: resetStampOneLogoHandler,
    stampTwoLogo: stampTwoLogo,
    updateStampTwoLogo: updateStampTwoLogoHandler,
    resetStampTwoLogo: resetStampTwoLogoHandler,
    stampThreeLogo: stampThreeLogo,
    updateStampThreeLogo: updateStampThreeLogoHandler,
    resetStampThreeLogo: resetStampThreeLogoHandler,
    stampFourLogo: stampFourLogo,
    updateStampFourLogo: updateStampFourLogoHandler,
    resetStampFourLogo: resetStampFourLogoHandler,
    stampFiveLogo: stampFiveLogo,
    updateStampFiveLogo: updateStampFiveLogoHandler,
    resetStampFiveLogo: resetStampFiveLogoHandler,
    stampSixLogo: stampSixLogo,
    updateStampSixLogo: updateStampSixLogoHandler,
    resetStampSixLogo: resetStampSixLogoHandler,
    stampSevenLogo: stampSevenLogo,
    updateStampSevenLogo: updateStampSevenLogoHandler,
    resetStampSevenLogo: resetStampSevenLogoHandler,
    stampEightLogo: stampEightLogo,
    updateStampEightLogo: updateStampEightLogoHandler,
    resetStampEightLogo: resetStampEightLogoHandler,
    stampNineLogo: stampNineLogo,
    updateStampNineLogo: updateStampNineLogoHandler,
    resetStampNineLogo: resetStampNineLogoHandler,
    stampTenLogo: stampTenLogo,
    updateStampTenLogo: updateStampTenLogoHandler,
    resetStampTenLogo: resetStampTenLogoHandler,
    stampElevenLogo: stampElevenLogo,
    updateStampElevenLogo: updateStampElevenLogoHandler,
    resetStampElevenLogo: resetStampElevenLogoHandler,
    stampTwelveLogo: stampTwelveLogo,
    updateStampTwelveLogo: updateStampTwelveLogoHandler,
    resetStampTwelveLogo: resetStampTwelveLogoHandler,
    stampZeroWatermarkColor: stampZeroWatermarkColor,
    updateStampZeroWatermarkColor: updateStampZeroWatermarkColorHandler,
    stampZeroWatermarkPosition: stampZeroWatermarkPosition,
    updateStampZeroWatermarkPosition: updateStampZeroWatermarkPositionHandler,
    stampOneWatermarkColor: stampOneWatermarkColor,
    updateStampOneWatermarkColor: updateStampOneWatermarkColorHandler,
    stampOneWatermarkPosition: stampOneWatermarkPosition,
    updateStampOneWatermarkPosition: updateStampOneWatermarkPositionHandler,
    stampTwoWatermarkColor: stampTwoWatermarkColor,
    updateStampTwoWatermarkColor: updateStampTwoWatermarkColorHandler,
    stampTwoWatermarkPosition: stampTwoWatermarkPosition,
    updateStampTwoWatermarkPosition: updateStampTwoWatermarkPositionHandler,
    stampThreeWatermarkColor: stampThreeWatermarkColor,
    updateStampThreeWatermarkColor: updateStampThreeWatermarkColorHandler,
    stampThreeWatermarkPosition: stampThreeWatermarkPosition,
    updateStampThreeWatermarkPosition: updateStampThreeWatermarkPositionHandler,
    stampFourWatermarkColor: stampFourWatermarkColor,
    updateStampFourWatermarkColor: updateStampFourWatermarkColorHandler,
    stampFourWatermarkPosition: stampFourWatermarkPosition,
    updateStampFourWatermarkPosition: updateStampFourWatermarkPositionHandler,
    stampFiveWatermarkColor: stampFiveWatermarkColor,
    updateStampFiveWatermarkColor: updateStampFiveWatermarkColorHandler,
    stampFiveWatermarkPosition: stampFiveWatermarkPosition,
    updateStampFiveWatermarkPosition: updateStampFiveWatermarkPositionHandler,
    stampSixWatermarkColor: stampSixWatermarkColor,
    updateStampSixWatermarkColor: updateStampSixWatermarkColorHandler,
    stampSixWatermarkPosition: stampSixWatermarkPosition,
    updateStampSixWatermarkPosition: updateStampSixWatermarkPositionHandler,
    stampSevenWatermarkColor: stampSevenWatermarkColor,
    updateStampSevenWatermarkColor: updateStampSevenWatermarkColorHandler,
    stampSevenWatermarkPosition: stampSevenWatermarkPosition,
    updateStampSevenWatermarkPosition: updateStampSevenWatermarkPositionHandler,
    stampEightWatermarkColor: stampEightWatermarkColor,
    updateStampEightWatermarkColor: updateStampEightWatermarkColorHandler,
    stampEightWatermarkPosition: stampEightWatermarkPosition,
    updateStampEightWatermarkPosition: updateStampEightWatermarkPositionHandler,
    stampNineWatermarkColor: stampNineWatermarkColor,
    updateStampNineWatermarkColor: updateStampNineWatermarkColorHandler,
    stampNineWatermarkPosition: stampNineWatermarkPosition,
    updateStampNineWatermarkPosition: updateStampNineWatermarkPositionHandler,
    stampTenWatermarkColor: stampTenWatermarkColor,
    updateStampTenWatermarkColor: updateStampTenWatermarkColorHandler,
    stampTenWatermarkPosition: stampTenWatermarkPosition,
    updateStampTenWatermarkPosition: updateStampTenWatermarkPositionHandler,
    stampElevenWatermarkColor: stampElevenWatermarkColor,
    updateStampElevenWatermarkColor: updateStampElevenWatermarkColorHandler,
    stampElevenWatermarkPosition: stampElevenWatermarkPosition,
    updateStampElevenWatermarkPosition:
      updateStampElevenWatermarkPositionHandler,
    stampTwelveWatermarkColor: stampTwelveWatermarkColor,
    updateStampTwelveWatermarkColor: updateStampTwelveWatermarkColorHandler,
    stampTwelveWatermarkPosition: stampTwelveWatermarkPosition,
    updateStampTwelveWatermarkPosition:
      updateStampTwelveWatermarkPositionHandler,
    locations: locations,
    locationCount: locationCount,
    updateLocationCount: updateLocationCountHandler,
    updateLocations: updateLocationsHandler,
    beacons: beacons,
    beaconCount: beaconCount,
    updateBeaconCount: updateBeaconCountHandler,
    updateBeacons: updateBeaconsHandler,
    activeStep: activeStep,
    updateActiveStep: updateActiveStepHandler,
    clearAllStampKeyValues: clearAllStampKeyValuesHandler,
    watermarkColor: watermarkColor,
    updateWatermarkColor: updateWatermarkColorHandler,
    watermarkPosition: watermarkPosition,
    updateWatermarkPosition: updateWatermarkPositionHandler,
    googleBannerWatermarkColor: googleBannerWatermarkColor,
    updateGoogleBannerWatermarkColor: updateGoogleBannerWatermarkColorHandler,
    googleBannerWatermarkPosition: googleBannerWatermarkPosition,
    updateGoogleBannerWatermarkPosition:
      updateGoogleBannerWatermarkPositionHandler,
    displayBannerWatermarkPreview: displayBannerWatermarkPreview,
    updateDisplayBannerWatermarkPreview:
      updateDisplayBannerWatermarkPreviewHandler,
    displayGoogleBannerWatermarkPreview: displayGoogleBannerWatermarkPreview,
    updateDisplayGoogleBannerWatermarkPreview:
      updateDisplayGoogleBannerWatermarkPreviewHandler,
    loyaltyConfigEnabled: loyaltyConfigEnabled,
    updateLoyaltyConfigEnabled: updateLoyaltyConfigEnabledHandler,
    loyaltyConfigNumberType: loyaltyConfigNumberType,
    updateLoyaltyConfigNumberType: updateLoyaltyConfigNumberTypeHandler,
  };

  return (
    <PassTemplateContext.Provider value={context}>
      {props.children}
    </PassTemplateContext.Provider>
  );
}

export default PassTemplateContext;
