import { Icon } from '@chakra-ui/react';

export const CustomTapSuccessIcon = (props) => (
  <Icon viewBox="0 0 17 17" {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        d="M6.0245 0.943848L3.82336 0.943848C2.23278 0.943848 0.943359 2.23327 0.943359 3.82385L0.943359 6.02499"
        stroke="white"
        strokeWidth="1.6128"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.0245 15.9199H3.82336C2.23278 15.9199 0.943359 14.6305 0.943359 13.0399L0.943359 10.8388"
        stroke="white"
        strokeWidth="1.6128"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.8378 0.943848L13.0389 0.943848C14.6295 0.943848 15.9189 2.23327 15.9189 3.82385L15.9189 6.02499"
        stroke="white"
        strokeWidth="1.6128"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.8378 15.9199L13.0389 15.9199C14.6295 15.9199 15.9189 14.6305 15.9189 13.0399V10.8388"
        stroke="white"
        strokeWidth="1.6128"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 9.01904L7.0352 11.2782"
        stroke="white"
        strokeWidth="1.6128"
        stroke-linecap="round"
      />
      <path
        d="M12.3135 6L7.0352 11.2783"
        stroke="white"
        strokeWidth="1.6128"
        stroke-linecap="round"
      />
    </svg>
  </Icon>
);
