import {
  Container,
  Divider,
  Text,
  useColorModeValue,
  Box,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
import { useContext } from 'react';
import Breadcrumbs from '../../../common/Breadcrumbs';
import EventPrimaryFields from './EventPrimaryFields';
import EventsMessages from './EventsMessages';
import EventsContext from '../../../store/client/EventsContext';
import EventViewHeader from './EventViewHeader';

const EventViewPageView = () => {
  const eventsCtx = useContext(EventsContext);
  const modeBoxBg = useColorModeValue('primaryBackground', 'secondaryDark');

  const breadcrumbLinks = [
    {
      linkName: 'Home',
      linkUrl: '/',
    },
    {
      linkName: 'Events',
      linkUrl: '/events',
    },
    {
      linkName: eventsCtx?.eventState?.name,
      linkUrl: null,
    },
  ];

  return (
    <Container display="flex" flexDir="column" flex="1" pb={6}>
      <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />
      <EventViewHeader />
      <Divider borderColor="primaryBackground" mb={6} />
      <Box>
        <EventPrimaryFields />
      </Box>
    </Container>
  );
};
export default EventViewPageView;
