import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Hide,
  Input,
  Link,
  Show,
  Stack,
  Switch,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { HttpContext } from '../../../context/HttpContext';
import { useForm } from 'react-hook-form';
import appsignal from '../../../appsignal';
import KeyHolder from './KeyHolder';
import ApiDocToggleVisibility from './ApiKeyItemVisibilityToggler';
import { CustomRefreshIcon } from '../../../theme/icons/CustomRefreshIcon';
import RefreshKeyModal from '../../modals/RefreshKeyModal';
import CustomToast from '../../../common/CustomToast';
import { CustomReactSelect } from '../../common/CustomReactSelect';
import { CustomLinkIcon } from '../../../theme/icons/CustomLinkIcon';
import { CustomExclamationMarkInverseIcon } from '../../../theme/icons/CustomExclamationMarkInverseIcon';

const WebhooksContainer = () => {
  const modeDisabledBg = useColorModeValue(
    'secondaryBackground',
    'secondaryDark06'
  );
  const modeDisabledText = useColorModeValue('secondaryDark06', '#848C91');
  const [url, setUrl] = useState('');
  const [enabled, setEnabled] = useState(false);
  const [secretKey, setSecretKey] = useState('');
  const [webhooksDisabled, setWebhooksDisabled] = useState(true);
  const [subscribedWebhooks, setSubscribedWebhooks] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [formattedOptions, setFormattedOptions] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const toast = useToast();
  const fetchIdRef = useRef(0);
  const tooltipRef = useRef();
  const bgTooltip = useColorModeValue('secondaryDark', '#dddddd');
  const textTooltip = useColorModeValue('#ffffff', 'primaryDark');
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { authAxios } = useContext(HttpContext);

  const {
    isOpen: isRefreshKeyModalOpen,
    onOpen: onRefreshKeyModalOpen,
    onClose: onRefreshKeyModalClose,
  } = useDisclosure();

  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const modeFormControlBg = useColorModeValue('white', 'primaryDark08');
  const modeLabelBg = useColorModeValue('#EFEFEF', 'primaryDark03');
  const [visibleKeyUUID, setVisibleKeyUUID] = useState('');
  const [isKeyVisible, setIsKeyVisible] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm();

  const prepareDataForSubmit = () => {
    subscribedWebhooks.forEach((webhookEvent) => {
      Object.keys(webhookEvent).forEach((key) => {
        const found = selectedOptions.some((option) => option.value === key);
        webhookEvent[key] = found;
      });
    });

    let submittedData = {
      url: url,
      enabled: enabled,
    };

    subscribedWebhooks.forEach((webhookEvent) => {
      submittedData = { ...submittedData, ...webhookEvent };
    });
    return submittedData;
  };

  const onSubmit = async (values) => {
    try {
      const submittedData = prepareDataForSubmit(values);
      await authAxios.patch(`/api/v1/webhook_subscriptions`, submittedData);
      toast({
        render: ({ onClose }) => (
          <CustomToast
            status="success"
            title="Webhook settings updated"
            description=""
            onClose={onClose}
          />
        ),
      });
    } catch (onError) {
      appsignal.sendError(onError);
      console.log(onError);
      if (onError.response?.data?.errors) {
        onError.response.data.errors.forEach((error) => {
          toast({
            render: ({ onClose }) => (
              <CustomToast status="error" title={error} onClose={onClose} />
            ),
          });
        });
      } else {
        toast({
          render: ({ onClose }) => (
            <CustomToast
              status="error"
              title="Something went wrong"
              description="Please try again"
              onClose={onClose}
            />
          ),
        });
      }
    }
  };

  const changeValue = () => {
    setValue('enabled', !enabled);
    setEnabled(!enabled);
  };

  const handleSwitchChange = () => {
    changeValue('enabled');
    setBtnDisabled(false);
  };

  const showTooltipHandler = (e) => {
    e.preventDefault();
    tooltipOpen ? setTooltipOpen(false) : setTooltipOpen(true);
  };

  const fetchData = useCallback(
    ({ newKey }) => {
      const fetchId = ++fetchIdRef.current;
      setTimeout(async () => {
        if (fetchId === fetchIdRef.current) {
          try {
            const response = await authAxios.get(
              `/api/v1/webhook_subscriptions`
            );
            const data = response.data.data.attributes;
            setSecretKey(data.secretKey);
            if (!newKey) {
              setUrl(data.url);
              setEnabled(data.enabled);
              setSubscribedWebhooks(data.subscribedWebhooks);
              let existingWebhooks = [];
              const formattedData = data.subscribedWebhooks.map(
                (webhookEvent) => {
                  let option;
                  for (const [key, value] of Object.entries(webhookEvent)) {
                    option = {
                      value: key,
                      label: key
                        .replace(/([A-Z])/g, ' $1')
                        .replace(/^./, function (str) {
                          return str.toUpperCase();
                        }),
                    };
                    if (value === true) {
                      existingWebhooks.push(option);
                    }
                  }
                  return option;
                }
              );
              setSelectedOptions(existingWebhooks);
              setFormattedOptions(formattedData);
            }
          } catch (onError) {
            appsignal.sendError(onError);
            console.log(onError);
          }
        }
      }, 0);
    },
    [authAxios]
  );

  useEffect(() => {
    fetchData({ newKey: false });
  }, [fetchData]);

  useEffect(() => {
    setWebhooksDisabled(enabled ? false : true);
  }, [enabled]);

  useEffect(() => {
    setIsKeyVisible(false);
    setVisibleKeyUUID('');
  }, [enabled]);

  return (
    <>
      <Box mt={6} className="autofillForDarkBg" w="full">
        <Box className="primaryBoxShadow" borderRadius="15px">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack
              direction="row"
              pt={6}
              px={6}
              pb={3}
              spacing={3}
              borderRadius="15px 15px 0 0"
              bg="white"
            >
              <Switch
                id="enabled"
                {...register('enabled')}
                isChecked={enabled}
                onChange={() => handleSwitchChange()}
              />
              <Heading size="lg" lineHeight={1}>
                Webhooks
              </Heading>
              <Tooltip
                label={
                  'Enabling webhooks allows you to subscribe to events and receive real-time notifications.'
                }
                hasArrow
                placement="top"
                fontSize="12px"
                bg={bgTooltip}
                color={textTooltip}
                w="205px"
                borderRadius="6px"
                textAlign="center"
                p="10px"
                isOpen={tooltipOpen}
              >
                <Box
                  onMouseEnter={(e) => showTooltipHandler(e)}
                  onMouseLeave={(e) => showTooltipHandler(e)}
                  ref={tooltipRef}
                  mb={1}
                >
                  <CustomExclamationMarkInverseIcon
                    mb={1.5}
                    boxSize="15px"
                    mode={'light'}
                  />
                </Box>
              </Tooltip>
            </Stack>
            <Divider borderColor={modeBorderColor} />
            <FormControl
              isDisabled={webhooksDisabled}
              bg={webhooksDisabled ? modeDisabledBg : modeFormControlBg}
              isInvalid={errors.email}
            >
              <Flex alignItems="center">
                <Hide below="2xl">
                  <Box pl="35px" flexShrink={0} w="240px">
                    <FormLabel m={0}>Secret key</FormLabel>
                  </Box>
                </Hide>
                <Box p="15px 20px" w="full" bg={modeLabelBg}>
                  <Show below="2xl">
                    <FormLabel>Secret key</FormLabel>
                  </Show>
                  <Flex
                    direction={{ base: 'column', sm: 'row' }}
                    p="14px"
                    justify="space-between"
                  >
                    <KeyHolder
                      id="secretKey"
                      secretKey={secretKey}
                      visibleKeyUUID={visibleKeyUUID}
                      isKeyVisible={isKeyVisible}
                    />
                    <Flex
                      mt={{ base: 2, sm: 0 }}
                      direction={{ base: 'column', sm: 'row' }}
                    >
                      <ApiDocToggleVisibility
                        id="secretKey"
                        visibleKeyUUID={visibleKeyUUID}
                        setVisibleKeyUUID={setVisibleKeyUUID}
                        setIsKeyVisible={setIsKeyVisible}
                      />
                      <Box
                        onClick={
                          webhooksDisabled ? null : onRefreshKeyModalOpen
                        }
                        cursor={webhooksDisabled ? 'not-allowed' : 'pointer'}
                        w="135px"
                      >
                        <Flex
                          justifyContent={{ base: 'start', md: 'center' }}
                          alignItems="center"
                        >
                          <CustomRefreshIcon
                            boxSize="14px"
                            color={webhooksDisabled && '#848B90'}
                          />
                          <Text
                            pl="7px"
                            pt="1px"
                            color={
                              webhooksDisabled ? modeDisabledText : '#EC675A'
                            }
                          >
                            Refresh
                          </Text>
                        </Flex>
                      </Box>
                    </Flex>
                  </Flex>
                  <FormErrorMessage>
                    {errors.email?.message || errors.email}
                  </FormErrorMessage>
                </Box>
              </Flex>
            </FormControl>
            <Divider borderColor={modeBorderColor} />

            <FormControl
              isInvalid={errors.password}
              isDisabled={webhooksDisabled}
              bg={webhooksDisabled ? modeDisabledBg : modeFormControlBg}
            >
              <Flex alignItems="center">
                <Hide below="2xl">
                  <Box pl="35px" flexShrink={0} w="240px">
                    <FormLabel m={0}>URL</FormLabel>
                  </Box>
                </Hide>
                <Box p="15px 20px" w="full" bg={modeLabelBg}>
                  <Show below="2xl">
                    <FormLabel>URL</FormLabel>
                  </Show>
                  <Input
                    variant="filledForDarkBg"
                    placeholder="Enter URL"
                    value={url}
                    onChange={(e) => {
                      setUrl(e.target.value);
                      setBtnDisabled(false);
                    }}
                    onBlur={() => setBtnDisabled(false)}
                  />
                </Box>
              </Flex>
            </FormControl>
            <Divider borderColor={modeBorderColor} />

            <FormControl
              isInvalid={errors.password}
              isDisabled={webhooksDisabled}
              bg={webhooksDisabled ? modeDisabledBg : modeFormControlBg}
            >
              <Flex alignItems="center">
                <Hide below="2xl">
                  <Box pl="35px" flexShrink={0} w="240px">
                    <Flex direction="row" alignItems="center" gap={2}>
                      <FormLabel m={0}>Events</FormLabel>
                      <Link
                        href="https://developers.passentry.com/#tag/Webhooks"
                        alt="Webhooks API documentation"
                        isExternal
                      >
                        <CustomLinkIcon
                          color={webhooksDisabled ? '#878E94' : '#f28074'}
                          boxSize="15px"
                          mb="5px"
                        />
                      </Link>
                    </Flex>
                  </Box>
                </Hide>
                <Box p="15px 20px" w="full" bg={modeLabelBg}>
                  <Show below="2xl">
                    <Flex
                      direction="row"
                      alignItems="center"
                      gap={2}
                      mb={{ base: 1 }}
                    >
                      <FormLabel m={0}>Events</FormLabel>
                      <Link
                        href="https://developers.passentry.com/#tag/Webhooks"
                        alt="Webhooks API documentation"
                        isExternal
                      >
                        <CustomLinkIcon
                          color={webhooksDisabled ? '#878E94' : '#f28074'}
                          boxSize="15px"
                          mb="3px"
                        />
                      </Link>
                    </Flex>
                  </Show>
                  <CustomReactSelect
                    id="webhookEvents"
                    isMulti
                    options={formattedOptions}
                    value={selectedOptions}
                    onChange={(options) => {
                      setSelectedOptions(options);
                      setBtnDisabled(false);
                    }}
                    isDisabled={webhooksDisabled}
                  />
                </Box>
              </Flex>
            </FormControl>
            <Divider borderColor={modeBorderColor} />
            <Flex
              w="full"
              justifyContent="flex-end"
              bg="white"
              borderRadius="0 0 15px 15px"
              p="15px 14px"
            >
              <Button
                type="submit"
                isLoading={isSubmitting}
                isDisabled={btnDisabled}
                alt="Change email"
                size="sm"
              >
                Save
              </Button>
            </Flex>
          </form>
        </Box>
        <RefreshKeyModal
          isOpen={isRefreshKeyModalOpen}
          onClose={onRefreshKeyModalClose}
          fetchData={fetchData}
        />
      </Box>
    </>
  );
};
export default WebhooksContainer;
