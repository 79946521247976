import appsignal from '../../../appsignal';
import { useState, useContext, useEffect } from 'react';
import {
  Button,
  Checkbox,
  Collapse,
  Flex,
  Input,
  Text,
  useToast,
  useMediaQuery,
} from '@chakra-ui/react';
import { HttpContext } from '../../../context/HttpContext';
import PassContext from '../../../store/client/PassContext';
import validator from 'validator';
import CustomToast from '../../../common/CustomToast';
import { getErrorResponsePayload } from '../../../utils/ajax';

const PassLinkSender = (props) => {
  const { createdPassUuid } = props;
  const { authAxios } = useContext(HttpContext);
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [emailChecked, setEmailChecked] = useState(false);
  const [mobileChecked, setMobileChecked] = useState(false);
  const [smsChecked, setSMSChecked] = useState(false);
  const [whatsappChecked, setWhatsappChecked] = useState(false);
  const [mobileMsgTypeChecked, setMobileMsgTypeChecked] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [validMobile, setValidMobile] = useState(false);
  const [disableSend, setDisableSend] = useState(true);
  const passCtx = useContext(PassContext);
  const [isContextFetched, setIsContextFetched] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const [isMobile] = useMediaQuery('(max-width: 600px)');

  const validateInput = (input, errorSetter, validSetter, errorMessage) => {
    if (!input) {
      errorSetter('');
      validSetter(false);
    } else {
      let validInput =
        errorMessage === 'email address' ? validator.isEmail(input) : true;
      if (validInput) {
        errorSetter('');
        validSetter(true);
      } else {
        errorSetter(`Invalid ${errorMessage}`);
        validSetter(false);
      }
    }
  };

  const handleEmailValidation = () => {
    validateInput(email, setEmailError, setValidEmail, 'email address');
  };

  const handleMobileValidation = () => {
    validateInput(mobile, setMobileError, setValidMobile, 'mobile number');
  };

  const handleSendLink = async () => {
    const { uuid } = passCtx.passState;

    if (!isContextFetched) {
      return;
    }

    const sendData = {
      email: !!email && emailChecked ? email : null,
      mobile: !!mobile && mobileChecked ? mobile : null,
      pass: createdPassUuid || uuid,
      sms: smsChecked && mobileChecked,
      whatsapp: whatsappChecked && mobileChecked,
    };

    try {
      setIsSubmitting(true);
      const response = await authAxios.post(`/send_link`, sendData);
      const message = response?.data;

      const showToast = (status, title) => {
        toast({
          render: ({ onClose }) => (
            <CustomToast
              status={status}
              title={title}
              description=""
              onClose={onClose}
            />
          ),
        });
      };

      if (message?.sms) {
        showToast(
          message.sms === 'success' ? 'success' : 'error',
          message.sms === 'success'
            ? 'SMS sent successfully.'
            : 'SMS failed to send.'
        );
      }
      if (message?.whatsapp) {
        showToast(
          message.whatsapp === 'success' ? 'success' : 'error',
          message.whatsapp === 'success'
            ? 'WhatsApp sent successfully.'
            : 'WhatsApp failed to send.'
        );
      }
      if (message?.email) {
        showToast(
          message.email === 'success' ? 'success' : 'error',
          message.email === 'success'
            ? 'Email sent successfully.'
            : 'Email failed to send.'
        );
      }

      setIsSubmitting(false);
    } catch (onError) {
      appsignal.sendError(onError);
      console.log(onError);
      const { message, code } = getErrorResponsePayload(onError);
      setIsSubmitting(false);

      code !== 401 &&
        toast({
          render: (props) => (
            <CustomToast
              status="error"
              title={message ? message : `Something went wrong`}
              description={!message && 'Please try again later'}
              onClose={props.onClose}
            />
          ),
        });
    }
  };

  useEffect(() => {
    if (passCtx) {
      setIsContextFetched(true);
    }
  }, [passCtx]);

  useEffect(() => {
    if (emailChecked) {
      handleEmailValidation();
    } else {
      setValidEmail(true);
      setEmailError('');
    }
  }, [emailChecked, email]);

  useEffect(() => {
    if (mobileChecked) {
      handleMobileValidation();
      if (!smsChecked && !whatsappChecked) {
        setMobileMsgTypeChecked(false);
      }
    } else {
      setMobileMsgTypeChecked(true);
      setValidMobile(true);
      setMobileError('');
    }
  }, [mobileChecked, mobile]);

  useEffect(() => {
    if (mobileChecked && validMobile && !mobileMsgTypeChecked) {
      setMobileError('Please select message type');
    } else {
      setMobileError('');
    }
  }, [mobileChecked, validMobile, mobileMsgTypeChecked]);

  useEffect(() => {
    setMobileMsgTypeChecked(smsChecked || whatsappChecked);
  }, [smsChecked, whatsappChecked]);

  useEffect(() => {
    if (mobileChecked) {
      setDisableSend(!(validEmail && validMobile && mobileMsgTypeChecked));
    } else {
      setDisableSend(!(validEmail && validMobile));
    }
  }, [validEmail, validMobile, mobileMsgTypeChecked, mobileChecked]);

  return (
    <>
      <Flex mx={3}>
        <Flex direction="column" width="full">
          <Checkbox
            id="byEmail"
            onChange={() => setEmailChecked(!emailChecked)}
          >
            <Text fontSize="14px" color="secondaryDark">
              Email
            </Text>
          </Checkbox>
        </Flex>
        <Flex ml={3} direction="column" width="full">
          <Checkbox
            ml={5}
            id="byMobile"
            onChange={() => setMobileChecked(!mobileChecked)}
          >
            <Text fontSize="14px" color="secondaryDark">
              Mobile
            </Text>
          </Checkbox>
        </Flex>
      </Flex>
      <Collapse in={emailChecked || mobileChecked} animateOpacity>
        <Flex mx={3}>
          <Flex direction="column" width="full">
            <Input
              mt={3}
              variant="filledForDarkBg"
              size="sm"
              id="email"
              type="text"
              value={email}
              placeholder={isMobile ? 'Enter email' : 'Enter client email'}
              onChange={(e) => setEmail(e.target.value)}
              isDisabled={!emailChecked}
            />
            {!!emailError && (
              <Text
                style={{
                  fontSize: '12px',
                  paddingLeft: '22px',
                  paddingTop: '10px',
                  color: '#E53E3E',
                }}
              >
                {emailError}
              </Text>
            )}
            <Button
              mt={4}
              size="xs"
              width="4"
              onClick={handleSendLink}
              isDisabled={disableSend}
              isLoading={isSubmitting}
            >
              Send
            </Button>
          </Flex>
          <Flex ml={3} direction="column" width="full">
            <Input
              mt={3}
              width="full"
              variant="filledForDarkBg"
              size="sm"
              ml={3}
              id="mobile"
              type="text"
              value={mobile}
              placeholder={isMobile ? 'Enter mobile' : 'Enter client mobile'}
              onChange={(e) => setMobile(e.target.value)}
              isDisabled={!mobileChecked}
            />
            <Flex direction="row" mt={3}>
              <Checkbox
                ml={5}
                id="bySMS"
                onChange={() => setSMSChecked(!smsChecked)}
                size={'sm'}
                checked={smsChecked}
                disabled={!mobileChecked}
              >
                <Text fontSize="12px" color="secondaryDark">
                  SMS
                </Text>
              </Checkbox>
              <Checkbox
                ml={3}
                id="byWhatsapp"
                onChange={() => setWhatsappChecked(!whatsappChecked)}
                size={'sm'}
                checked={whatsappChecked}
                disabled={!mobileChecked}
              >
                <Text fontSize="12px" color="secondaryDark">
                  WhatsApp
                </Text>
              </Checkbox>
            </Flex>
            {!!mobileError && (
              <Text
                style={{
                  fontSize: '12px',
                  paddingLeft: '20px',
                  paddingTop: '10px',
                  color: '#E53E3E',
                }}
              >
                {mobileError}
              </Text>
            )}
          </Flex>
        </Flex>
      </Collapse>
    </>
  );
};

export default PassLinkSender;
