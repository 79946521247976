import { NavLink } from 'react-router-dom';
import { HStack, Flex, Square, Box, Divider, VStack } from '@chakra-ui/react';
import { CustomArrowRightIcon } from '../theme/icons/CustomArrowRightIcon';

const CustomNavlink = (props) => {
  return props.adminBottomLogout ? (
    <VStack width="82%" position="absolute" bottom={5}>
      <Divider borderColor="primaryBackground" />
      <NavLink
        to={props.to}
        className={({ isActive }) => (isActive ? 'active' : 'inactive')}
        onClick={props.onClick}
      >
        <Flex alignItems="center" justifyContent="space-between">
          <HStack spacing={3}>
            <Square size="32px" className="sideNavSquare">
              <props.component
                boxSize={props.boxSize ? props.boxSize : '16px'}
                className="sideNavIcon"
              />
            </Square>
            <Box>{props.title}</Box>
          </HStack>
          {props.rightIcon && <CustomArrowRightIcon color="brand" boxSize={2} mr={2} />}
        </Flex>
      </NavLink>
    </VStack>
  ) : (
    <NavLink
      to={props.to}
      className={({ isActive }) => (isActive ? 'active' : 'inactive')}
      onClick={props.onClick}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <HStack spacing={3}>
          <Square size="32px" className="sideNavSquare">
            <props.component
              boxSize={props.boxSize ? props.boxSize : '16px'}
              className="sideNavIcon"
            />
          </Square>
          <Box>{props.title}</Box>
        </HStack>
        {props.rightIcon && <CustomArrowRightIcon color="brand" boxSize={2} mr={2} />}
      </Flex>
    </NavLink>
  );
};

export default CustomNavlink;
