import {
  Box,
  Text,
  HStack,
  Image,
  useMediaQuery,
  useColorModeValue,
  Flex,
} from '@chakra-ui/react';
import { CustomAppleIcon } from '../../../theme/icons/CustomAppleIcon';
import { APPLE_SCREEN_BASE64 } from '../../../utils/base64';

function PreviewPassNotificationsApple({ previewData }) {
  const [isLargeForParallelDisplay] = useMediaQuery('(min-width: 1530px)');
  const modeIconColor = useColorModeValue('secondaryDark', '#DDDDDD');
  // const borderDarkMode = useColorModeValue('1px solid #ccc', '');

  return (
    <Box
      m="0 auto"
      w="330px"
      maxW="full"
      borderRadius="15px"
      alignItems="start"
    >
      {isLargeForParallelDisplay && !previewData.inList && (
        <HStack alignItems="baseline" color={modeIconColor} mb="10px">
          <CustomAppleIcon fontSize="18px" />
          <Text textStyle="bodyMedium">iOS</Text>
        </HStack>
      )}
      <Flex direction="column" position="relative" align="center">
        <Box>
          <Image
            width="auto"
            height="auto"
            maxH={previewData.smallerDisplay && '600px'}
            src={APPLE_SCREEN_BASE64}
            alt=""
          />
        </Box>
        <Box
          className="apple-notification"
          w="70vw"
          maxW={previewData.smallerDisplay ? '260px' : '310px'}
          borderRadius="15px"
          minH={previewData.smallerDisplay ? '50px' : '60px'}
          bg="rgba(255, 255, 255, 0.8)"
          position="absolute"
          top="30%"
        >
          <Flex py={3} px={2} gap={2} align="center">
            <Flex direction="column">
              <Box
                w={previewData.smallerDisplay ? '25px' : '30px'}
                h={previewData.smallerDisplay ? '25px' : '30px'}
                borderRadius="10px"
              >
                <Image
                  borderRadius="100%"
                  boxSize="full"
                  objectFit="cover"
                  src={previewData.iconLogo}
                  alt=""
                />
              </Box>
            </Flex>
            <Flex direction="column" w="full" justifyContent="start">
              <Flex direction="row" justifyContent={'space-between'}>
                <Flex textAlign="start" maxW="200px">
                  <Text
                    fontFamily={'helvetica'}
                    fontSize={previewData.smallerDisplay ? '12px' : '14px'}
                    fontWeight="semibold"
                    lineHeight="initial"
                  >
                    {previewData.notificationHeader}
                  </Text>
                </Flex>
                <Text
                  color="#999A9B"
                  fontFamily={'helvetica'}
                  fontSize={previewData.smallerDisplay ? '11px' : '12px'}
                >
                  2m ago
                </Text>
              </Flex>
              <Flex textAlign="start">
                <Text
                  fontFamily={'helvetica'}
                  fontSize={previewData.smallerDisplay ? '11px' : '13px'}
                  lineHeight="initial"
                  fontWeight="normal"
                  pt={previewData.smallerDisplay ? '3px' : '5px'}
                >
                  {previewData.messageText || 'Your message text here'}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}

export default PreviewPassNotificationsApple;
