import {
  Box,
  Text,
  HStack,
  Circle,
  Flex,
  Image,
  useMediaQuery,
  useColorModeValue,
  Divider,
  Center,
  Button,
  Link,
} from '@chakra-ui/react';
import { CustomAndroidIcon } from '../../../theme/icons/CustomAndroidIcon';
import { useContext, useEffect, useState } from 'react';
import { getContrastYIQ } from '../../../utils/colorBasedOnBgContrast';
import { QR_BASE64 } from '../../../utils/base64';
import parseString from '../../../hooks/useLinkFormatter';
import { CustomLinkIcon } from '../../../theme/icons/CustomLinkIcon';
import { isValidURL } from '../../../utils/isValidUrl';
import previewWatermarkLight from '../../../assets/vectors/previewWatermarkLight.svg';
import previewWatermarkDark from '../../../assets/vectors/previewWatermarkDark.svg';
import { MeContext } from '../../../context/MeContext';

function PreviewPassTemplateAndroid({
  previewData,
  clientView,
  isEdit,
  createView,
  createTemplate,
  isValidImage,
}) {
  const meCtx = useContext(MeContext);
  const { watermarkEnabled } = meCtx.state;
  const [isLargeForParallelDisplay] = useMediaQuery('(min-width: 1530px)');
  const modeIconColor = useColorModeValue('secondaryDark', '#DDDDDD');
  const [secValuesPresent, setSecValuesPresent] = useState(true);
  const [auxValuesPresent, setAuxValuesPresent] = useState(true);
  const [firstRowLabelsCount, setFirstRowLabelsCount] = useState(0);
  const [secondRowLabelsCount, setSecondRowLabelsCount] = useState(0);
  const [secTwoAlignment, setSecTwoAlignment] = useState('');
  const [secThreeAlignment, setSecThreeAlignment] = useState('');
  const [auxTwoAlignment, setAuxTwoAlignment] = useState('');
  const [auxThreeAlignment, setAuxThreeAlignment] = useState('');
  const [watermarkColor, setWatermarkColor] = useState('');
  const [watermarkPosition, setWatermarkPosition] = useState('');

  let determineBackgroundColor = () => {
    return !!previewData.passDesign && !!previewData.passDesign.backgroundColor
      ? previewData.passDesign.backgroundColor
      : previewData.passBackgroundColor;
  };
  // for android preview, label and label-content foreground color can not be manually chosen, so we automatically assign it based on chosen background color
  let getContrastColor = getContrastYIQ(determineBackgroundColor());

  const countFields = (ids, labels, values) => {
    return labels.reduce((count, label, index) => {
      return count + (label || values[index] || ids[index] ? 1 : 0);
    }, 0);
  };

  const handleAuxValuesPresent = () => {
    const {
      passType,
      auxOneId,
      auxOneLabel,
      auxOneValue,
      auxTwoId,
      auxTwoLabel,
      auxTwoValue,
      auxThreeId,
      auxThreeLabel,
      auxThreeValue,
    } = previewData;
    const auxOne = !!auxOneId || !!auxOneLabel || !!auxOneValue;
    const auxTwo = !!auxTwoId || !!auxTwoLabel || !!auxTwoValue;
    const auxThree = !!auxThreeId || !!auxThreeLabel || !!auxThreeValue;
    if (passType === 'storeCard' || passType === 'stampCard') {
      setAuxValuesPresent(auxOne);
    } else {
      setAuxValuesPresent(auxOne || auxTwo || auxThree);
    }
  };

  const handleSecValuesPresent = () => {
    const {
      secOneId,
      secOneLabel,
      secOneValue,
      secTwoId,
      secTwoLabel,
      secTwoValue,
      secThreeId,
      secThreeLabel,
      secThreeValue,
    } = previewData;
    const secOne = !!secOneId || !!secOneLabel || !!secOneValue;
    const secTwo = !!secTwoId || !!secTwoLabel || !!secTwoValue;
    const secThree = !!secThreeId || !!secThreeLabel || !!secThreeValue;
    setSecValuesPresent(secOne || secTwo || secThree);
  };

  const handleFirstRowLabelsCount = () => {
    const {
      secOneId,
      secOneLabel,
      secOneValue,
      secTwoId,
      secTwoLabel,
      secTwoValue,
      secThreeId,
      secThreeLabel,
      secThreeValue,
    } = previewData;
    setFirstRowLabelsCount(
      countFields(
        [secOneId, secTwoId, secThreeId],
        [secOneLabel, secTwoLabel, secThreeLabel],
        [secOneValue, secTwoValue, secThreeValue]
      )
    );
  };

  const handleSecondRowLabelsCount = () => {
    const {
      auxOneId,
      auxOneLabel,
      auxOneValue,
      auxTwoId,
      auxTwoLabel,
      auxTwoValue,
      auxThreeId,
      auxThreeLabel,
      auxThreeValue,
    } = previewData;
    setSecondRowLabelsCount(
      countFields(
        [auxOneId, auxTwoId, auxThreeId],
        [auxOneLabel, auxTwoLabel, auxThreeLabel],
        [auxOneValue, auxTwoValue, auxThreeValue]
      )
    );
  };

  const handleAlignment = (
    count,
    ids,
    labels,
    values,
    setTwoAlignment,
    setThreeAlignment
  ) => {
    const firstFieldEmpty = !ids[0] && !labels[0] && !values[0];
    const secondFieldEmpty = !ids[1] && !labels[1] && !values[1];
    if (count === 1) {
      setTwoAlignment('left');
      setThreeAlignment('left');
    } else if (count === 2) {
      setTwoAlignment(firstFieldEmpty ? 'left' : 'right');
      setThreeAlignment(firstFieldEmpty && secondFieldEmpty ? 'left' : 'right');
    } else if (count === 3) {
      setTwoAlignment(firstFieldEmpty ? 'left' : 'center');
      setThreeAlignment(
        !firstFieldEmpty && !secondFieldEmpty ? 'right' : 'center'
      );
    } else if (count === 4) {
      setTwoAlignment('center');
      setThreeAlignment('center');
    }
  };

  const getAppLinkHref = () => {
    const {
      googleAppLinkPlayStoreUrl,
      googleAppLinkWebAppUrl,
      googleAppLinkPackageName,
    } = previewData;

    const urlsToCheck = [
      googleAppLinkPlayStoreUrl,
      googleAppLinkWebAppUrl,
      googleAppLinkPackageName &&
        `https://play.google.com/store/apps/details?id=${googleAppLinkPackageName}`,
    ];

    for (const url of urlsToCheck) {
      if (url && isValidURL(url)) {
        return url;
      }
    }
    return null;
  };

  const duplicateBackfield = (field) => {
    const backFields = ['One', 'Two', 'Three', 'Four', 'Five'];

    return backFields.some(
      (backField) =>
        previewData[`${field}Id`] === previewData[`back${backField}Id`]
    );
  };

  useEffect(handleAuxValuesPresent, [
    previewData.passType,
    previewData.auxOneId,
    previewData.auxOneLabel,
    previewData.auxOneValue,
    previewData.auxTwoId,
    previewData.auxTwoLabel,
    previewData.auxTwoValue,
    previewData.auxThreeId,
    previewData.auxThreeLabel,
    previewData.auxThreeValue,
  ]);

  useEffect(handleSecValuesPresent, [
    previewData.secOneId,
    previewData.secOneLabel,
    previewData.secOneValue,
    previewData.secTwoId,
    previewData.secTwoLabel,
    previewData.secTwoValue,
    previewData.secThreeId,
    previewData.secThreeLabel,
    previewData.secThreeValue,
  ]);

  useEffect(handleFirstRowLabelsCount, [
    previewData.secOneId,
    previewData.secOneLabel,
    previewData.secOneValue,
    previewData.secTwoId,
    previewData.secTwoLabel,
    previewData.secTwoValue,
    previewData.secThreeId,
    previewData.secThreeLabel,
    previewData.secThreeValue,
  ]);

  useEffect(handleSecondRowLabelsCount, [
    previewData.auxOneId,
    previewData.auxOneLabel,
    previewData.auxOneValue,
    previewData.auxTwoId,
    previewData.auxTwoLabel,
    previewData.auxTwoValue,
    previewData.auxThreeId,
    previewData.auxThreeLabel,
    previewData.auxThreeValue,
  ]);

  useEffect(
    () =>
      handleAlignment(
        firstRowLabelsCount,
        [previewData.secOneId, previewData.secTwoId, previewData.secThreeId],
        [
          previewData.secOneLabel,
          previewData.secTwoLabel,
          previewData.secThreeLabel,
        ],
        [
          previewData.secOneValue,
          previewData.secTwoValue,
          previewData.secThreeValue,
        ],
        setSecTwoAlignment,
        setSecThreeAlignment
      ),
    [firstRowLabelsCount]
  );

  useEffect(
    () =>
      handleAlignment(
        secondRowLabelsCount,
        [previewData.auxOneId, previewData.auxTwoId, previewData.auxThreeId],
        [
          previewData.auxOneLabel,
          previewData.auxTwoLabel,
          previewData.auxThreeLabel,
        ],
        [
          previewData.auxOneValue,
          previewData.auxTwoValue,
          previewData.auxThreeValue,
        ],
        setAuxTwoAlignment,
        setAuxThreeAlignment
      ),
    [secondRowLabelsCount]
  );

  useEffect(() => {
    if (previewData.googleBannerImage) {
      setWatermarkColor(previewData.googleBannerWatermarkColor);
      setWatermarkPosition(previewData.googleBannerWatermarkPosition);
    } else {
      setWatermarkColor(previewData.watermarkColor);
      setWatermarkPosition(previewData.watermarkPosition);
    }
  }, [
    previewData.googleBannerImage,
    previewData.googleBannerWatermarkColor,
    previewData.googleBannerWatermarkPosition,
    previewData.watermarkColor,
    previewData.watermarkPosition,
  ]);

  return (
    <Box m="0 auto" w="343px" maxW="full">
      {isLargeForParallelDisplay && (
        <HStack alignItems="baseline" color={modeIconColor} mb="10px">
          <CustomAndroidIcon fontSize="15px" />
          <Text textStyle="bodyMedium">Android</Text>
        </HStack>
      )}
      <Box
        bg={determineBackgroundColor()}
        borderRadius="10px"
        maxW="full"
        w="343px"
        boxShadow="0px 0px 16px -9px rgba(0,0,0,0.75);"
        m="0 auto"
        zIndex="1"
        position="relative"
      >
        <Flex
          pt="10px"
          pb="5px"
          pl="15px"
          pr="15px"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Box>
            <Circle size="20px" m="15%" border="1px" borderColor="white">
              <Image
                borderRadius="100%"
                boxSize="full"
                objectFit="cover"
                src={previewData.iconLogo}
                alt=""
              />
            </Circle>
          </Box>
          <Text
            fontSize="12px"
            textStyle="headingFamilyRegularGooglePay"
            textAlign="center"
            color={getContrastColor}
            pl="12px"
          >
            {previewData.description
              ? previewData.description
              : 'Pass Description'}
          </Text>
        </Flex>
        <Center>
          <Divider
            mt={1}
            borderColor={getContrastColor}
            opacity="0.2"
            width="100%"
          />
        </Center>
        <Flex
          width="full"
          alignItems="flex-start"
          // justifyContent="flex-end"
          pt="10px"
          pl="22px"
        >
          <Text
            fontSize="16px"
            textStyle="headingFamilyRegularGooglePay"
            // textAlign="center"
            color={getContrastColor}
            wordBreak="break-word"
            pb={3}
            // textTransform="uppercase"
          >
            {previewData.centralTitle &&
            (previewData.passType === 'storeCard' ||
              previewData.passType === 'stampCard' ||
              previewData.passType === 'eventTicket')
              ? previewData.centralTitle
              : ''}
          </Text>
        </Flex>
        {(previewData.passType === 'storeCard' ||
          previewData.passType === 'stampCard' ||
          previewData.passType === 'eventTicket') && (
          <>
            <Flex width="full" alignItems="flex-start" pl="22px">
              <Text
                fontSize={'11px'}
                textStyle="headingFamilyRegularGooglePay"
                // textAlign="center"
                color={getContrastColor}
                wordBreak="break-word"
              >
                {previewData.headerOneLabel ? previewData.headerOneLabel : ''}
              </Text>
            </Flex>
            <Flex width="full" alignItems="flex-start" pl="22px">
              <Text
                fontSize={'13px'}
                textStyle="headingFamilyRegularGooglePay"
                // textAlign="center"
                color={getContrastColor}
                wordBreak="break-word"
              >
                {!!previewData.headerOneId && !previewData.headerOneValue
                  ? '<' + previewData.headerOneId + '>'
                  : previewData.headerOneValue.toString()}
              </Text>
            </Flex>
          </>
        )}
        {previewData.passType === 'generic' && (
          <>
            <Flex width="full" alignItems="flex-start" pl="22px">
              <Text
                fontSize={'14px'}
                textStyle="headingFamilyRegularGooglePay"
                // textAlign="center"
                color={getContrastColor}
                wordBreak="break-word"
              >
                {previewData.primaryOneLabel
                  ? previewData.primaryOneLabel
                  : previewData.primaryOneId
                    ? ''
                    : previewData.description}
              </Text>
            </Flex>
            <Flex width="full" alignItems="flex-start" pl="22px">
              <Text
                fontSize={'22px'}
                textStyle="headingFamilyRegularGooglePay"
                // textAlign="center"
                color={getContrastColor}
                wordBreak="break-word"
              >
                {!!previewData.primaryOneId && !previewData.primaryOneValue
                  ? '<' + previewData.primaryOneId + '>'
                  : previewData.primaryOneValue}
              </Text>
            </Flex>
          </>
        )}
        {secValuesPresent && (
          <Center>
            <Divider
              mt={3}
              borderColor={getContrastColor}
              opacity="0.2"
              width="92%"
            />
          </Center>
        )}
        <Flex justifyContent="space-between" pl="16px" pr="20px" mt={3}>
          {(!!previewData.secOneId ||
            !!previewData.secOneLabel ||
            !!previewData.secOneValue) && (
            <Box flex="1" flexShrink="0" pl={2}>
              {previewData.secOneLabel && (
                <Text
                  fontSize="11px"
                  textStyle="headingFamilyRegularGooglePay"
                  textAlign="left"
                  color={getContrastColor}
                  isTruncated
                >
                  {previewData.secOneLabel}
                </Text>
              )}
              <Text
                fontSize="13px"
                textStyle="headingFamilyRegularGooglePay"
                textAlign="left"
                pr={2}
                color={getContrastColor}
                wordBreak="break-word"
                mt={!previewData.secOneLabel && '15px'}
              >
                {!!previewData.secOneId && !previewData.secOneValue
                  ? '<' + previewData.secOneId + '>'
                  : previewData.secOneValue
                    ? previewData.secOneValue
                    : ''}
              </Text>
            </Box>
          )}
          {(!!previewData.secTwoId ||
            !!previewData.secTwoLabel ||
            !!previewData.secTwoValue) && (
            <Box flex="1" flexShrink="0" pl={2} align={secTwoAlignment}>
              <Text
                fontSize="11px"
                textStyle="headingFamilyRegularGooglePay"
                color={getContrastColor}
                isTruncated
              >
                {previewData.secTwoLabel ? previewData.secTwoLabel : ''}
              </Text>
              <Text
                fontSize="13px"
                textStyle="headingFamilyRegularGooglePay"
                wordBreak="break-word"
                color={getContrastColor}
                mt={!previewData.secTwoLabel && '15px'}
              >
                {!!previewData.secTwoId && !previewData.secTwoValue
                  ? '<' + previewData.secTwoId + '>'
                  : previewData.secTwoValue
                    ? previewData.secTwoValue
                    : ''}
              </Text>
            </Box>
          )}
          {(!!previewData.secThreeId ||
            !!previewData.secThreeLabel ||
            !!previewData.secThreeValue) && (
            <Box flex="1" flexShrink="0" pl={2} align={secThreeAlignment}>
              <Text
                fontSize="11px"
                textStyle="headingFamilyRegularGooglePay"
                color={getContrastColor}
                isTruncated
              >
                {previewData.secThreeLabel ? previewData.secThreeLabel : ''}
              </Text>
              <Text
                fontSize="13px"
                textStyle="headingFamilyRegularGooglePay"
                wordBreak="break-word"
                color={getContrastColor}
                mt={!previewData.secThreeLabel && '15px'}
              >
                {!!previewData.secThreeId && !previewData.secThreeValue
                  ? '<' + previewData.secThreeId + '>'
                  : previewData.secThreeValue
                    ? previewData.secThreeValue
                    : ''}
              </Text>
            </Box>
          )}
        </Flex>
        {auxValuesPresent && (
          <>
            <Center>
              <Divider
                borderColor={getContrastColor}
                opacity="0.2"
                mt={secValuesPresent ? '3' : '0'}
                width="92%"
              />
            </Center>
            <Flex justifyContent="space-between" pl="16px" pr="20px" mt={3}>
              {(!!previewData.auxOneId ||
                !!previewData.auxOneLabel ||
                !!previewData.auxOneValue) && (
                <Box flex="1" flexShrink="0" pl={2}>
                  <Text
                    fontSize="11px"
                    textStyle="headingFamilyRegularGooglePay"
                    textAlign="left"
                    color={getContrastColor}
                    isTruncated
                  >
                    {previewData.auxOneLabel ? previewData.auxOneLabel : ''}
                  </Text>
                  <Text
                    fontSize="13px"
                    textStyle="headingFamilyRegularGooglePay"
                    textAlign="left"
                    wordBreak="break-word"
                    color={getContrastColor}
                    mt={!previewData.auxOneLabel && '15px'}
                  >
                    {!!previewData.auxOneId && !previewData.auxOneValue
                      ? '<' + previewData.auxOneId + '>'
                      : previewData.auxOneValue
                        ? previewData.auxOneValue
                        : ''}
                  </Text>
                </Box>
              )}
              {previewData.passType !== 'storeCard' &&
                previewData.passType !== 'stampCard' && (
                  <>
                    {(!!previewData.auxTwoId ||
                      !!previewData.auxTwoLabel ||
                      !!previewData.auxTwoValue) && (
                      <Box
                        flex="1"
                        flexShrink="0"
                        pl={2}
                        align={auxTwoAlignment}
                      >
                        <Text
                          fontSize="11px"
                          textStyle="headingFamilyRegularGooglePay"
                          color={getContrastColor}
                          isTruncated
                        >
                          {previewData.auxTwoLabel
                            ? previewData.auxTwoLabel
                            : ''}
                        </Text>
                        <Text
                          fontSize="13px"
                          textStyle="headingFamilyRegularGooglePay"
                          wordBreak="break-word"
                          color={getContrastColor}
                          mt={!previewData.auxTwoLabel && '15px'}
                        >
                          {!!previewData.auxTwoId && !previewData.auxTwoValue
                            ? '<' + previewData.auxTwoId + '>'
                            : previewData.auxTwoValue
                              ? previewData.auxTwoValue
                              : ''}
                        </Text>
                      </Box>
                    )}
                    {(!!previewData.auxThreeId ||
                      !!previewData.auxThreeLabel ||
                      !!previewData.auxThreeValue) && (
                      <Box
                        flex="1"
                        flexShrink="0"
                        pl={2}
                        align={auxThreeAlignment}
                      >
                        <Text
                          fontSize="11px"
                          textStyle="headingFamilyRegularGooglePay"
                          color={getContrastColor}
                          isTruncated
                        >
                          {previewData.auxThreeLabel
                            ? previewData.auxThreeLabel
                            : ''}
                        </Text>
                        <Text
                          fontSize="13px"
                          textStyle="headingFamilyRegularGooglePay"
                          wordBreak="break-word"
                          color={getContrastColor}
                          mt={!previewData.auxThreeLabel && '15px'}
                        >
                          {!!previewData.auxThreeId &&
                          !previewData.auxThreeValue
                            ? '<' + previewData.auxThreeId + '>'
                            : previewData.auxThreeValue
                              ? previewData.auxThreeValue
                              : ''}
                        </Text>
                      </Box>
                    )}
                  </>
                )}
            </Flex>
          </>
        )}
        {(createView || clientView || isEdit) &&
          !!previewData.qrValue &&
          !previewData.googleNfcEnabled && (
            <>
              <Flex mt={3} w="full" justifyContent="center">
                <Flex
                  bg="white"
                  mt={2}
                  py={2}
                  px={1}
                  borderRadius="10px"
                  w="fit-content"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Image width="110px" height="100px" src={QR_BASE64} alt="" />
                </Flex>
              </Flex>
              {previewData.qrDisplayText && (
                <>
                  <Flex w="full" justifyContent="center">
                    <Text
                      fontSize="13px"
                      textStyle="headingFamilyRegularApplePay"
                      my={2}
                    >
                      {previewData.qrValue}
                    </Text>
                  </Flex>
                </>
              )}
            </>
          )}
        {previewData.bannerImage ? (
          <Box
            // h="115px"
            h={previewData.passType !== 'eventTicket' ? '100%' : '90px'}
            w="full"
            bg={previewData.passBackgroundColor}
            // bg={
            //   !passContextLoad
            //     ? previewData.passBackgroundColor
            //     : passCtx.passState.ticket.event.template.passBackgroundColor
            // }
            mt="15px"
            borderBottomLeftRadius="10px"
            borderBottomRightRadius="10px"
            position="relative"
            // display="flex"
            // justifyContent="center"
            // alignItems="center"
          >
            <Image
              borderBottomLeftRadius="10px"
              borderBottomRightRadius="10px"
              // w="95%"
              w="full"
              h={previewData.passType !== 'eventTicket' ? '100%' : '90px'}
              // pb="10px"
              objectFit="cover"
              src={
                !!previewData.passDesign &&
                !!previewData.passDesign.bannerImageUrl &&
                previewData.passType !== 'generic'
                  ? previewData.passDesign.bannerImageUrl
                  : previewData.googleBannerImage
                    ? previewData.googleBannerImage
                    : previewData.bannerImage
              }
              alt=""
              aspectRatio={
                previewData.passType === 'eventTicket' ? '3.8' : '2.6'
              }
            />
            {createTemplate &&
              watermarkEnabled &&
              (!previewData.googleBannerImage
                ? previewData.displayBannerWatermarkPreview
                : previewData.displayGoogleBannerWatermarkPreview) && (
                <Box
                  position="absolute"
                  top={
                    (watermarkPosition === 'topLeft' ||
                      watermarkPosition === 'topRight') &&
                    '0'
                  }
                  bottom={
                    (watermarkPosition === 'bottomLeft' ||
                      watermarkPosition === 'bottomRight') &&
                    '0'
                  }
                  left={
                    (watermarkPosition === 'topLeft' ||
                      watermarkPosition === 'bottomLeft') &&
                    '0'
                  }
                  right={
                    (watermarkPosition === 'topRight' ||
                      watermarkPosition === 'bottomRight') &&
                    '0'
                  }
                  mx="14px"
                  my="5px"
                >
                  <Image
                    src={
                      watermarkColor === 'light'
                        ? previewWatermarkLight
                        : previewWatermarkDark
                    }
                    alt="watermark"
                    w="119px"
                    h="17.5px"
                  />
                </Box>
              )}
          </Box>
        ) : (
          <Box h="20px" />
        )}
      </Box>

      {/* DETAILS SECTION  */}
      {(createView || isEdit || clientView || createTemplate) && (
        <>
          <Box
            bg="#fff"
            borderRadius="0px 0px 10px 10px"
            border="1px solid"
            borderColor="primaryBackground"
            borderTop="0px"
            maxW="full"
            // w="344px"
            m="0 auto"
            position="relative"
            top="-6px"
            p="15px"
            className="androidGPayDetailsBorderHelper"
          >
            {(previewData.googleAppLinkPlayStoreUrl ||
              previewData.googleAppLinkPackageName ||
              previewData.googleAppLinkWebAppUrl) && (
              <Flex
                my={3}
                width="full"
                justifyContent="center"
                direction="row"
                align="center"
              >
                <Button
                  w="full"
                  size="sm"
                  variant="none"
                  borderRadius="25px"
                  border="1px solid"
                  borderColor="black"
                  _hover={{ bg: '#EFEFEF' }}
                >
                  <Link
                    href={getAppLinkHref()}
                    isExternal
                    textDecoration="none"
                    _hover={{ textDecoration: 'none' }}
                  >
                    <Flex
                      direction="row"
                      alignItems="center"
                      gap={2}
                      color="#1D60D3"
                    >
                      <CustomLinkIcon boxSize="11px" />
                      <Text
                        fontSize="12px"
                        textStyle="headingFamilyRegularGooglePay"
                      >
                        Open issuer's{' '}
                        {previewData.googleAppLinkPlayStoreUrl ||
                        previewData.googleAppLinkPackageName
                          ? 'app'
                          : 'website'}
                      </Text>
                    </Flex>
                  </Link>
                </Button>
              </Flex>
            )}
            {(createView || clientView || isEdit) &&
              !!previewData.qrValue &&
              previewData.googleNfcEnabled && (
                <>
                  <Flex mt={3} w="full" justifyContent="center">
                    <Box>
                      <Image
                        width="110px"
                        height="100px"
                        src={QR_BASE64}
                        alt=""
                      />
                    </Box>
                  </Flex>
                  {previewData.qrDisplayText && (
                    <>
                      <Flex w="full" justifyContent="center">
                        <Text
                          fontSize="13px"
                          textStyle="headingFamilyRegularApplePay"
                          my={2}
                        >
                          {previewData.qrValue}
                        </Text>
                      </Flex>
                    </>
                  )}
                </>
              )}

            <Box align="left">
              <Circle size="30px" border="1px" borderColor="white">
                <Image
                  borderRadius="100%"
                  boxSize="full"
                  objectFit="cover"
                  src={previewData.iconLogo}
                  alt=""
                />
              </Circle>
            </Box>
            {previewData.centralTitle &&
              (previewData.passType === 'storeCard' ||
                previewData.passType === 'stampCard' ||
                previewData.passType === 'eventTicket') && (
                <Text
                  fontSize="20px"
                  textStyle="headingFamilyRegularGooglePay"
                  textAlign="left"
                  color="androidGPayLabel"
                  wordBreak="break-word"
                  mt={3}
                >
                  {previewData.centralTitle}
                </Text>
              )}
            {previewData.passType !== 'generic' && (
              <>
                {previewData.headerOneId ? (
                  <Box pt="15px">
                    <Text
                      fontSize="12px"
                      textStyle="headingFamilySemiBoldGooglePay"
                      textAlign="left"
                      color="androidGPayLabel"
                      pb="5px"
                    >
                      {previewData.headerOneLabel
                        ? previewData.headerOneLabel
                        : ''}
                    </Text>
                    <Text
                      fontSize="12px"
                      textStyle="headingFamilyRegularGooglePay"
                      textAlign="left"
                      color="androidGPayContent"
                      wordBreak="break-word"
                    >
                      {previewData.headerOneValue
                        ? previewData.headerOneValue.toString()
                        : `<${previewData.headerOneId}>`}
                    </Text>
                  </Box>
                ) : (
                  ''
                )}
              </>
            )}
            {!duplicateBackfield('secOne') &&
            (previewData.secOneId ||
              previewData.secOneLabel ||
              previewData.secOneValue) ? (
              <Box pt="15px">
                <Text
                  fontSize="12px"
                  textStyle="headingFamilySemiBoldGooglePay"
                  textAlign="left"
                  color="androidGPayLabel"
                  pb="5px"
                >
                  {previewData.secOneLabel}
                </Text>
                <Text
                  fontSize="12px"
                  textStyle="headingFamilyRegularGooglePay"
                  textAlign="left"
                  color="androidGPayContent"
                  wordBreak="break-word"
                >
                  {!!previewData.secOneId && !previewData.secOneValue
                    ? '<' + previewData.secOneId + '>'
                    : previewData.secOneValue
                      ? previewData.secOneValue
                      : ''}
                </Text>
              </Box>
            ) : (
              ''
            )}
            {!duplicateBackfield('secTwo') &&
            (previewData.secTwoId ||
              previewData.secTwoLabel ||
              previewData.secTwoValue) ? (
              <Box pt="15px">
                <Text
                  fontSize="12px"
                  textStyle="headingFamilySemiBoldGooglePay"
                  textAlign="left"
                  color="androidGPayLabel"
                  pb="5px"
                >
                  {previewData.secTwoLabel}
                </Text>
                <Text
                  fontSize="12px"
                  textStyle="headingFamilyRegularGooglePay"
                  textAlign="left"
                  color="androidGPayContent"
                  wordBreak="break-word"
                >
                  {!!previewData.secTwoId && !previewData.secTwoValue
                    ? '<' + previewData.secTwoId + '>'
                    : previewData.secTwoValue
                      ? previewData.secTwoValue
                      : ''}
                </Text>
              </Box>
            ) : (
              ''
            )}
            {!duplicateBackfield('secThree') &&
            (previewData.secThreeId ||
              previewData.secThreeLabel ||
              previewData.secThreeValue) ? (
              <Box pt="15px">
                <Text
                  fontSize="12px"
                  textStyle="headingFamilySemiBoldGooglePay"
                  textAlign="left"
                  color="androidGPayLabel"
                  pb="5px"
                >
                  {previewData.secThreeLabel}
                </Text>
                <Text
                  fontSize="12px"
                  textStyle="headingFamilyRegularGooglePay"
                  textAlign="left"
                  color="androidGPayContent"
                  wordBreak="break-word"
                >
                  {!!previewData.secThreeId && !previewData.secThreeValue
                    ? '<' + previewData.secThreeId + '>'
                    : previewData.secThreeValue
                      ? previewData.secThreeValue
                      : ''}
                </Text>
              </Box>
            ) : (
              ''
            )}
            {!duplicateBackfield('auxOne') &&
            (previewData.auxOneId ||
              previewData.auxOneLabel ||
              previewData.auxOneValue) ? (
              <Box pt="15px">
                <Text
                  fontSize="12px"
                  textStyle="headingFamilySemiBoldGooglePay"
                  textAlign="left"
                  color="androidGPayLabel"
                  pb="5px"
                >
                  {previewData.auxOneLabel}
                </Text>
                <Text
                  fontSize="12px"
                  textStyle="headingFamilyRegularGooglePay"
                  textAlign="left"
                  color="androidGPayContent"
                  wordBreak="break-word"
                >
                  {!!previewData.auxOneId && !previewData.auxOneValue
                    ? '<' + previewData.auxOneId + '>'
                    : previewData.auxOneValue
                      ? previewData.auxOneValue
                      : ''}
                </Text>
              </Box>
            ) : (
              ''
            )}
            {previewData.passType !== 'storeCard' &&
              previewData.passType !== 'stampCard' && (
                <>
                  {!duplicateBackfield('auxTwo') &&
                  (previewData.auxTwoId ||
                    previewData.auxTwoLabel ||
                    previewData.auxTwoValue) ? (
                    <Box pt="15px">
                      <Text
                        fontSize="12px"
                        textStyle="headingFamilySemiBoldGooglePay"
                        textAlign="left"
                        color="androidGPayLabel"
                        pb="5px"
                      >
                        {previewData.auxTwoLabel}
                      </Text>
                      <Text
                        fontSize="12px"
                        textStyle="headingFamilyRegularGooglePay"
                        textAlign="left"
                        color="androidGPayContent"
                        wordBreak="break-word"
                      >
                        {!!previewData.auxTwoId && !previewData.auxTwoValue
                          ? '<' + previewData.auxTwoId + '>'
                          : previewData.auxTwoValue
                            ? previewData.auxTwoValue
                            : ''}
                      </Text>
                    </Box>
                  ) : (
                    ''
                  )}
                  {!duplicateBackfield('auxThree') &&
                  (previewData.auxThreeId ||
                    previewData.auxThreeLabel ||
                    previewData.auxThreeValue) ? (
                    <Box pt="15px">
                      <Text
                        fontSize="12px"
                        textStyle="headingFamilySemiBoldGooglePay"
                        textAlign="left"
                        color="androidGPayLabel"
                        pb="5px"
                      >
                        {previewData.auxThreeLabel}
                      </Text>
                      <Text
                        fontSize="12px"
                        textStyle="headingFamilyRegularGooglePay"
                        textAlign="left"
                        color="androidGPayContent"
                        wordBreak="break-word"
                      >
                        {!!previewData.auxThreeId && !previewData.auxThreeValue
                          ? '<' + previewData.auxThreeId + '>'
                          : previewData.auxThreeValue
                            ? previewData.auxThreeValue
                            : ''}
                      </Text>
                    </Box>
                  ) : (
                    ''
                  )}
                </>
              )}
            {previewData.backOneId ||
            previewData.backOneLabel ||
            previewData.backOneValue ? (
              <Box pt="15px">
                <Text
                  fontSize="12px"
                  textStyle="headingFamilySemiBoldGooglePay"
                  textAlign="left"
                  color="androidGPayLabel"
                  pb="5px"
                >
                  {previewData.backOneLabel ? previewData.backOneLabel : ''}
                </Text>
                {!!previewData.backOneId && !previewData.backOneValue ? (
                  <Text
                    fontSize="13px"
                    textStyle="headingFamilyRegularGooglePay"
                    color="androidGPayContent"
                    textAlign="left"
                    wordBreak="break-word"
                  >
                    {'<' + previewData.backOneId + '>'}
                  </Text>
                ) : (
                  <>
                    {previewData.backOneValue.split('\\n').map((i, key) => {
                      return (
                        <Text
                          fontSize="13px"
                          textStyle="headingFamilyRegularGooglePay"
                          color="androidGPayContent"
                          textAlign="left"
                          wordBreak="break-word"
                          key={key}
                        >
                          {parseString(i)}
                        </Text>
                      );
                    })}
                  </>
                )}
              </Box>
            ) : (
              ''
            )}
            {previewData.backTwoId ||
            previewData.backTwoLabel ||
            previewData.backTwoValue ? (
              <Box pt="15px">
                <Text
                  fontSize="12px"
                  textStyle="headingFamilySemiBoldGooglePay"
                  textAlign="left"
                  color="androidGPayLabel"
                  pb="5px"
                >
                  {previewData.backTwoLabel ? previewData.backTwoLabel : ''}
                </Text>
                {!!previewData.backTwoId && !previewData.backTwoValue ? (
                  <Text
                    fontSize="13px"
                    textStyle="headingFamilyRegularGooglePay"
                    color="androidGPayContent"
                    textAlign="left"
                    wordBreak="break-word"
                  >
                    {'<' + previewData.backTwoId + '>'}
                  </Text>
                ) : (
                  <>
                    {previewData.backTwoValue.split('\\n').map((i, key) => {
                      return (
                        <Text
                          fontSize="13px"
                          textStyle="headingFamilyRegularGooglePay"
                          color="androidGPayContent"
                          textAlign="left"
                          wordBreak="break-word"
                          key={key}
                        >
                          {parseString(i)}
                        </Text>
                      );
                    })}
                  </>
                )}
              </Box>
            ) : (
              ''
            )}
            {previewData.backThreeId ||
            previewData.backThreeLabel ||
            previewData.backThreeValue ? (
              <Box pt="15px">
                <Text
                  fontSize="12px"
                  textStyle="headingFamilySemiBoldGooglePay"
                  textAlign="left"
                  color="androidGPayLabel"
                  pb="5px"
                >
                  {previewData.backThreeLabel ? previewData.backThreeLabel : ''}
                </Text>
                {!!previewData.backThreeId && !previewData.backThreeValue ? (
                  <Text
                    fontSize="13px"
                    textStyle="headingFamilyRegularGooglePay"
                    color="androidGPayContent"
                    textAlign="left"
                    wordBreak="break-word"
                  >
                    {'<' + previewData.backThreeId + '>'}
                  </Text>
                ) : (
                  <>
                    {previewData.backThreeValue.split('\\n').map((i, key) => {
                      return (
                        <Text
                          fontSize="13px"
                          textStyle="headingFamilyRegularGooglePay"
                          color="androidGPayContent"
                          textAlign="left"
                          wordBreak="break-word"
                          key={key}
                        >
                          {parseString(i)}
                        </Text>
                      );
                    })}
                  </>
                )}
              </Box>
            ) : (
              ''
            )}
            {previewData.backFourId ||
            previewData.backFourLabel ||
            previewData.backFourValue ? (
              <Box pt="15px">
                <Text
                  fontSize="12px"
                  textStyle="headingFamilySemiBoldGooglePay"
                  textAlign="left"
                  color="androidGPayLabel"
                  pb="5px"
                >
                  {previewData.backFourLabel ? previewData.backFourLabel : ''}
                </Text>
                {!!previewData.backFourId && !previewData.backFourValue ? (
                  <Text
                    fontSize="13px"
                    textStyle="headingFamilyRegularGooglePay"
                    color="androidGPayContent"
                    textAlign="left"
                    wordBreak="break-word"
                  >
                    {'<' + previewData.backFourId + '>'}
                  </Text>
                ) : (
                  <>
                    {previewData.backFourValue.split('\\n').map((i, key) => {
                      return (
                        <Text
                          fontSize="13px"
                          textStyle="headingFamilyRegularGooglePay"
                          color="androidGPayContent"
                          textAlign="left"
                          wordBreak="break-word"
                          key={key}
                        >
                          {parseString(i)}
                        </Text>
                      );
                    })}
                  </>
                )}
              </Box>
            ) : (
              ''
            )}
            {previewData.backFiveId ||
            previewData.backFiveLabel ||
            previewData.backFiveValue ? (
              <Box pt="15px">
                <Text
                  fontSize="12px"
                  textStyle="headingFamilySemiBoldGooglePay"
                  textAlign="left"
                  color="androidGPayLabel"
                  pb="5px"
                >
                  {previewData.backFiveLabel ? previewData.backFiveLabel : ''}
                </Text>
                {!!previewData.backFiveId && !previewData.backFiveValue ? (
                  <Text
                    fontSize="13px"
                    textStyle="headingFamilyRegularGooglePay"
                    color="androidGPayContent"
                    textAlign="left"
                    wordBreak="break-word"
                  >
                    {'<' + previewData.backFiveId + '>'}
                  </Text>
                ) : (
                  <>
                    {previewData.backFiveValue.split('\\n').map((i, key) => {
                      return (
                        <Text
                          fontSize="13px"
                          textStyle="headingFamilyRegularGooglePay"
                          color="androidGPayContent"
                          textAlign="left"
                          wordBreak="break-word"
                          key={key}
                        >
                          {parseString(i)}
                        </Text>
                      );
                    })}
                  </>
                )}
              </Box>
            ) : (
              ''
            )}
            <Box pt="15px">
              <Text
                fontSize="12px"
                textStyle="headingFamilySemiBoldGooglePay"
                textAlign="left"
                color="androidGPayLabel"
                pb="5px"
              >
                PassEntry Terms
              </Text>
              <Text
                fontSize="12px"
                textStyle="headingFamilyRegularGooglePay"
                textAlign="left"
                color="androidGPayLabel"
                wordBreak="break-word"
              >
                {previewData.googleTermsText
                  ? `${previewData.googleTermsText}`
                  : 'Your use of the Google Wallet is subject to Google’s terms and conditions of use, which PassEntry are not responsible for.'}
              </Text>
            </Box>
            <Box pt="15px">
              <Text
                fontSize="12px"
                textStyle="headingFamilyRegularGooglePay"
                textAlign="left"
                color="androidGPayContent"
                pb="5px"
              >
                The Pass provider or merchant is responsible for the info on
                this pass and may send you notifications. Contact them with any
                questions.
              </Text>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}

export default PreviewPassTemplateAndroid;
