import React, { useState } from 'react';
import { useContext } from 'react';
import {
  Button,
  Flex,
  Heading,
  Spacer,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import PassesListContext from '../../../store/client/PassesListContext';
import RedirectionContext from '../../../context/RedirectionContext';
import PassesListCreatePassesButton from './PassesListCreatePassesButton';
import { Link as RouterLink } from 'react-router-dom';
import PassesListFilter from './PassesListFilter';
import useOrganizationStatus from '../../../hooks/useOrganisationStatus';

function getFiltersString(filters) {
  const { id, passTemplate, groupTag, passType, deviceType, status } = filters;
  if (
    (id === '' || id === undefined) &&
    // (name === '' || name === undefined) &&
    // (email === '' || email === undefined) &&
    (passTemplate === '' || passTemplate === undefined) &&
    (groupTag === '' || groupTag === undefined) &&
    (passType === '' || passType === undefined) &&
    (deviceType === '' || deviceType === undefined) &&
    (status === '' || status === undefined)
  ) {
    return '';
  }

  let values = [];
  // if (name !== '') {
  //   values.push(name);
  // }
  // if (email !== '') {
  //   values.push(email);
  // }
  if (id !== '') {
    values.push(id);
  }
  if (passTemplate !== '') {
    values.push(passTemplate);
  }
  if (groupTag !== '') {
    values.push(groupTag);
  }
  if (passType !== '') {
    values.push(passType);
  }
  if (deviceType !== '') {
    values.push(deviceType);
  }
  if (status !== '') {
    values.push(status);
  }

  const count = values.length;
  let outString = '';
  values.forEach((value, index) => {
    if (index === count - 1) {
      outString = outString + value;
    } else {
      outString = outString + value + ', ';
    }
  });

  return outString;
}

function PassesListHeader() {
  const [loadingDashHeader, setLoadingDashHeader] = useState(true);
  const [loadingDashBody, setLoadingDashBody] = useState(true);
  const passesListCtx = useContext(PassesListContext);
  const filters = getFiltersString(passesListCtx.filterValues);
  const filterModeColor = useColorModeValue('secondaryDark08', 'white');
  const redirectCtx = useContext(RedirectionContext);
  const { shouldRender } = useOrganizationStatus();

  return (
    <Flex
      flexDirection={{ base: 'column', sm: 'row' }}
      alignItems="center"
      flexWrap="wrap"
      mt="15px"
      pt="9px"
      minH="54px"
    >
      <Heading alignSelf={{ base: 'flex-start', sm: 'center' }}>
        {filters === '' ? 'Passes' : `Filter results: `}
        <Text
          as="span"
          display="inline-block"
          textStyle="bodyFamilyMedium"
          fontSize="16px"
          color={filterModeColor}
          wordBreak="break-word"
        >
          {filters}
        </Text>
      </Heading>
      <Spacer />
      <Flex
        mb="10px"
        gap={{ base: '10px', sm: '0px' }}
        alignItems="flex-end"
        justifyContent="flex-start"
        flexWrap="wrap"
      >
        {shouldRender && (
          <>
            <PassesListCreatePassesButton />
            <Button
              mr={{ base: '0px', sm: '10px' }}
              px={{ base: '20px', sm: '40px' }}
              alt="Create a pass"
              size="sm"
              as={RouterLink}
              to="/passes/create"
              onClick={() => {
                // reset temp pass state
                redirectCtx.updateReaderAddedExternally(false);
                redirectCtx.updatePassTemplateFromPassState(false);
                redirectCtx.updatePassTemplateFromEditPassState(false);
                redirectCtx.updateAddReaderFromPassState(false);
                redirectCtx.updateReadersArray([]);
                redirectCtx.updateAddReaderFromEditPassState(false);
                redirectCtx.updateEventAddedExternally(false);
                redirectCtx.updateAddEventFromPassState(false);
                redirectCtx.updateAddEventFromEditPassState(false);
              }}
            >
              Create a pass
            </Button>
            <PassesListFilter
              loadingDashHeader={loadingDashHeader}
              loadingDashBody={loadingDashBody}
              setLoadingDashBody={setLoadingDashBody}
              setLoadingDashHeader={setLoadingDashHeader}
            />
          </>
        )}
      </Flex>
    </Flex>
  );
}

export default PassesListHeader;
