import { forwardRef, useContext } from 'react';
import {
  Container,
  Flex,
  Heading,
  Spacer,
  Button,
  Divider,
  Box,
  Grid,
  GridItem,
  Text,
  Image,
  Center,
  VStack,
  HStack,
  Square,
} from '@chakra-ui/react';
import moment from 'moment';
import ReadersRenderStatus from '../readers/ReadersRenderStatus';
import EmptyState from '../../../common/EmptyState';
import {
  passesIssuedIcon,
  passesActiveIcon,
  passesScannedIcon,
  passesUniqueIcon,
  passesInvalidIcon,
  lastPassScanIcon,
  sentSmsIcon,
  sentEmailsIcon,
  sentPushIcon,
} from './DashboardPdfTemplateIcons';
import { ClientDashboardStatsContext } from '../../../store/client/ClientDashboardStatsContext';

const DashboardPdfTemplate = forwardRef((props, ref) => {
  const { dashboardState, filterTime, filterEvent, mostUsedReaders } = useContext(
    ClientDashboardStatsContext
  );

  const timeFormat = (timeValue) => {
    const date = moment(timeValue).format('DD MMM YYYY');
    const time = moment(timeValue).format('HH:mm:ss A');

    return { date, time };
  };

  const defaultText = 'No data yet';

  const passScanTime =
    dashboardState.lastPassTap === null
      ? defaultText
      : timeFormat(dashboardState.lastPassTap?.createdAt);

  const passScanStatus =
    dashboardState.lastPassTap === null
      ? defaultText
      : dashboardState.lastPassTap?.success
      ? 'Accepted'
      : 'Rejected';

  const passScanReaderName = dashboardState.lastPassTap?.readerName ?? defaultText;

  const passScanReaderID =
    dashboardState.lastPassTap?.readerUserDefinedIdentifier ?? defaultText;

  return (
    <Container {...props} bg="white" ref={ref} w="297mm" p="15px">
      <Flex alignItems="center" mt="15px" py="9px" minH="54px" gap="10px" w="full">
        <Heading color="primaryDark">Dashboard</Heading>
        <Spacer />
        <Flex gap="10px">
          <Button
            variant="dashTime"
            minW="200px"
            border="2px solid #E2E8F0"
            bg="white"
            color="primaryDark"
          >
            {filterEvent?.name}
          </Button>
          <Button
            variant="dashTime"
            minW="200px"
            border="2px solid #E2E8F0"
            bg="white"
            color="primaryDark"
          >
            {!filterTime.data?.labelFromDate ? (
              <Text fontSize="14px" top="1px" pos="relative">
                {filterTime?.name}
              </Text>
            ) : (
              <Text fontSize="14px" top="1px" pos="relative">
                {`${filterTime?.data.labelFromDate} `}
                <Text
                  as="span"
                  fontWeight="bold"
                >{`${filterTime?.data.fromTime} - `}</Text>
                {`${filterTime?.data.labelToDate} `}
                <Text as="span" fontWeight="bold">
                  {filterTime?.data.toTime}
                </Text>
              </Text>
            )}
          </Button>
        </Flex>
      </Flex>
      <Divider borderColor="#E2E8F0" mb="23px" />
      <Grid
        templateColumns={{
          base: 'repeat(5, 199px)',
        }}
        columnGap="23px"
      >
        <GridItem w="100%" borderRadius="15px" border="2px solid #E2E8F0">
          <Center
            bg="white"
            minHeight="196px"
            borderRadius="15px"
            p="8px"
            boxShadow="0px 3.5px 5.5px rgb(0 0 0 / 2%)"
          >
            <VStack>
              <Image boxSize="64px" src={passesIssuedIcon} alt="passes" />
              <Heading pt="10px" color="primaryDark">
                {dashboardState?.passesIssued}
              </Heading>
              <Text color="secondaryDark08">Issued passes</Text>
            </VStack>
          </Center>
        </GridItem>

        <GridItem w="100%" borderRadius="15px" border="2px solid #E2E8F0">
          <Center
            bg="white"
            minHeight="196px"
            borderRadius="15px"
            p="8px"
            boxShadow="0px 3.5px 5.5px rgb(0 0 0 / 2%)"
          >
            <VStack>
              <Image boxSize="64px" src={passesActiveIcon} alt="passes" />
              <Heading pt="10px" color="primaryDark">
                {dashboardState?.activePasses}
              </Heading>
              <Text color="secondaryDark08">Active passes</Text>
            </VStack>
          </Center>
        </GridItem>

        <GridItem w="100%" borderRadius="15px" border="2px solid #E2E8F0">
          <Center
            bg="white"
            minHeight="196px"
            borderRadius="15px"
            p="8px"
            boxShadow="0px 3.5px 5.5px rgb(0 0 0 / 2%)"
          >
            <VStack>
              <Image boxSize="64px" src={passesScannedIcon} alt="passes" />
              <Heading pt="10px" color="primaryDark">
                {dashboardState?.totalPassTaps}
              </Heading>
              <Text color="secondaryDark08">Total pass scans</Text>
            </VStack>
          </Center>
        </GridItem>

        <GridItem w="100%" borderRadius="15px" border="2px solid #E2E8F0">
          <Center
            bg="white"
            minHeight="196px"
            borderRadius="15px"
            p="8px"
            boxShadow="0px 3.5px 5.5px rgb(0 0 0 / 2%)"
          >
            <VStack>
              <Image boxSize="64px" src={passesUniqueIcon} alt="passes" />
              <Heading pt="10px" color="primaryDark">
                {dashboardState?.uniquePassTaps}
              </Heading>
              <Text color="secondaryDark08">Unique passes scanned</Text>
            </VStack>
          </Center>
        </GridItem>

        <GridItem w="100%" borderRadius="15px" border="2px solid #E2E8F0">
          <Center
            bg="white"
            minHeight="196px"
            borderRadius="15px"
            p="8px"
            boxShadow="0px 3.5px 5.5px rgb(0 0 0 / 2%)"
          >
            <VStack>
              <Image boxSize="64px" src={passesInvalidIcon} alt="passes" />
              <Heading pt="10px" color="primaryDark">
                {dashboardState?.totalInvalidPassTaps}
              </Heading>
              <Text color="secondaryDark08">Invalid passes scanned</Text>
            </VStack>
          </Center>
        </GridItem>
      </Grid>
      <HStack mt="23px" gap="15px" pb="10px">
        {/* MOST USED READER SECTION */}
        <Box
          h="480px"
          w="55%"
          bg="white"
          boxShadow="0px 3.5px 5.5px rgb(0 0 0 / 2%)"
          borderRadius="15px"
          p="28px 24px 36px"
          border="2px solid #E2E8F0"
          align="start"
        >
          <Heading size="lg" mb="20px" color="primaryDark">
            Most used readers
          </Heading>
          <VStack>
            <Box>
              <HStack color="secondaryDark08" whiteSpace="nowrap">
                <Box w="200px">READER NAME</Box>
                <Box w="120px">READER ID</Box>
                <Box w="100px">TOTAL SCANS</Box>
                <Box w="100px" textAlign="center">
                  STATUS
                </Box>
              </HStack>
              <Divider borderColor="#E2E8F0" />
            </Box>
            <Box>
              {mostUsedReaders &&
                mostUsedReaders.map((reader) => {
                  return (
                    <Box key={reader.uuid}>
                      <HStack py="22px">
                        <Box color="secondaryDark08" w="200px">
                          {reader?.name?.length > 20
                            ? `${reader?.name.substring(0, 18)}...`
                            : reader?.name}
                        </Box>
                        <Box color="secondaryDark08" w="120px">
                          {reader?.userDefinedIdentifier.length > 12
                            ? `${reader?.userDefinedIdentifier.substring(0, 9)}...`
                            : reader?.userDefinedIdentifier}
                        </Box>
                        <Box color="secondaryDark08" w="100px">
                          {reader?.totalScans}
                        </Box>
                        <Center w="100px">
                          <ReadersRenderStatus
                            setupConfirmed={true}
                            readerStatus={reader?.enabled}
                            clientReaderList
                          />
                        </Center>
                      </HStack>
                      <Divider borderColor="#E2E8F0" />
                    </Box>
                  );
                })}
            </Box>
          </VStack>
          {filterTime?.name === 'All time' &&
            filterEvent?.name === 'All events' &&
            mostUsedReaders?.length === 0 && (
              <Box my="81px">
                <EmptyState
                  heading="No data yet"
                  text="When we receive data from the readers it will be presented here."
                ></EmptyState>
              </Box>
            )}

          {(filterTime?.name !== 'All time' || filterEvent?.name !== 'All events') &&
            mostUsedReaders.length === 0 && (
              <Box my="81px">
                <EmptyState
                  heading="No results found"
                  text="Try adjusting your filters to find what you're looking for."
                ></EmptyState>
              </Box>
            )}
        </Box>
        <Box w="45%">
          <VStack gap="15px" w="full">
            {/* LAST PASS SCAN SECTION */}
            <Box
              h="228px"
              w="full"
              bg="linear-gradient(81.62deg, #FF8477 2.25%, #EC6759 79.87%)"
              borderRadius="15px"
              boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
              p="28px"
              color="white"
            >
              <VStack align="start" h="full" gap="18px">
                <Heading size="lg">Last pass scan</Heading>
                <HStack justifyContent="space-between" w="full" pt="15px">
                  <Square
                    size="80px"
                    bg="white"
                    borderRadius="15px"
                    boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
                    p="10px"
                    position="relative"
                  >
                    <Image
                      src={lastPassScanIcon}
                      alt="passes"
                      w="60px"
                      position="absolute"
                      top="10px"
                      left="10px"
                    />
                  </Square>

                  {dashboardState.lastPassTap === null && (
                    <Box w="112px">
                      <Heading size="xl" fontSize="20px" w="112px">
                        {passScanTime}
                      </Heading>
                    </Box>
                  )}
                  {dashboardState.lastPassTap !== null && (
                    <Box w="128px">
                      <Heading
                        size="lg"
                        fontSize="20px"
                        w="full"
                        fontWeight="500"
                        whiteSpace="nowrap"
                      >
                        {passScanTime.date}
                      </Heading>
                      <Heading size="lg" fontSize="22px" w="full" whiteSpace="nowrap">
                        {passScanTime.time}
                      </Heading>
                    </Box>
                  )}

                  <Center>
                    <Divider w="1px" bg="white" h="70px" />
                  </Center>

                  <VStack>
                    <Box fontSize="12px" w="170px">
                      <Text>
                        STATUS: <span>{passScanStatus}</span>
                      </Text>
                      <Text>
                        READER NAME: <span>{passScanReaderName}</span>
                      </Text>
                      <Text>
                        READER ID: <span>{passScanReaderID}</span>
                      </Text>
                    </Box>
                  </VStack>
                </HStack>
              </VStack>
            </Box>
            {/* SENT SECTION */}
            <Box
              h="228px"
              w="full"
              background="secondaryDark"
              borderRadius="15px"
              boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
              p="28px"
              color="white"
            >
              <VStack h="full">
                <Heading size="xl" w="full" textAlign="left">
                  Sent
                </Heading>
                <Spacer />
                <HStack justifyContent="space-between" w="full" gap="40px">
                  <VStack w="full" spacing="12px" alignItems="flex-start">
                    <HStack spacing="8px">
                      <Square
                        w="46px"
                        bg="#EFEFEF"
                        borderRadius="6px"
                        boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
                      >
                        <Image src={sentSmsIcon} alt="sentSms" />
                      </Square>
                      <Text textStyle="headingFamilyMedium">SMS</Text>
                    </HStack>
                    <Heading justifySelf="flex-start">{dashboardState.smsSent}</Heading>
                    <Divider borderWidth="3px" borderColor="brand" borderRadius="5px" />
                  </VStack>
                  <VStack w="full" spacing="12px" alignItems="flex-start">
                    <HStack spacing="8px">
                      <Square
                        w="46px"
                        bg="#EFEFEF"
                        borderRadius="6px"
                        boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
                      >
                        <Image src={sentEmailsIcon} alt="sentEmails" />
                      </Square>
                      <Text textStyle="headingFamilyMedium">Emails</Text>
                    </HStack>
                    <Heading justifySelf="flex-start">
                      {dashboardState.emailsSent}
                    </Heading>
                    <Divider borderWidth="3px" borderColor="brand" borderRadius="5px" />
                  </VStack>
                  <VStack w="full" spacing="12px" alignItems="flex-start">
                    <HStack spacing="8px">
                      <Square
                        w="46px"
                        bg="#EFEFEF"
                        borderRadius="6px"
                        boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
                      >
                        <Image src={sentPushIcon} alt="sentPush" />
                      </Square>
                      <Text textStyle="headingFamilyMedium">Push</Text>
                    </HStack>
                    <Heading justifySelf="flex-start">{dashboardState.pushSent}</Heading>
                    <Divider borderWidth="3px" borderColor="brand" borderRadius="5px" />
                  </VStack>
                </HStack>
              </VStack>
            </Box>
          </VStack>
        </Box>
      </HStack>
    </Container>
  );
});

export default DashboardPdfTemplate;
