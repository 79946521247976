import {
  Box,
  Text,
  Flex,
  HStack,
  Circle,
  Image,
  useMediaQuery,
  useColorModeValue,
} from '@chakra-ui/react';
import { CustomAppleIcon } from '../../../theme/icons/CustomAppleIcon';
import { QR_BASE64 } from '../../../utils/base64';
import { useContext, useEffect, useMemo, useState } from 'react';
import { CustomNfcIcon } from '../../../theme/icons/CustomNfcIcon';
import { ReactFitty } from 'react-fitty';
import appleControls from '../../../theme/icons/AppleControlButtons.png';
import parseString from '../../../hooks/useLinkFormatter';
import previewWatermarkLight from '../../../assets/vectors/previewWatermarkLight.svg';
import previewWatermarkDark from '../../../assets/vectors/previewWatermarkDark.svg';
import { MeContext } from '../../../context/MeContext';

const countLabelsAndValues = (data, keys) => {
  return keys.reduce((count, key) => count + (data[key] ? 1 : 0), 0);
};

const setAlignments = (count, data, setAlign1, setAlign2, keys) => {
  const firstFieldEmpty = !data[keys[0]] && !data[keys[1]] && !data[keys[2]];
  const secondFieldEmpty = !data[keys[3]] && !data[keys[4]] && !data[keys[5]];
  if (count === 1) {
    setAlign1('left');
    setAlign2('left');
  } else if (count === 2) {
    setAlign1(firstFieldEmpty ? 'left' : 'right');
    setAlign2(firstFieldEmpty && secondFieldEmpty ? 'left' : 'right');
  } else if (count === 3) {
    setAlign1(firstFieldEmpty ? 'left' : 'center');
    setAlign2(!firstFieldEmpty && !secondFieldEmpty ? 'right' : 'center');
  } else if (count === 4) {
    setAlign1('center');
    setAlign2('center');
  }
};

function PreviewPassTemplateApple({
  previewData,
  clientView,
  isEdit,
  createView,
  cutoutBorderGrey,
  createTemplate,
  templatesPage,
}) {
  const meCtx = useContext(MeContext);
  const { watermarkEnabled } = meCtx.state;
  const [isLargeForParallelDisplay] = useMediaQuery('(min-width: 1530px)');
  const modeCutout = useColorModeValue(
    previewData.cutoutBg ? previewData.cutoutBg : 'white',
    previewData.cutoutBg ? 'primaryDark' : 'secondaryDark'
  );
  const modeIconColor = useColorModeValue('secondaryDark', '#DDDDDD');
  const borderDarkMode = useColorModeValue('1px solid #ccc', '');
  const thumbnailDisplay =
    previewData.passType === 'storeCard' ||
    previewData.passType === 'stampCard' ||
    previewData.passType === 'eventTicket' ||
    previewData.passType === 'boarding_pass'
      ? 'none'
      : '';

  // Element display according to Apple pass type
  const bannerDisplay =
    previewData.passType === 'generic' ||
    previewData.passType === 'boarding_pass'
      ? 'none'
      : '';
  const primaryDisplay = previewData.passType === 'generic' ? '' : 'none';
  const labelDisplay =
    previewData.passType === 'storeCard' ||
    previewData.passType === 'stampCard' ||
    previewData.passType === 'coupon'
      ? ''
      : 'none';
  const rowVisibility =
    previewData.passType === 'storeCard' ||
    previewData.passType === 'stampCard' ||
    previewData.passType === 'coupon'
      ? 'hidden'
      : '';
  // Apple pass styles
  const borderRadius =
    previewData.passType === 'eventTicket' || previewData.passType === 'coupon'
      ? ''
      : '4%';
  const cutoutBorderEvent = cutoutBorderGrey
    ? `cutoutBorderGrey`
    : `cutoutBorderWhite`;

  const [backFields, setBackFields] = useState([]);
  const [filteredBackFields, setFilteredBackFields] = useState([]);

  const firstRowKeys = useMemo(
    () => [
      'secOneId',
      'secOneLabel',
      'secOneValue',
      'secTwoId',
      'secTwoLabel',
      'secTwoValue',
      'secThreeId',
      'secThreeLabel',
      'secThreeValue',
      'auxOneId',
      'auxOneLabel',
      'auxOneValue',
    ],
    []
  );

  const secondRowKeys = useMemo(
    () => [
      'auxOneId',
      'auxOneLabel',
      'auxOneValue',
      'auxTwoId',
      'auxTwoLabel',
      'auxTwoValue',
      'auxThreeId',
      'auxThreeLabel',
      'auxThreeValue',
    ],
    []
  );

  const [firstRowLabelsCount, setFirstRowLabelsCount] = useState(0);
  const [secondRowLabelsCount, setSecondRowLabelsCount] = useState(0);
  const [secTwoAlignment, setSecTwoAlignment] = useState('');
  const [secThreeAlignment, setSecThreeAlignment] = useState('');
  const [auxTwoAlignment, setAuxTwoAlignment] = useState('');
  const [auxThreeAlignment, setAuxThreeAlignment] = useState('');

  useEffect(() => {
    const count = countLabelsAndValues(previewData, firstRowKeys);
    setFirstRowLabelsCount(count);
  }, [previewData, firstRowKeys]);

  useEffect(() => {
    const count = countLabelsAndValues(previewData, secondRowKeys);
    setSecondRowLabelsCount(count);
  }, [previewData, secondRowKeys]);

  useEffect(() => {
    setAlignments(
      firstRowLabelsCount,
      previewData,
      setSecTwoAlignment,
      setSecThreeAlignment,
      [
        'secOneId',
        'secOneLabel',
        'secOneValue',
        'secTwoId',
        'secTwoLabel',
        'secTwoValue',
      ]
    );
  }, [firstRowLabelsCount, previewData]);

  useEffect(() => {
    setAlignments(
      secondRowLabelsCount,
      previewData,
      setAuxTwoAlignment,
      setAuxThreeAlignment,
      [
        'auxOneId',
        'auxOneLabel',
        'auxOneValue',
        'auxTwoId',
        'auxTwoLabel',
        'auxTwoValue',
      ]
    );
  }, [secondRowLabelsCount, previewData]);

  useEffect(() => {
    setBackFields([
      { name: 'backOne', value: previewData.backOneLabel },
      { name: 'backTwo', value: previewData.backTwoLabel },
      { name: 'backThree', value: previewData.backThreeLabel },
      { name: 'backFour', value: previewData.backFourLabel },
      { name: 'backFive', value: previewData.backFiveLabel },
    ]);
  }, [
    previewData.backOneLabel,
    previewData.backTwoLabel,
    previewData.backThreeLabel,
    previewData.backFourLabel,
    previewData.backFiveLabel,
  ]);

  useEffect(() => {
    const filteredFields = backFields.filter(
      (field) =>
        !!previewData[`${field.name}Id`] ||
        !!previewData[`${field.name}Label`] ||
        !!previewData[`${field.name}Value`]
    );
    setFilteredBackFields(filteredFields);
  }, [previewData, backFields]);

  let resizeTimer;

  // Set a timer to trigger the resize event after 300 milliseconds
  resizeTimer = setTimeout(() => {
    window.dispatchEvent(new Event('resize'));
  }, 300);

  // Clear the timer when the user is about to leave the page
  window.addEventListener('beforeunload', () => {
    clearTimeout(resizeTimer);
  });

  return (
    <Box m="0 auto" w="343px" maxW="full">
      {isLargeForParallelDisplay && !previewData.inList && (
        <HStack alignItems="baseline" color={modeIconColor} mb="10px">
          <CustomAppleIcon fontSize="18px" />
          <Text textStyle="bodyMedium">iOS</Text>
        </HStack>
      )}
      <Box
        bg={
          !!previewData.passDesign && !!previewData.passDesign.backgroundColor
            ? previewData.passDesign.backgroundColor
            : previewData.passBackgroundColor
        }
        // maxW="full"
        zIndex="1"
        maxWidth="343px"
        minH="350px"
        boxShadow="0px 6px 13px rgb(0 0 0 / 15%)"
        m="0 auto"
        pb="20px"
        pt="10px"
        position={templatesPage ? 'static' : 'relative'}
        border={borderDarkMode}
        borderRadius={borderRadius}
        _before={
          previewData.passType === 'coupon'
            ? {
                bg: `linear-gradient(140deg, transparent 50%, ${previewData.passBackgroundColor} 50%),
            linear-gradient(220deg, transparent 50%, ${previewData.passBackgroundColor} 50%)`,
                bgPosition: 'left-bottom',
                bgRepeat: 'repeat-x',
                bgSize: '10px 45px',
                content: "' '",
                display: 'block',
                position: 'absolute',
                left: '0',
                w: '100%',
                h: '42px',
                bottom: '329px',
              }
            : ''
        }
        _after={
          previewData.passType === 'coupon'
            ? {
                bg: `linear-gradient(-140deg, ${previewData.passBackgroundColor} 50%, transparent 50%),
            linear-gradient(-220deg, ${previewData.passBackgroundColor} 50%, transparent 50%)`,
                bgPosition: 'left-bottom',
                bgRepeat: 'repeat-x',
                bgSize: '10px 45px',
                content: "' '",
                display: 'block',
                position: 'absolute',
                left: '0',
                w: '100%',
                h: '33px',
                bottom: '-11px',
              }
            : ''
        }
      >
        <Box
          display={previewData.passType === 'eventTicket' ? 'block' : 'none'}
          w="80px"
          h="30px"
          pos="absolute"
          left="50%"
          transform="translateX(-50%)"
          top="-8px"
          pointerEvents="none"
          overflow="hidden"
          className={cutoutBorderEvent}
        >
          <Circle
            display={previewData.passType === 'eventTicket' ? 'block' : 'none'}
            bg={modeCutout}
            size="80px"
            pos="absolute"
            bottom="0"
            pointerEvents="none"
            border={borderDarkMode}
          />
        </Box>
        <Flex
          justifyContent="space-between"
          px="12px"
          align="center"
          position="relative"
        >
          <Box>
            <Flex w="156px" h="40px" alignItems="center" borderRadius="5px">
              <Image
                maxH="full"
                width="calc(100% - 35px)"
                mt="5px"
                src={
                  !!previewData.passDesign &&
                  !!previewData.passDesign.logoImageUrl
                    ? previewData.passDesign.logoImageUrl
                    : previewData.logoImage
                }
                alt=""
              />
            </Flex>
          </Box>
          <Box
            className="box"
            overflow="hidden"
            minWidth="100px"
            align="right"
            pl={2}
          >
            <Box minWidth="100px" align="right">
              <Text
                fontSize="10px"
                textStyle="headingFamilyMediumApplePay"
                color={previewData.titleFontColor}
                textTransform="uppercase"
                isTruncated
              >
                {previewData.headerOneLabel ? previewData.headerOneLabel : ''}
              </Text>
              <ReactFitty minSize={8} maxSize={20} fontSize="20px">
                <Text
                  textStyle="headingFamilyRegularApplePay"
                  color={previewData.bodyFontColor}
                  whiteSpace="nowrap"
                  isTruncated
                  mt={previewData.headerOneLabel ? '3px' : '17px'}
                >
                  {!!previewData.headerOneId && !previewData.headerOneValue
                    ? '<' + previewData.headerOneId + '>'
                    : previewData.headerOneValue
                      ? previewData.headerOneValue
                      : ''}
                </Text>
              </ReactFitty>
            </Box>
          </Box>
        </Flex>
        <Box
          h={previewData.passType !== 'eventTicket' ? '100%' : '90px'}
          width="100%"
          mt="10px"
          mb={1}
          display={bannerDisplay}
          position="relative"
        >
          <Image
            width="100%"
            height="100%"
            objectFit="cover"
            src={
              !!previewData.passDesign &&
              !!previewData.passDesign.bannerImageUrl
                ? previewData.passDesign.bannerImageUrl
                : previewData.bannerImage
            }
            alt=""
            aspectRatio={previewData.passType === 'eventTicket' ? '3.8' : '2.6'}
          />
          {createTemplate &&
            watermarkEnabled &&
            previewData.displayBannerWatermarkPreview && (
              <Box
                position="absolute"
                top={
                  (previewData.watermarkPosition === 'topLeft' ||
                    previewData.watermarkPosition === 'topRight') &&
                  '0'
                }
                bottom={
                  (previewData.watermarkPosition === 'bottomLeft' ||
                    previewData.watermarkPosition === 'bottomRight') &&
                  '0'
                }
                left={
                  (previewData.watermarkPosition === 'topLeft' ||
                    previewData.watermarkPosition === 'bottomLeft') &&
                  '0'
                }
                right={
                  (previewData.watermarkPosition === 'topRight' ||
                    previewData.watermarkPosition === 'bottomRight') &&
                  '0'
                }
                mx="14px"
                my="5px"
              >
                <Image
                  src={
                    previewData.watermarkColor === 'light'
                      ? previewWatermarkLight
                      : previewWatermarkDark
                  }
                  alt="watermark"
                  w="119px"
                  h="17.5px"
                />
              </Box>
            )}
        </Box>
        <Flex
          justifyContent="space-between"
          pl="12px"
          pr="12px"
          mt="10px"
          mb={1}
        >
          <Box h="90px" mt="10px" mb={1} display={primaryDisplay} pl={2}>
            <Text
              fontSize="10px"
              textStyle="headingFamilyMediumApplePay"
              textTransform="uppercase"
              textAlign="left"
              wordBreak="break-word"
              color={previewData.titleFontColor}
            >
              {previewData.primaryOneLabel ? previewData.primaryOneLabel : ''}
            </Text>
            <Text
              fontSize="20px"
              textStyle="headingFamilyRegularApplePay"
              textAlign="left"
              wordBreak="break-word"
              color={previewData.bodyFontColor}
            >
              {!!previewData.primaryOneId && !previewData.primaryOneValue
                ? '<' + previewData.primaryOneId + '>'
                : previewData.primaryOneValue
                  ? previewData.primaryOneValue
                  : ''}
            </Text>
          </Box>
          {!!previewData.thumbnailImage && (
            <Box
              h="90px"
              width="90px"
              mt="10px"
              mb={1}
              display={thumbnailDisplay}
            >
              <Image
                width="100%"
                height="100%"
                objectFit="cover"
                src={
                  !!previewData.passDesign &&
                  !!previewData.passDesign.thumbnailImageUrl
                    ? previewData.passDesign.thumbnailImageUrl
                    : previewData.thumbnailImage
                }
                alt=""
              />
            </Box>
          )}
        </Flex>

        <Flex
          justifyContent="space-between"
          px="12px"
          align="center"
          position="relative"
        >
          {(previewData.secOneId ||
            previewData.secOneLabel ||
            previewData.secOneValue) && (
            <Box
              className="box"
              overflow="hidden"
              minWidth="50px"
              align="left"
              pl={2}
            >
              <Box minWidth="50px" align="left">
                <Text
                  fontSize="10px"
                  textStyle="headingFamilyMediumApplePay"
                  color={previewData.titleFontColor}
                  textTransform="uppercase"
                  isTruncated
                >
                  {previewData.secOneLabel ? previewData.secOneLabel : ''}
                </Text>
                <ReactFitty minSize={8} maxSize={16}>
                  <Text
                    id="secOneValue"
                    textStyle="headingFamilyRegularApplePay"
                    color={previewData.bodyFontColor}
                    whiteSpace="nowrap"
                    isTruncated
                    mt={previewData.secOneLabel ? '3px' : '17px'}
                  >
                    {!!previewData.secOneId && !previewData.secOneValue
                      ? '<' + previewData.secOneId + '>'
                      : previewData.secOneValue
                        ? previewData.secOneValue
                        : ''}
                  </Text>
                </ReactFitty>
              </Box>
            </Box>
          )}
          {(previewData.secTwoId ||
            previewData.secTwoLabel ||
            previewData.secTwoValue) && (
            <Box
              className="box"
              pl={2}
              overflow="hidden"
              minWidth="50px"
              align={secTwoAlignment}
            >
              <Box minWidth="50px" align="left">
                <Text
                  fontSize="10px"
                  textStyle="headingFamilyMediumApplePay"
                  color={previewData.titleFontColor}
                  textTransform="uppercase"
                  isTruncated
                >
                  {previewData.secTwoLabel ? previewData.secTwoLabel : ''}
                </Text>
                <ReactFitty minSize={8} maxSize={16}>
                  <Text
                    id="secTwoValue"
                    textStyle="headingFamilyRegularApplePay"
                    color={previewData.bodyFontColor}
                    whiteSpace="nowrap"
                    isTruncated
                    mt={previewData.secTwoLabel ? '3px' : '17px'}
                  >
                    {!!previewData.secTwoId && !previewData.secTwoValue
                      ? '<' + previewData.secTwoId + '>'
                      : previewData.secTwoValue
                        ? previewData.secTwoValue
                        : ''}
                  </Text>
                </ReactFitty>
              </Box>
            </Box>
          )}
          {(previewData.secThreeId ||
            previewData.secThreeLabel ||
            previewData.secThreeValue) && (
            <Box
              className="box"
              pl={2}
              overflow="hidden"
              minWidth="50px"
              align={secThreeAlignment}
            >
              <Box minWidth="50px" align="left">
                <Text
                  fontSize="10px"
                  textStyle="headingFamilyMediumApplePay"
                  color={previewData.titleFontColor}
                  textTransform="uppercase"
                  isTruncated
                >
                  {previewData.secThreeLabel ? previewData.secThreeLabel : ''}
                </Text>
                <ReactFitty minSize={8} maxSize={16}>
                  <Text
                    id="secThreeValue"
                    textStyle="headingFamilyRegularApplePay"
                    color={previewData.bodyFontColor}
                    whiteSpace="nowrap"
                    isTruncated
                    mt={previewData.secThreeLabel ? '3px' : '17px'}
                  >
                    {!!previewData.secThreeId && !previewData.secThreeValue
                      ? '<' + previewData.secThreeId + '>'
                      : previewData.secThreeValue
                        ? previewData.secThreeValue
                        : ''}
                  </Text>
                </ReactFitty>
              </Box>
            </Box>
          )}
          {(previewData.auxOneId ||
            previewData.auxOneLabel ||
            previewData.auxOneValue) && (
            <Box
              className="box"
              display={labelDisplay}
              pl={2}
              overflow="hidden"
              minWidth="50px"
              align="right"
            >
              <Box minWidth="50px" align="left">
                <Text
                  fontSize="10px"
                  textStyle="headingFamilyMediumApplePay"
                  color={previewData.titleFontColor}
                  textTransform="uppercase"
                  isTruncated
                >
                  {previewData.auxOneLabel ? previewData.auxOneLabel : ''}
                </Text>
                <ReactFitty minSize={8} maxSize={16}>
                  <Text
                    id="auxOneValue"
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    wordBreak="break-word"
                    textStyle="headingFamilyRegularApplePay"
                    color={previewData.bodyFontColor}
                    isTruncated
                    mt={previewData.auxOneLabel ? '3px' : '17px'}
                  >
                    {!!previewData.auxOneId && !previewData.auxOneValue
                      ? '<' + previewData.auxOneId + '>'
                      : previewData.auxOneValue
                        ? previewData.auxOneValue
                        : ''}
                  </Text>
                </ReactFitty>
              </Box>
            </Box>
          )}
        </Flex>
        <Flex
          justifyContent="space-between"
          px="12px"
          pt="6px"
          align="center"
          visibility={rowVisibility}
        >
          {(previewData.auxOneId ||
            previewData.auxOneLabel ||
            previewData.auxOneValue) && (
            <Box overflow="hidden" minWidth="50px" align="left" pl={2}>
              <Box minWidth="50px" align="left">
                <Text
                  fontSize="10px"
                  textStyle="headingFamilyMediumApplePay"
                  color={previewData.titleFontColor}
                  textTransform="uppercase"
                  isTruncated
                >
                  {previewData.auxOneLabel ? previewData.auxOneLabel : ''}
                </Text>
                <ReactFitty minSize={8} maxSize={16}>
                  <Text
                    wordBreak="break-word"
                    textStyle="headingFamilyRegularApplePay"
                    color={previewData.bodyFontColor}
                    mt={!previewData.auxOneLabel && '15px'}
                  >
                    {!!previewData.auxOneId && !previewData.auxOneValue
                      ? '<' + previewData.auxOneId + '>'
                      : previewData.auxOneValue
                        ? previewData.auxOneValue
                        : ''}
                  </Text>
                </ReactFitty>
              </Box>
            </Box>
          )}
          {previewData.passType !== 'storeCard' &&
            previewData.passType !== 'stampCard' && (
              <>
                {(previewData.auxTwoId ||
                  previewData.auxTwoLabel ||
                  previewData.auxTwoValue) && (
                  <Box
                    overflow="hidden"
                    minWidth="50px"
                    align={auxTwoAlignment}
                    pl={2}
                  >
                    <Box minWidth="50px" align="left">
                      <Text
                        fontSize="10px"
                        textStyle="headingFamilyMediumApplePay"
                        textAlign="left"
                        color={previewData.titleFontColor}
                        textTransform="uppercase"
                        isTruncated
                      >
                        {previewData.auxTwoLabel ? previewData.auxTwoLabel : ''}
                      </Text>
                      <ReactFitty minSize={8} maxSize={16}>
                        <Text
                          textStyle="headingFamilyRegularApplePay"
                          textAlign="left"
                          wordBreak="break-word"
                          color={previewData.bodyFontColor}
                          mt={!previewData.auxTwoLabel && '15px'}
                        >
                          {!!previewData.auxTwoId && !previewData.auxTwoValue
                            ? '<' + previewData.auxTwoId + '>'
                            : previewData.auxTwoValue
                              ? previewData.auxTwoValue
                              : ''}
                        </Text>
                      </ReactFitty>
                    </Box>
                  </Box>
                )}
                {(previewData.auxThreeId ||
                  previewData.auxThreeLabel ||
                  previewData.auxThreeValue) && (
                  <Box
                    overflow="hidden"
                    minWidth="50px"
                    align={auxThreeAlignment}
                    pl={2}
                  >
                    <Box minWidth="50px" align="left">
                      <Text
                        fontSize="10px"
                        textStyle="headingFamilyMediumApplePay"
                        textAlign="left"
                        color={previewData.titleFontColor}
                        textTransform="uppercase"
                        isTruncated
                      >
                        {previewData.auxThreeLabel
                          ? previewData.auxThreeLabel
                          : ''}
                      </Text>
                      <ReactFitty minSize={8} maxSize={16}>
                        <Text
                          textStyle="headingFamilyRegularApplePay"
                          textAlign="left"
                          wordBreak="break-word"
                          color={previewData.bodyFontColor}
                          mt={!previewData.auxThreeLabel && '15px'}
                        >
                          {!!previewData.auxThreeId &&
                          !previewData.auxThreeValue
                            ? '<' + previewData.auxThreeId + '>'
                            : previewData.auxThreeValue
                              ? previewData.auxThreeValue
                              : ''}
                        </Text>
                      </ReactFitty>
                    </Box>
                  </Box>
                )}
              </>
            )}
        </Flex>
        {!!previewData.appleAppLink && (
          <Box>
            <Image
              h="24px"
              w="24px"
              pos="absolute"
              bottom="6px"
              left="6px"
              src={previewData.iconLogo}
              borderRadius="5px"
              alt=""
            />
          </Box>
        )}
        {!!previewData.nfc && (
          <CustomNfcIcon
            fontSize="20px"
            pos="absolute"
            bottom="10px"
            right="6px"
            color={previewData.bodyFontColor}
          />
        )}
        {(createView || clientView || isEdit) &&
          !!previewData.qrValue &&
          !previewData.nfc && (
            <>
              <Flex w="full" justifyContent="center">
                <Flex
                  direction="column"
                  bg="white"
                  mt={4}
                  w="fit-content"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box mt={previewData.qrDisplayText && 2}>
                    <Image
                      width="100px"
                      height="100px"
                      src={QR_BASE64}
                      alt=""
                    />
                  </Box>
                  {previewData.qrDisplayText && (
                    <Text
                      fontSize="11px"
                      textStyle="headingFamilyRegularApplePay"
                      mx={2}
                      mb={2}
                    >
                      {previewData.qrValue}
                    </Text>
                  )}
                </Flex>
              </Flex>
            </>
          )}
      </Box>
      {/* DETAILS SECTION  */}
      {(createView || isEdit || clientView || createTemplate) && (
        <Box
          bg="#ffff"
          borderRadius="0px 0px 10px 10px"
          border="1px solid"
          borderColor="primaryBackground"
          borderTop="0px"
          maxW="full"
          w="343px"
          m="0 auto"
          position="relative"
          zIndex={templatesPage && '-1'}
          p="15px"
        >
          {(createView || clientView || isEdit) &&
            !!previewData.qrValue &&
            previewData.nfc && (
              <>
                <Flex w="full" justifyContent="center">
                  <Box>
                    <Image
                      width="120px"
                      height="110px"
                      src={QR_BASE64}
                      alt=""
                    />
                  </Box>
                </Flex>
                {previewData.qrDisplayText && (
                  <>
                    <Flex w="full" justifyContent="center">
                      <Text
                        fontSize="12px"
                        textStyle="headingFamilyRegularApplePay"
                        mb={3}
                      >
                        {previewData.qrValue}
                      </Text>
                    </Flex>
                  </>
                )}
              </>
            )}
          <Box pt="15px">
            <Text
              fontSize="15px"
              textStyle="headingFamilyRegular"
              textAlign="center"
              wordBreak="break-word"
            >
              {previewData.description
                ? previewData.description
                : 'Pass Description'}
            </Text>
          </Box>
          <Box align="center">
            <Image py="15px" src={appleControls} alt="ios_buttons" />
          </Box>
          {filteredBackFields.map((field, index) => (
            <Box
              bg="white"
              borderRadius={
                filteredBackFields.length === 1
                  ? '10px'
                  : index === 0
                    ? '10px 10px 0px 0px'
                    : index === filteredBackFields.length - 1
                      ? '0px 0px 10px 10px'
                      : '0'
              }
              py="8px"
              px="10px"
              align="center"
              border="1px solid #E6E6E6"
              borderTopWidth={index !== 0 ? '0' : '1px'}
            >
              <HStack justify="space-between" align="center">
                <Text
                  fontSize="12px"
                  textStyle="headingFamilyRegularApplePay"
                  textAlign="left"
                  color="black"
                >
                  {field.value}
                </Text>
                {(!!previewData[`${field.name}Id`] ||
                  !!previewData[`${field.name}Value`]) && (
                  <Box align="right" overflow="hidden">
                    {!!previewData[`${field.name}Id`] &&
                    !previewData[`${field.name}Value`] ? (
                      <Text
                        fontSize="13px"
                        textStyle="headingFamilyRegularApplePay"
                        textAlign="right"
                        color="applePayLabel"
                      >
                        {`<${previewData[`${field.name}Id`]}>`}
                      </Text>
                    ) : (
                      previewData[`${field.name}Value`]
                        .split('\\n')
                        .map((i, key) => {
                          return (
                            <Text
                              fontSize="13px"
                              textStyle="headingFamilyRegularApplePay"
                              textAlign="right"
                              color="applePayLabel"
                              key={key}
                            >
                              {parseString(i)}
                            </Text>
                          );
                        })
                    )}
                  </Box>
                )}
              </HStack>
            </Box>
          ))}
          <Box pt="30px">
            <Text
              fontSize="12px"
              textStyle="headingFamilyRegularApplePay"
              textAlign="left"
              color="applePayLabel"
              pb="5px"
            >
              PassEntry Terms
            </Text>
            <Text
              fontSize="13px"
              textStyle="headingFamilyRegularApplePay"
              textAlign="left"
              color="applePayLabel"
              wordBreak="break-word"
            >
              Your use of the Apple Wallet is subject to Apple’s terms and
              conditions of use, which PassEntry are not responsible for.
            </Text>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default PreviewPassTemplateApple;
