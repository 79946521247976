import {
  Box,
  useColorModeValue,
  Text,
  Divider,
  FormControl,
  Flex,
  Hide,
  Show,
  FormLabel,
  HStack,
  Spacer,
} from '@chakra-ui/react';
import EventsContext from '../../../store/client/EventsContext';
import { useContext } from 'react';
import { CustomClockIcon } from '../../../theme/icons/CustomClockIcon';
import { CustomCalendarIcon } from '../../../theme/icons/CustomCalendarIcon';
import EventsRenderStatus from './EventsRenderStatus';
import moment from 'moment';

const EventPrimaryFields = () => {
  const eventsCtx = useContext(EventsContext);
  const modeBoxBg = useColorModeValue('gradient', 'secondaryDark');
  const modeTextBg = useColorModeValue('quaternaryBackground', 'primaryDark06');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const modeFormControlBg = useColorModeValue('#fff', 'secondaryDark');
  const modeLabelBg = useColorModeValue('#EFEFEF', 'primaryDark03');

  let primaryFields = [
    { name: 'Event name', value: eventsCtx.eventState?.name },
    { name: 'Venue name', value: eventsCtx.eventState?.venueName },
  ];

  let formattedStartDate = moment(eventsCtx.eventState?.startDate).format('DD/MM/YYYY');
  let formattedStartTime = moment(eventsCtx.eventState?.startDate).format('h:mm A');
  let formattedEndDate = moment(eventsCtx.eventState?.endDate).format('DD/MM/YYYY');
  let formattedEndTime = moment(eventsCtx.eventState?.endDate).format('h:mm A');

  return (
    <Box className="autofillForDarkBg" w="full" borderRadius="15px">
      <Box className="primaryBoxShadow" bg={modeBoxBg} borderRadius="15px">
        <Text
          fontSize="18px"
          textStyle="headingFamilyMedium"
          p="14px 14px 14px 24px"
          bg={modeTextBg}
          borderRadius="15px 15px 0 0"
        >
          Primary fields
        </Text>
        <Divider borderColor={modeBorderColor} />

        {/* Event status */}
        <FormControl bg={modeFormControlBg}>
          <Flex alignItems="center">
            <Hide below="2xl">
              <Box pl="24px" flexShrink={0} w="240px">
                <FormLabel m={0} variant="adminLabel">
                  Status
                </FormLabel>
              </Box>
            </Hide>
            <Box p="14px" w="full" bg={modeLabelBg}>
              <Show below="2xl">
                <FormLabel pl="10px" variant="adminLabel">
                  Status
                </FormLabel>
              </Show>
              <Box p={3}>
                <EventsRenderStatus value={eventsCtx.eventState?.status} />
              </Box>
            </Box>
          </Flex>
        </FormControl>
        <Divider borderColor={modeBorderColor} />

        {/* Above start date/time and end date/time */}
        {primaryFields.map((field, index) => {
          return (
            <Box key={index}>
              <FormControl bg={modeFormControlBg}>
                <Flex alignItems="center">
                  <Hide below="2xl">
                    <Box pl="24px" flexShrink={0} w="240px">
                      <FormLabel m={0} variant="adminLabel">
                        {field.name}
                      </FormLabel>
                    </Box>
                  </Hide>
                  <Box p="14px" w="full" bg={modeLabelBg}>
                    <Show below="2xl">
                      <FormLabel pl="10px" variant="adminLabel">
                        {field.name}
                      </FormLabel>
                    </Show>
                    <Text p={3} wordBreak="break-word">
                      {field.value}
                    </Text>
                  </Box>
                </Flex>
              </FormControl>

              <Divider borderColor={modeBorderColor} />
            </Box>
          );
        })}

        {/* start date/time */}
        <Flex
          flexDir={{ base: 'column', xl: 'row' }}
          alignItems="center"
          w="full"
          bg={modeFormControlBg}
        >
          <Hide below="2xl">
            <Box pl="24px" flexShrink={0} w="240px">
              <FormLabel m={0} variant="adminLabel">
                Event start date and time
              </FormLabel>
            </Box>
          </Hide>
          <Box w="full">
            <FormControl bg={modeFormControlBg}>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel pl="10px" variant="adminLabel">
                    Event start date
                  </FormLabel>
                </Show>
                <Box w={{ base: '100%', sm: '50%', md: '50%', xl: 'full' }}>
                  <HStack spacing={3} p={2}>
                    <CustomCalendarIcon
                      boxSize="20px"
                      stopColor="#FF8477"
                      stopcolor2="#EC6759"
                    />
                    <Text pt="5px">{formattedStartDate}</Text>
                  </HStack>
                </Box>
              </Box>
            </FormControl>
          </Box>
          <Spacer backgroundColor={modeLabelBg} />

          <Show breakpoint="(max-width: 1279px)">
            <Divider borderColor={modeBorderColor} />
          </Show>

          <Box w="full">
            <FormControl bg={modeFormControlBg}>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel pl="10px" variant="adminLabel">
                    Event start time
                  </FormLabel>
                </Show>
                <Box w={{ base: '100%', sm: '50%', md: '50%', xl: 'full' }}>
                  <HStack spacing={3} p={2}>
                    <CustomClockIcon
                      boxSize="20px"
                      stopColor="#FF8477"
                      stopcolor2="#EC6759"
                    />
                    <Text pt="5px">{formattedStartTime}</Text>
                  </HStack>
                </Box>
              </Box>
            </FormControl>
          </Box>
        </Flex>

        <Divider borderColor={modeBorderColor} />

        {/* end date/time */}
        <Flex
          flexDir={{ base: 'column', xl: 'row' }}
          alignItems="center"
          w="full"
          bg={modeFormControlBg}
          borderBottomRightRadius="15px"
          borderBottomLeftRadius="15px"
        >
          <Hide below="2xl">
            <Box pl="24px" flexShrink={0} w="240px">
              <FormLabel m={0} variant="adminLabel">
                Event end date and time
              </FormLabel>
            </Box>
          </Hide>
          <Box w="full">
            <FormControl bg={modeFormControlBg} borderBottomLeftRadius="15px">
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel pl="10px" variant="adminLabel">
                    Event end date
                  </FormLabel>
                </Show>
                <Box w={{ base: '100%', sm: '50%', md: '50%', xl: 'full' }}>
                  <HStack spacing={3} p={2}>
                    <CustomCalendarIcon
                      boxSize="20px"
                      stopColor="#FF8477"
                      stopcolor2="#EC6759"
                    />
                    <Text pt="5px">{formattedEndDate}</Text>
                  </HStack>
                </Box>
              </Box>
            </FormControl>
          </Box>
          <Spacer backgroundColor={modeLabelBg} />

          <Show breakpoint="(max-width: 1279px)">
            <Divider borderColor={modeBorderColor} />
          </Show>

          <Box w="full">
            <FormControl
              bg={modeFormControlBg}
              borderBottomRightRadius="15px"
              borderBottomLeftRadius="15px"
            >
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel pl="10px" variant="adminLabel">
                    Event end time
                  </FormLabel>
                </Show>
                <Box w={{ base: '100%', sm: '50%', md: '50%', xl: 'full' }}>
                  {/* end time */}
                  <HStack spacing={3} p={2}>
                    <CustomClockIcon
                      boxSize="20px"
                      stopColor="#FF8477"
                      stopcolor2="#EC6759"
                    />
                    <Text pt="5px">{formattedEndTime}</Text>
                  </HStack>
                </Box>
              </Box>
            </FormControl>
          </Box>
        </Flex>
        <Divider borderColor={modeBorderColor} />

        <FormControl
          bg={modeFormControlBg}
          borderBottomLeftRadius="15px"
          borderBottomRightRadius="15px"
        >
          <Flex alignItems="center">
            <Hide below="2xl">
              <Box pl="24px" flexShrink={0} w="240px">
                <FormLabel m={0} fontSize="16px">
                  Time zone
                </FormLabel>
              </Box>
            </Hide>
            <Box
              p="14px"
              w="full"
              bg={modeLabelBg}
              borderBottomRightRadius="15px"
              borderBottomLeftRadius={{ base: '15px', '2xl': '0px' }}
            >
              <Show below="2xl">
                <FormLabel pl="10px" fontSize="16px">
                  Time zone
                </FormLabel>
              </Show>
              <Text p={3}>{eventsCtx.eventState.timeZone}</Text>
            </Box>
          </Flex>
        </FormControl>
      </Box>
    </Box>
  );
};
export default EventPrimaryFields;
