import appsignal from '../../appsignal';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Link,
  Divider,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { HttpContext } from '../../context/HttpContext';
import ReaderField from '../client/readers/ReaderField';

const ReaderConfigurationModal = ({ onClose, isOpen, entityId }) => {
  const { authAxios } = useContext(HttpContext);
  const modeBoxBg = useColorModeValue('white', 'secondaryDark');
  const modeTextBg = useColorModeValue('white', 'primaryDark06');
  const modeLabelBg = useColorModeValue('white', 'primaryDark03');
  const modeOffset = useColorModeValue('0', '16px !important');
  const [configurationFields, setConfigurationFields] = useState([]);

  useEffect(() => {
    const fetchReaderConfiguration = async () => {
      const response = await authAxios.get(
        `api/v1/entities/${entityId}/readerConfiguration`
      );
      const {
        applePassTypeId,
        googleCollectorId,
        googleKeyVersion,
        nfcPrivateKey,
        readerConfigurationApiKey,
      } = response?.data?.data?.attributes ?? {};

      setConfigurationFields([
        { name: 'Apple Pass Type ID', value: applePassTypeId },
        { name: 'Google Collector ID', value: googleCollectorId },
        { name: 'Google Key Version', value: googleKeyVersion },
        { name: 'NFC Private Key', value: nfcPrivateKey },
        {
          name: 'Reader Configuration API Key',
          value: readerConfigurationApiKey,
        },
      ]);
    };
    if (entityId) {
      fetchReaderConfiguration();
    }
  }, [entityId, authAxios]);

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="3xl">
      <ModalOverlay />
      <ModalContent
        bg={modeBoxBg}
        borderRadius="15px"
        overflow="hidden"
        p={{ base: '1rem 0.3rem', sm: '1rem' }}
      >
        <ModalHeader bg={modeTextBg} pb={modeOffset}>
          <Heading fontSize={{ base: '24px', lg: '32px' }} lineHeight="130%">
            Reader Configuration
          </Heading>
        </ModalHeader>
        <ModalBody bg={modeLabelBg}>
          <Text>
            You can use these configuration details to set up your readers. If
            you need help setting up a VTAP Cloud Reader, please refer to{' '}
            <Link
              href="https://docs.google.com/document/d/1kEA_nPeiLUwTPnkkweqkBCfw3N1MXFSY5fdllbwY1H8/edit?usp=sharing"
              textStyle="bodyBold"
              color="secondaryDark"
            >
              this guide.
            </Link>{' '}
          </Text>
          <Divider mt={4} />
          {configurationFields &&
            configurationFields.map((field, index) => {
              return <ReaderField field={field} index={index} />;
            })}
        </ModalBody>
        <ModalFooter bg={modeTextBg} pt={modeOffset}>
          <Button
            onClick={onClose}
            alt="Cancel"
            variant="secondary"
            width={{ base: 'full', sm: 'auto' }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default ReaderConfigurationModal;
