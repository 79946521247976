import React, { useContext } from 'react';
import { Button, Flex, Heading, Spacer, Text, Tooltip, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import ReadersListContext from '../../../store/client/ReadersListContext';
import ReaderListFilter from './ReaderListFilter';
import RedirectionContext from '../../../context/RedirectionContext';
import { MeContext } from '../../../context/MeContext';
import { Link as RouterLink } from 'react-router-dom';
import ReaderConfigurationModal from '../../modals/ReaderConfigurationModal';

function getFiltersString(filters) {
  const { name, uuid, type } = filters;

  if (name === '' && uuid === '' && type === '') {
    return '';
  }

  let values = [];
  if (name !== '') {
    values.push(name);
  }
  if (uuid !== '') {
    values.push(uuid);
  }
  if (type !== '') {
    values.push(type);
  }

  const count = values.length;
  let outString = '';
  values.forEach((value, index) => {
    if (index === count - 1) {
      outString = outString + value;
    } else {
      outString = outString + value + ', ';
    }
  });

  return outString;
}

function ReaderListHeader() {
  const readerListCtx = useContext(ReadersListContext);
  const filters = getFiltersString(readerListCtx.filterValues);
  const filterModeColor = useColorModeValue('secondaryDark08', 'white');
  const meCtx = useContext(MeContext);
  const isSetUpConfirmed = meCtx?.isSetUpConfirmed() ?? true;
  const { filterEntity } = meCtx.state;
  const multipleEntities = meCtx?.state?.entities?.length > 1;
  const {
    isOpen: isReaderConfigurationOpen,
    onOpen: onReaderConfigurationOpen,
    onClose: onReaderConfigurationClose,
  } = useDisclosure();
  const bgTooltip = useColorModeValue('secondaryDark', '#dddddd');
  const textTooltip = useColorModeValue('#ffffff', 'primaryDark');

  return (
    <>
    <Flex
      flexDirection={{ base: 'column', sm: 'row' }}
      flexWrap="wrap"
      mt="15px"
      pt="9px"
      minH="54px"
    >
      <Flex
        gap={{ base: '10px' }}
        alignItems="center"
        justifyContent="flex-start"
        flexWrap="wrap"
        mb={{ base: '10px', sm: '0px' }}
      >
        <Heading alignSelf={{ base: 'flex-start', sm: 'center' }}>
          {filters === '' ? 'Readers' : `Filter results: `}
          <Text
            as="span"
            display="inline-block"
            textStyle="bodyFamilyMedium"
            fontSize="16px"
            color={filterModeColor}
            wordBreak="break-word"
          >
            {filters}
          </Text>
        </Heading>
        {isSetUpConfirmed && (
          <Tooltip
            label="You need to select an entity first"
            isDisabled={!multipleEntities || !!filterEntity}
            hasArrow
            placement="right"
            fontSize="12px"
            borderRadius="6px"
            p="10px"
            bg={bgTooltip}
            color={textTooltip}
          >
            <Button
              alt="Add reader"
              mr={{ base: '0px', sm: '10px' }}
              px={{ base: '20px', sm: '30px' }}
              size="sm"
              isDisabled={multipleEntities && !filterEntity}
              onClick={onReaderConfigurationOpen}
              >
              Reader Configuration
            </Button>
          </Tooltip>
        )}
      </Flex>
      <Spacer />{' '}
      <Flex
        mb="10px"
        gap={{ base: '10px', sm: '0px' }}
        alignItems="flex-end"
        justifyContent="flex-start"
        flexWrap="wrap"
      >
        {isSetUpConfirmed && (
          <Button
          alt="Add reader"
          mr={{ base: '0px', sm: '10px' }}
          px={{ base: '20px', sm: '40px' }}
          size="sm"
          as={RouterLink}
          to="/readers/create"
            >
            Add reader
          </Button>
        )}
        {isSetUpConfirmed && <ReaderListFilter />}
      </Flex>
    </Flex>
    <ReaderConfigurationModal
        isOpen={isReaderConfigurationOpen}
        onClose={onReaderConfigurationClose}
        entityId={multipleEntities ? (filterEntity ? filterEntity.uuid : "") : meCtx?.state?.entities?.[0]?.uuid}
      />
    </>
  );
}

export default ReaderListHeader;
