import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
  useDisclosure,
  useMediaQuery,
  useOutsideClick,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { CustomChevronLeftIcon } from '../../theme/icons/CustomChevronLeftIcon';
import { CustomAccountSettingsMultiIcon } from '../../theme/multicolor-icons/CustomAccountSettingsMultiIcon';
import { CustomCogMultiIcon } from '../../theme/multicolor-icons/CustomCogMultiIcon';
import { CustomDeveloperToolsMultiIcon } from '../../theme/multicolor-icons/CustomDeveloperToolsMultiIcon';
import { CustomLogoutMultiIcon } from '../../theme/multicolor-icons/CustomLogoutMultiIcon';

const TopRightAccountCallToActions = (props) => {
  const [transformPopoverArrow, setTransformPopoverArrow] = useState(false);
  const transformPopoverArrowRef = useRef();
  useOutsideClick({
    ref: transformPopoverArrowRef,
    handler: () => {
      setTransformPopoverArrow(false);
    },
  });
  const { onClose } = useDisclosure();
  const modeColorText = useColorModeValue('secondaryDark', '#DDDD');
  const modeColorTextHover = useColorModeValue('black', 'white');
  const [isLargerThanLG] = useMediaQuery('(min-width: 62em)');

  return (
    isLargerThanLG && (
      <Box pos="absolute" right={6} top={5} zIndex="100">
        <Flex justifyContent="space-between" alignItems="center">
          <Box as={Link} to="/developer-tools" mr={5}>
            <HStack>
              <CustomDeveloperToolsMultiIcon boxSize="20px" />
              <Heading
                fontSize="14px"
                top="1px"
                pos="relative"
                color={props.publicPage ? '#DDD' : modeColorText}
                _hover={
                  props.publicPage
                    ? { color: 'white', transition: '0.3s' }
                    : { color: modeColorTextHover, transition: '0.3s' }
                }
                transition="0.3s"
              >
                Developer tools
              </Heading>
            </HStack>
          </Box>
          <Flex justifyContent="flex-end">
            <Menu
              onClose={() => {
                setTransformPopoverArrow(false);
              }}
              variant="myAccount"
            >
              <MenuButton
                as={Button}
                variant="myAccount"
                onClick={() => {
                  setTransformPopoverArrow((prevState) => !prevState);
                }}
              >
                <HStack>
                  <CustomAccountSettingsMultiIcon boxSize="20px" />
                  <Heading
                    fontSize="14px"
                    top="1px"
                    pos="relative"
                    color={props.publicPage ? '#DDD' : modeColorText}
                    _hover={{ color: modeColorTextHover, transition: '0.3s' }}
                    transition="0.3s"
                  >
                    My account
                  </Heading>
                  <Box pt="3px">
                    <CustomChevronLeftIcon
                      ref={transformPopoverArrowRef}
                      transform={
                        transformPopoverArrow ? 'rotate(90deg)' : 'rotate(-90deg)'
                      }
                      alignSelf="flex-end"
                      color={transformPopoverArrow ? modeColorTextHover : null}
                    />
                  </Box>
                </HStack>
              </MenuButton>
              <MenuList>
                <Box className="toolTipTriangleMyAcc" />
                <MenuItem borderTopLeftRadius="6px" borderTopRightRadius="6px">
                  <HStack
                    as={Link}
                    to="/account-settings"
                    alignItems="center"
                    justifyContent="flex-start"
                    onClick={onClose}
                  >
                    <CustomCogMultiIcon boxSize="20px" />
                    <Heading fontSize="13px">Account settings</Heading>
                  </HStack>
                </MenuItem>
                <MenuItem
                  borderBottomLeftRadius="6px"
                  borderBottomRightRadius="6px"
                  onClick={props.handleClick}
                >
                  <HStack alignItems="center" justifyContent="flex-start">
                    <CustomLogoutMultiIcon boxSize="20px" />
                    <Heading fontSize="13px">Log out</Heading>
                  </HStack>
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </Box>
    )
  );
};

export default TopRightAccountCallToActions;
