import { Input, InputGroup, InputLeftElement, useColorModeValue } from '@chakra-ui/react';
import { CustomSearchIcon } from '../../theme/icons/CustomSearchIcon';

export function CustomSearchbar(props) {
  const modeBgColor = useColorModeValue('white', 'secondaryDark');

  const handleInputSearch = (e) => {
    const value = e.target.value;
    props.setInputSearch(value);
  };

  return (
    <>
      <InputGroup minW={{base: "100%", sm: "300px"}}>
        <InputLeftElement
          width="30px"
          height="full"
          mt={{ base: '5px', sm: '0' }}
          pl="10px"
          children={<CustomSearchIcon name="search" />}
        />
        <Input
          alignSelf={{ base: 'flex-start', sm: 'center' }}
          mt={{ base: '12px', sm: '0' }}
          pl="35px"
          alt="Search template name"
          fontSize="12px"
          fontWeight="bold"
          textTransform="uppercase"
          mr={{base: "0", sm: "15px"}}
          w="full"
          bg={modeBgColor}
          height="36px"
          type="text"
          placeholder="Search template name"
          value={props.inputSearch}
          onChange={handleInputSearch}
        ></Input>
      </InputGroup>
    </>
  );
}
