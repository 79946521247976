import { useEffect } from 'react';
import ReactGA from 'react-ga4';
const TRACKING_ID = 'G-GPHGGL4J75'; // OUR_TRACKING_ID

const useGATracker = () => {
  useEffect(() => {
    if (process.env.REACT_APP_GA_ENVIRONMENT === 'true') {
      ReactGA.initialize(TRACKING_ID);
      ReactGA.send('pageview');
    }
  }, []);
};

export default useGATracker;
