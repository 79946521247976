import { motion } from 'framer-motion';
import { Icon } from '@chakra-ui/react';

const EventsIconAnimated = () => {
  return (
    <Icon viewBox="0 0 128 118" width="128px" height="118px">
      <path
        d="M63 115C92.2711 115 116 91.2711 116 62C116 32.7289 92.2711 9 63 9C33.7289 9 10 32.7289 10 62C10 91.2711 33.7289 115 63 115Z"
        fill="#D1D1D1"
      />
      <g filter="url(#filter0_d_1989_2127)">
        <mask
          id="mask0_1989_2127"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x="10"
          y="9"
          width="106"
          height="106"
        >
          <motion.path
            d="M63 115C92.2711 115 116 91.2711 116 62C116 32.7289 92.2711 9 63 9C33.7289 9 10 32.7289 10 62C10 91.2711 33.7289 115 63 115Z"
            fill="#D1D1D1"
            animate={{
              opacity: [0, 1],
            }}
            transition={{ duration: 1, ease: 'easeInOut' }}
          />
        </mask>
        <g mask="url(#mask0_1989_2127)">
          <motion.path
            d="M93.3867 39.3867H32.6134C30.662 39.3867 29.0801 40.9686 29.0801 42.9201V117.12C29.0801 119.071 30.662 120.653 32.6134 120.653H93.3867C95.3382 120.653 96.9201 119.071 96.9201 117.12V42.9201C96.9201 40.9686 95.3382 39.3867 93.3867 39.3867Z"
            fill="white"
            animate={{
              opacity: [0, 1],
            }}
            transition={{ duration: 1, ease: 'easeInOut' }}
          />
        </g>
      </g>
      <motion.path
        d="M56.6398 46.4534H38.2665C37.0956 46.4534 36.1465 47.4025 36.1465 48.5734C36.1465 49.7442 37.0956 50.6934 38.2665 50.6934H56.6398C57.8107 50.6934 58.7598 49.7442 58.7598 48.5734C58.7598 47.4025 57.8107 46.4534 56.6398 46.4534Z"
        fill="#3B4851"
        fillOpacity="0.8"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M56.6398 76.1333H38.2665C37.0956 76.1333 36.1465 77.0825 36.1465 78.2533C36.1465 79.4241 37.0956 80.3733 38.2665 80.3733H56.6398C57.8107 80.3733 58.7598 79.4241 58.7598 78.2533C58.7598 77.0825 57.8107 76.1333 56.6398 76.1333Z"
        fill="#3B4851"
        fillOpacity="0.4"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M39.6798 56.8467H86.3198C87.9951 56.8467 89.3531 58.2047 89.3531 59.88V66.9467C89.3531 68.6219 87.9951 69.98 86.3198 69.98H39.6798C38.0046 69.98 36.6465 68.6219 36.6465 66.9467V59.88C36.6465 58.2047 38.0046 56.8467 39.6798 56.8467Z"
        stroke="url(#paint0_linear_1989_2127)"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M86.3202 57.0533H39.6802C38.1191 57.0533 36.8535 58.3189 36.8535 59.88V66.9467C36.8535 68.5078 38.1191 69.7733 39.6802 69.7733H86.3202C87.8813 69.7733 89.1469 68.5078 89.1469 66.9467V59.88C89.1469 58.3189 87.8813 57.0533 86.3202 57.0533Z"
        stroke="url(#paint1_linear_1989_2127)"
        strokeWidth="2"
        //OVDE
        fill="#F7FAFC"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M86.3198 86.0266H39.6798C37.7284 86.0266 36.1465 87.6085 36.1465 89.5599V95.2133C36.1465 97.1647 37.7284 98.7466 39.6798 98.7466H86.3198C88.2712 98.7466 89.8531 97.1647 89.8531 95.2133V89.5599C89.8531 87.6085 88.2712 86.0266 86.3198 86.0266Z"
        fill="#DDDDDD"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M47.4526 31.6133C49.0138 31.6133 50.2793 30.3478 50.2793 28.7866C50.2793 27.2255 49.0138 25.96 47.4526 25.96C45.8915 25.96 44.626 27.2255 44.626 28.7866C44.626 30.3478 45.8915 31.6133 47.4526 31.6133Z"
        fill="white"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M62.9995 31.6133C64.5606 31.6133 65.8262 30.3478 65.8262 28.7866C65.8262 27.2255 64.5606 25.96 62.9995 25.96C61.4384 25.96 60.1729 27.2255 60.1729 28.7866C60.1729 30.3478 61.4384 31.6133 62.9995 31.6133Z"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M78.5464 31.6133C80.1075 31.6133 81.3731 30.3478 81.3731 28.7866C81.3731 27.2255 80.1075 25.96 78.5464 25.96C76.9853 25.96 75.7197 27.2255 75.7197 28.7866C75.7197 30.3478 76.9853 31.6133 78.5464 31.6133Z"
        fill="white"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M71 73C73.7614 73 76 70.7614 76 68C76 65.2386 73.7614 63 71 63C68.2386 63 66 65.2386 66 68C66 70.7614 68.2386 73 71 73Z"
        fill="#B1B6B9"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M77.3148 72.9035C77.3745 72.8679 77.4364 72.836 77.5003 72.8078C77.7191 72.7114 77.9557 72.6615 78.1948 72.6615C78.434 72.6615 78.6705 72.7114 78.8894 72.8078C79.1082 72.9043 79.3046 73.0453 79.4659 73.2219C79.6272 73.3984 79.75 73.6066 79.8264 73.8333L79.3526 73.993L79.8264 73.8333C79.9002 74.0522 79.9292 74.2836 79.9117 74.5138V78.9714V78.9801L79.9117 78.9801C79.8987 79.7233 79.7264 80.7385 79.2392 81.5866C78.7406 82.4546 77.8935 83.1664 76.5872 83.1707C76.5707 83.1715 76.5461 83.1728 76.514 83.1744C76.1649 83.1922 74.9256 83.2556 73.5365 83.2556H73.4749L73.4721 83.2548C72.9325 83.2548 72.4094 83.2539 71.9284 83.2235L71.9262 83.2233C71.2425 83.1771 70.5848 82.9431 70.0255 82.5473C69.4706 82.1545 69.0338 81.6174 68.7624 80.9943L65.8127 75.1649C65.536 74.8176 65.3993 74.3782 65.4313 73.9337C65.4649 73.4673 65.6814 73.0332 66.0337 72.7258L66.0413 72.7191L66.0492 72.7128C66.3464 72.4739 66.7172 72.3453 67.0985 72.3488L67.1084 72.3489L67.1084 72.349C67.3763 72.3568 67.6394 72.4226 67.8794 72.5419C68.1195 72.6611 68.3308 72.8311 68.4988 73.0399L68.5059 73.0487L68.5126 73.0578L68.9838 73.7009V68.2046C68.9838 67.7237 69.1748 67.2624 69.5149 66.9224C69.8549 66.5823 70.3162 66.3912 70.7971 66.3912C71.278 66.3912 71.7393 66.5823 72.0793 66.9224M77.3148 72.9035L72.0793 66.9224M77.3148 72.9035C77.2419 72.7509 77.1467 72.6095 77.0322 72.4841C76.8708 72.3076 76.6745 72.1666 76.4556 72.0701C76.2368 71.9736 76.0002 71.9238 75.7611 71.9238C75.5219 71.9238 75.2854 71.9736 75.0665 72.0701C75.0284 72.0869 74.991 72.105 74.9543 72.1245C74.8776 71.9031 74.7565 71.6996 74.5984 71.5266C74.4371 71.35 74.2407 71.209 74.0219 71.1125C73.803 71.0161 73.5665 70.9662 73.3273 70.9662C73.0882 70.9662 72.8516 71.0161 72.6328 71.1125C72.6253 71.1158 72.6179 71.1192 72.6104 71.1226M77.3148 72.9035L72.6104 71.1226M72.0793 66.9224C72.4194 67.2624 72.6104 67.7237 72.6104 68.2046V71.1226M72.0793 66.9224L72.6104 71.1226"
        fill="#FF8477"
        stroke="white"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="107"
        y="27"
        width="18"
        height="3"
        rx="1.5"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ delay: 1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="95"
        y="18"
        width="18"
        height="3"
        rx="1.5"
        transform="rotate(-90 95 18)"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ delay: 1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.21967 59.0653C-0.0732233 59.3582 -0.0732233 59.833 0.21967 60.1259C0.512563 60.4188 0.987437 60.4188 1.28033 60.1259L3.17279 58.2335L5.06526 60.1259C5.35815 60.4188 5.83303 60.4188 6.12592 60.1259C6.41881 59.833 6.41881 59.3582 6.12592 59.0653L4.23345 57.1728L6.12592 55.2803C6.41881 54.9874 6.41881 54.5126 6.12592 54.2197C5.83303 53.9268 5.35815 53.9268 5.06526 54.2197L3.17279 56.1121L1.28033 54.2197C0.987437 53.9268 0.512563 53.9268 0.21967 54.2197C-0.0732235 54.5126 -0.073223 54.9874 0.21967 55.2803L2.11213 57.1728L0.21967 59.0653Z"
        fill="#3B4851"
        fillOpacity="0.8"
        animate={{
          opacity: [0, 1],
          x: [20, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M123.38 75.8583C123.17 76.0685 123.17 76.4093 123.38 76.6195C123.591 76.8297 123.931 76.8297 124.142 76.6195L125.5 75.2614L126.858 76.6195C127.068 76.8297 127.409 76.8297 127.619 76.6195C127.829 76.4093 127.829 76.0685 127.619 75.8583L126.261 74.5002L127.619 73.1419C127.829 72.9317 127.829 72.5909 127.619 72.3807C127.409 72.1705 127.068 72.1705 126.858 72.3807L125.5 73.7389L124.142 72.3807C123.931 72.1705 123.591 72.1705 123.38 72.3807C123.17 72.5909 123.17 72.9317 123.38 73.1419L124.739 74.5002L123.38 75.8583Z"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          x: [-20, 0],
          y: [-10, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="22"
        y="15.307"
        width="7.50525"
        height="1.39773"
        rx="0.698864"
        transform="rotate(-45 22 15.307)"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          x: [10, 0],
          y: [10, 0],
          rotate: [-45, -45],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="109.597"
        y="61.7903"
        width="7.91522"
        height="1.68777"
        rx="0.843883"
        transform="rotate(-135 109.597 61.7903)"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          x: [-10, -5],
          y: [10, 0],
          rotate: [-135, -135],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.5, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="104"
        y="18.7279"
        width="18"
        height="3"
        rx="1.5"
        transform="rotate(-45 104 18.7279)"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ delay: 1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 35.6C14.1075 35.6 14.6 35.1075 14.6 34.5C14.6 33.8925 14.1075 33.4 13.5 33.4C12.8925 33.4 12.4 33.8925 12.4 34.5C12.4 35.1075 12.8925 35.6 13.5 35.6ZM13.5 37C14.8807 37 16 35.8807 16 34.5C16 33.1193 14.8807 32 13.5 32C12.1193 32 11 33.1193 11 34.5C11 35.8807 12.1193 37 13.5 37Z"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
          x: [10, 0],
          y: [5, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.3, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 85C15.5523 85 16 84.5523 16 84C16 83.4477 15.5523 83 15 83C14.4477 83 14 83.4477 14 84C14 84.5523 14.4477 85 15 85ZM15 87C16.6569 87 18 85.6569 18 84C18 82.3431 16.6569 81 15 81C13.3431 81 12 82.3431 12 84C12 85.6569 13.3431 87 15 87Z"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          x: [10, 0],
          y: [-5, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.3, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.5 101C114.328 101 115 100.328 115 99.5C115 98.6716 114.328 98 113.5 98C112.672 98 112 98.6716 112 99.5C112 100.328 112.672 101 113.5 101ZM113.5 103C115.433 103 117 101.433 117 99.5C117 97.567 115.433 96 113.5 96C111.567 96 110 97.567 110 99.5C110 101.433 111.567 103 113.5 103Z"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
          x: [-15, 0],
          y: [-15, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.1, duration: 1, ease: 'easeInOut' }}
      />
      <defs>
        <filter
          id="filter0_d_1989_2127"
          x="23.0801"
          y="30.3867"
          width="79.8398"
          height="87.6133"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1989_2127"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1989_2127"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1989_2127"
          x1="37.5325"
          y1="70.48"
          x2="73.9535"
          y2="50.0962"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8477" />
          <stop offset="1" stopColor="#EC6759" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1989_2127"
          x1="38.203"
          y1="69.7733"
          x2="72.2802"
          y2="49.1398"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8477" />
          <stop offset="1" stopColor="#EC6759" />
        </linearGradient>
      </defs>
    </Icon>
  );
};

export default EventsIconAnimated;
