import React, { useContext, useEffect, useState } from 'react';
import PassTemplateContext from '../../../store/client/PassTemplateContext';
import CropModal from '../../modals/CropModal';
import CustomDropzone from '../../common/CustomDropzone';
import {
  Box,
  Flex,
  Heading,
  Link,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { CustomPlusIcon } from '../../../theme/icons/CustomPlusIcon';
import placeholderBanner from '../../../assets/images/banner.png';
import useAddWatermark from '../../../hooks/useAddWatermark';

function GoogleBannerImage(props) {
  const [droppedImage, setDroppedImage] = useState(null);
  const passTemplateCtx = useContext(PassTemplateContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [aspectRatio, setAspectRatio] = useState('');
  const [showCustomDropzone, setShowCustomDropzone] = useState(
    !!passTemplateCtx.googleBannerImage
  );
  const modeColorText = useColorModeValue('secondaryDark', '#DDDD');
  const modeColorTextHover = useColorModeValue('black', 'white');

  const { googleBannerLogo: image } = passTemplateCtx;
  const { determineWatermarkColor } = useAddWatermark();

  function handleUpdateDroppedImage(image) {
    setDroppedImage(image);
  }

  async function handleSaveDroppedImage(base64DroppedFile) {
    const watermarkColor = await determineWatermarkColor(base64DroppedFile);
    passTemplateCtx.updateGoogleBannerWatermarkColor(watermarkColor);
    props.setEditedFields({
      ...props.editedFields,
      google_banner_watermark_color: watermarkColor,
    });

    passTemplateCtx.updateGoogleBannerImage(base64DroppedFile);
    passTemplateCtx.updateGoogleBannerLogo({
      ...image,
      src: droppedImage.preview,
      mimeType: droppedImage.type,
    });
    props.setEditedImages({
      ...props.editedImages,
      google_banner_image: true,
    });
    if (props.isEdit) {
      passTemplateCtx.updateDisplayGoogleBannerWatermarkPreview(true);
    }
  }

  function handleAddGoogleBannerToTemplate() {
    passTemplateCtx.resetGoogleBannerLogo();
    passTemplateCtx.updateGoogleBannerImage(placeholderBanner);
    props.setEditedImages({ ...props.editedImages, google_banner_image: true });
    setShowCustomDropzone(true);
    passTemplateCtx.updateDisplayGoogleBannerWatermarkPreview(true);
  }

  function isImageAdded() {
    onOpen();
  }

  async function updateImage(image) {
    passTemplateCtx.updateGoogleBannerLogo(image);
  }

  useEffect(() => {
    if (passTemplateCtx.passType === 'eventTicket') {
      setAspectRatio('1125x294');
    } else if (
      passTemplateCtx.passType === 'storeCard' ||
      passTemplateCtx.passType === 'stampCard'
    ) {
      setAspectRatio('1125x432');
    } else {
      setAspectRatio('1125x370');
    }
  }, [passTemplateCtx.passType]);

  useEffect(() => {
    if (passTemplateCtx.googleBannerImage) {
      setShowCustomDropzone(true);
    }
  }, [passTemplateCtx.googleBannerImage]);

  return (
    <>
      {showCustomDropzone ? (
        <>
          <CropModal
            key="google_banner_image_crop_modal"
            onClose={onClose}
            onOpen={onOpen}
            isOpen={isOpen}
            image={image}
            updateImage={updateImage}
            parent="google_banner_image"
            title="Crop google banner image"
            droppedImage={droppedImage}
            saveDroppedImage={handleSaveDroppedImage}
          />
          <CustomDropzone
            key="google_banner_image_drop_zone"
            image={image}
            isImageAdded={isImageAdded}
            parent="google_banner_image"
            explanation={`Recommended size: ${aspectRatio} pixels. Use wide, rectangular images. Recommended file type is png.`}
            accept={['image/jpg', 'image/jpeg', 'image/png', 'image/x-png']}
            droppedImage={droppedImage}
            updateDroppedImage={handleUpdateDroppedImage}
            removable={props.removable}
            setShowCustomDropzone={setShowCustomDropzone}
          />
        </>
      ) : (
        <Box as={Link} onClick={handleAddGoogleBannerToTemplate}>
          <Flex align="center" gap={1} mt={2}>
            <CustomPlusIcon color="#FF8477" boxSize="12px" />
            <Heading
              fontSize="14px"
              top="1px"
              pos="relative"
              color={props.publicPage ? '#DDD' : modeColorText}
              _hover={
                props.publicPage
                  ? { color: 'white', transition: '0.3s' }
                  : { color: modeColorTextHover, transition: '0.3s' }
              }
              transition="0.3s"
            >
              Add google banner image to template
            </Heading>
          </Flex>
        </Box>
      )}
    </>
  );
}

export default GoogleBannerImage;
