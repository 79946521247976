import {
  Container,
  Box,
  VStack,
  Image,
  Link,
  Center,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  IconButton,
  Heading,
  Flex,
  Text,
  Button,
  useColorModeValue,
  HStack,
  Square,
  Tooltip,
} from '@chakra-ui/react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { MeContext } from '../../context/MeContext';
import { useDisclosure, useMediaQuery } from '@chakra-ui/react';
import CustomNavlink from '../../common/CustomNavlink';
import { CustomMenuOpenIcon } from '../../theme/icons/CustomMenuOpenIcon';

import logoDarkModeSrc from '../../assets/vectors/logoDarkMode.svg';
import logoSrc from '../../assets/vectors/logo.svg';
import dividerSrc from '../../assets/vectors/divider.svg';
import dividerDarkSrc from '../../assets/vectors/divider-dark.svg';

import { CustomDashboardMultiIcon } from '../../theme/multicolor-icons/CustomDashboardMultiIcon';
import { CustomPassesMultiIcon } from '../../theme/multicolor-icons/CustomPassesMultiIcon';
import { CustomTemplatesMultiIcon } from '../../theme/multicolor-icons/CustomTemplatesMultiIcon';
import { CustomEventsMultiIcon } from '../../theme/multicolor-icons/CustomEventsMultiIcon';
import { CustomReadersAltMultiIcon } from '../../theme/multicolor-icons/CustomReadersAltMultiIcon';
import { CustomCogMultiIcon } from '../../theme/multicolor-icons/CustomCogMultiIcon';
import { CustomLogoutMultiIcon } from '../../theme/multicolor-icons/CustomLogoutMultiIcon';

import developerToolsSrc from '../../assets/images/developer-tools.png';
import trialExpirySrc from '../../assets/images/trial-expiry.png';
import { CustomLiveEventDataMultiIcon } from '../../theme/multicolor-icons/CustomLiveEventDataMultiIcon';
import {
  CustomReactSelect,
  formatOptionLabelFilterEntity,
} from '../../components/common/CustomReactSelect';
import { useContext, useEffect, useState } from 'react';
import { CustomEmailMultiIcon } from '../../theme/multicolor-icons/CustomEmailMultiIcon';
import useOrganizationStatus from '../../hooks/useOrganisationStatus';

function Sidebar({ apiView, handleClick }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLargerThanLG] = useMediaQuery('(min-width: 62em)');
  const [isSmallerThanMD] = useMediaQuery('(max-width: 1280px)');
  const modeLogoSrc = useColorModeValue(logoSrc, logoDarkModeSrc);
  const modeDividerSrc = useColorModeValue(dividerSrc, dividerDarkSrc);
  const modeColorOnImage = useColorModeValue('white', 'primaryDark');
  const [entitiesList, setEntitiesList] = useState([]);
  const meCtx = useContext(MeContext);
  const {
    organisationStatus,
    trialExpiry,
    entities,
    filterEntity,
    batchNotificationsEnabled,
  } = meCtx.state;
  const { shouldRender } = useOrganizationStatus();
  const isSetUpConfirmed = meCtx?.isSetUpConfirmed() ?? true;
  const [selectedEntity, setSelectedEntity] = useState('');
  const [displayTooltip, setDisplayTooltip] = useState(false);
  const bgTooltip = useColorModeValue('#FD8175');
  const textTooltip = useColorModeValue('#ffffff');
  let location = useLocation();
  const selectEntityLocation =
    location.pathname === '/pass-templates/create' ||
    location.pathname === '/events/create' ||
    location.pathname === '/readers/create' ||
    location.pathname === '/messaging';
  const openSidebarOnMobile =
    isSmallerThanMD &&
    entities.length > 1 &&
    selectEntityLocation &&
    !filterEntity;
  const handlePassesClick = () => {
    sessionStorage.removeItem('lastVisitedPageIndex');
    sessionStorage.removeItem('navigatedFromPassPage');
    sessionStorage.removeItem('filterValues');
    onClose();
  };

  // Calculate days remaining in trial
  let trialDaysRemaining = null;
  if (organisationStatus === 'trial' && !!trialExpiry) {
    const trialEnd = new Date(trialExpiry);
    const today = new Date();
    trialDaysRemaining = Math.ceil((trialEnd - today) / (1000 * 60 * 60 * 24));
  }

  const displayTrialEnd =
    isSetUpConfirmed && organisationStatus === 'trial' && trialDaysRemaining;

  useEffect(() => {
    let entitiesArray = [];
    if (entities) {
      entities.sort((a, b) => a.name.localeCompare(b.name));
      entities.forEach((item) => {
        entitiesArray.push({
          label: item.name,
          value: item,
        });
      });
    }
    setEntitiesList([{ label: 'All entities' }, ...entitiesArray]);
  }, [entities]);

  useEffect(() => {
    if (openSidebarOnMobile) {
      onOpen();
      setTimeout(() => {
        setDisplayTooltip(true);
      }, 300);
    }
  }, [location]);

  useEffect(() => {
    if (openSidebarOnMobile && isOpen) {
      setDisplayTooltip(false);
      setTimeout(() => {
        setDisplayTooltip(true);
      }, 300);
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectEntityLocation) {
      if (!filterEntity) {
        setTimeout(() => {
          setDisplayTooltip(true);
        }, 300);
      } else {
        setDisplayTooltip(false);
      }
    } else {
      setDisplayTooltip(false);
    }
  }, [location, filterEntity]);

  const sidebarContent = () => {
    return (
      <Container>
        <Center mt={9} textAlign="center">
          <Link as={RouterLink} to="/">
            <Image w="137px" src={modeLogoSrc} alt="PassEntry" loading="lazy" />
          </Link>
        </Center>
        <Box my={5}>
          <Image src={modeDividerSrc} alt="divider" loading="lazy" />
        </Box>
        {entitiesList.length > 2 && shouldRender && (
          <Tooltip
            label={'Please select an entity'}
            hasArrow
            placement={!isSmallerThanMD ? 'right' : 'bottom'}
            fontSize="12px"
            bg={bgTooltip}
            color={textTooltip}
            w="205px"
            borderRadius="6px"
            textAlign="center"
            p="10px"
            isOpen={displayTooltip}
          >
            <Box my={5} mx={1}>
              <CustomReactSelect
                options={entitiesList}
                formatOptionLabel={formatOptionLabelFilterEntity}
                onChange={(entity) => {
                  setSelectedEntity(entity);
                  meCtx.updateFilterEntity(entity.value);
                }}
                value={selectedEntity}
                placeholder="All entities"
                id="passTemplate"
                PassTemplate
              />
            </Box>
          </Tooltip>
        )}
        <VStack spacing={0} className="sideNav">
          <CustomNavlink
            onClick={onClose}
            to="/dashboard"
            title="Dashboard"
            component={CustomDashboardMultiIcon}
          />
          {/* <CustomNavlink
            onClick={onClose}
            to="/live-data"
            title="Live event data"
            component={CustomAnalyticsMultiIcon}
          /> */}
          <CustomNavlink
            onClick={onClose}
            to="/live-data"
            title="Pass taps"
            component={CustomLiveEventDataMultiIcon}
            boxSize="21px"
          />
          <CustomNavlink
            onClick={handlePassesClick}
            to="/passes"
            title="Passes"
            component={CustomPassesMultiIcon}
            boxSize="21px"
          />
          <CustomNavlink
            onClick={onClose}
            to="/pass-templates"
            title="Pass templates"
            component={CustomTemplatesMultiIcon}
          />
          <CustomNavlink
            onClick={onClose}
            to="/events"
            title="Events"
            component={CustomEventsMultiIcon}
          />
          <CustomNavlink
            onClick={onClose}
            to="/readers"
            title="Readers"
            component={CustomReadersAltMultiIcon}
          />
          {batchNotificationsEnabled && (
            <CustomNavlink
              onClick={onClose}
              to="/messaging"
              title="Messaging"
              component={CustomEmailMultiIcon}
            />
          )}
          {!isLargerThanLG && (
            <>
              <Box alignSelf="flex-start" pl="14px" pt="20px" pb="10px">
                <Heading size="md" textTransform="uppercase">
                  Account pages
                </Heading>
              </Box>
              <CustomNavlink
                onClick={onClose}
                to="/account-settings"
                title="Account settings"
                component={CustomCogMultiIcon}
              />
              <Box className="inactive logoutSideBar" onClick={handleClick}>
                <Flex alignItems="center" justifyContent="space-between">
                  <HStack spacing={3}>
                    <Square size="32px" className="sideNavSquare">
                      <CustomLogoutMultiIcon
                        boxSize="15px"
                        className="sideNavIcon"
                      />
                    </Square>
                    <Box>Log out</Box>
                  </HStack>
                </Flex>
              </Box>
            </>
          )}
        </VStack>

        <Box w="full" color={modeColorOnImage} mb={4}>
          <Flex
            overflow="hidden"
            flexDir="column"
            mt="19px"
            minH="200px"
            // backgroundImage="url('./images/developer-tools.webp')"
            backgroundImage={`url(${developerToolsSrc})`}
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            p={4}
            justifyContent="flex-end"
            borderRadius="15px"
          >
            <Heading fontSize="16px">Documentation and API&nbsp;keys</Heading>
            <Text fontSize="12px" lineHeight="120%" mt="6px" mb="9px">
              Please check our docs and instructions
            </Text>
            <Button
              variant="white"
              size="sm"
              fontSize="12px"
              as={RouterLink}
              to="/developer-tools"
              onClick={onClose}
            >
              Developer tools
            </Button>
          </Flex>
        </Box>

        {displayTrialEnd && (
          <Box w="full" color={modeColorOnImage} mb={4}>
            <Flex
              overflow="hidden"
              flexDir="column"
              mt="19px"
              minH="200px"
              backgroundImage={`url(${trialExpirySrc})`}
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              backgroundSize="cover"
              p={4}
              justifyContent="flex-end"
              borderRadius="15px"
            >
              <Heading
                fontSize="20px"
                mb="5px"
              >{`Your trial ends in ${trialDaysRemaining} day(s).`}</Heading>
              <Text fontSize="13.5px" lineHeight="120%" mt="6px" mb="9px">
                Contact{' '}
                <Link href="mailto:sales@passentry.com" color="">
                  sales@passentry.com
                </Link>{' '}
                to continue.
              </Text>
            </Flex>
          </Box>
        )}
      </Container>
    );
  };

  return (
    <>
      {isLargerThanLG && !apiView ? (
        <Box
          bg="quinaryBackground"
          h="100vh"
          w="250px"
          zIndex={1}
          pos="fixed"
          overflow="auto"
        >
          {sidebarContent()}
        </Box>
      ) : (
        isSmallerThanMD && (
          <>
            <IconButton
              variant="solid"
              height="40px"
              isRound
              bg="brand"
              color="white"
              className="primaryBoxShadow"
              aria-label="Open sidebar"
              icon={<CustomMenuOpenIcon boxSize="24px" />}
              onClick={onOpen}
              pos="fixed"
              top="10px"
              right="10px"
              zIndex={100}
              _focus={{ outline: 'none' }}
              _hover={{ bg: 'brandDark' }}
            />
            <Drawer
              isOpen={isOpen}
              placement="left"
              onClose={onClose}
              size="sm"
            >
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerBody bg="quinaryBackground" p={0}>
                  {sidebarContent()}
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </>
        )
      )}
    </>
  );
}

export default Sidebar;
