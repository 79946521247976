import { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import appsignal from '../../../appsignal';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTable, usePagination } from 'react-table';
import {
  Stack,
  Flex,
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Text,
  useMediaQuery,
  Spinner,
  Hide,
  Image,
  HStack,
  Show,
  useColorModeValue,
  useDisclosure,
  useColorMode,
  useToast,
  Tooltip,
} from '@chakra-ui/react';
import TableBox from '../../common/TableBox';
import CustomToast from '../../../common/CustomToast';
import ReactTablePagination from '../../../common/ReactTablePagination';
import EmptyState from '../../../common/EmptyState';
import readersIconSrc from '../../../assets/vectors/readersIcon.svg';
import readersIconDarkSrc from '../../../assets/vectors/readersIcon-dark.svg';
import ReadersRenderStatus from './ReadersRenderStatus';
import { CustomLogoutMultiIcon } from '../../../theme/multicolor-icons/CustomLogoutMultiIcon';
import ReadersListMoreInfo from './ReadersListMoreInfo';
import ReadersListContext from '../../../store/client/ReadersListContext';
import RedirectionContext from '../../../context/RedirectionContext';
import ReaderIconAnimated from '../../../theme/illustrations-animated//ReaderIconAnimated';
import ReaderIconDarkAnimated from '../../../theme/illustrations-animated//ReaderIconDarkAnimated';
import { CustomEditIcon } from '../../../theme/icons/CustomEditIcon';
import LogoutReaderModal from '../../modals/LogoutReaderModal';
import { CustomExclamationMarkInverseIcon } from '../../../theme/icons/CustomExclamationMarkInverseIcon';

// Create a default prop getter (per documentation https://react-table.tanstack.com/docs/examples/data-driven-classes-and-styles)
const defaultPropGetter = () => ({});

export default function ReadersListTable({
  columns,
  data,
  fetchData,
  loading,
  total,
  pageCount: controlledPageCount,
  filterEntity,
  // getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  query,
}) {
  const navigate = useNavigate();
  const [readerToLogOut, setReaderToLogOut] = useState();
  const readerListCtx = useContext(ReadersListContext);
  const redirectCtx = useContext(RedirectionContext);
  const [lastSeenAtTooltipOpen, setlastSeenAtTooltipOpen] = useState(false);
  const bgTooltip = useColorModeValue('secondaryDark', '#dddddd');
  const textTooltip = useColorModeValue('#ffffff', 'primaryDark');

  // use this ref to keep track of updating internal table state
  const tableStateUpdateRef = useRef(false);

  const [isLargerThan2XL] = useMediaQuery('(min-width: 96em)');
  const [isLargerThanXL] = useMediaQuery('(min-width: 80em)');
  const [isLargerThanLG] = useMediaQuery('(min-width: 62em)');
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
  const [isLargerThanSM] = useMediaQuery('(min-width: 30em)');
  const [isLargerThanS] = useMediaQuery('(min-width: 18em)');
  const [isLargerThanXS] = useMediaQuery('(min-width: 10em)');

  const modeReadersIcon = useColorModeValue(
    <ReaderIconAnimated />,
    <ReaderIconDarkAnimated />
  );
  const modeReadersIconSrc = useColorModeValue(
    readersIconSrc,
    readersIconDarkSrc
  );

  const {
    isOpen: isLogoutReaderOpen,
    onOpen: onLogoutReaderOpen,
    onClose: onLogoutReaderClose,
  } = useDisclosure();

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setHiddenColumns,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      // initialState: { pageIndex: 2, hiddenColumns: hiddenTableColumns },
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
    },
    usePagination
  );

  useEffect(() => {
    gotoPage(0);
  }, [filterEntity, gotoPage]);

  useEffect(() => {
    if (isLargerThan2XL) {
      setHiddenColumns(['moreInfo']);
    } else if (isLargerThanXL) {
      setHiddenColumns(['moreInfo']);
    } else if (isLargerThanLG) {
      setHiddenColumns(['uuid', 'lastSeenAt']);
    } else if (isLargerThanMD) {
      setHiddenColumns(['uuid', 'lastSeenAt']);
    } else if (isLargerThanSM) {
      setHiddenColumns(['uuid', 'actionZero', 'lastSeenAt']);
    } else if (isLargerThanS) {
      setHiddenColumns(['uuid', 'type', 'actionZero', 'lastSeenAt']);
    } else if (isLargerThanXS) {
      setHiddenColumns([
        'uuid',
        'type',
        'actionZero',
        'actionFirst',
        'lastSeenAt',
      ]);
    } else {
      setHiddenColumns([
        'name',
        'uuid',
        'type',
        'actionZero',
        'actionFirst',
        'lastSeenAt',
      ]);
    }
  }, [
    isLargerThan2XL,
    isLargerThanXL,
    isLargerThanLG,
    isLargerThanMD,
    isLargerThanSM,
    isLargerThanS,
    setHiddenColumns,
    isLargerThanXS,
  ]);

  // Listen for changes in pagination and use the state to fetch our new data
  useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  // reset the page index to 0 when the table data updates due to something other than internal table state changes
  useEffect(() => {
    gotoPage(0);
  }, [gotoPage, readerListCtx.clearPageIndex]);

  // clear our ref when the data is loaded, after we perform any side effects
  useEffect(() => {
    tableStateUpdateRef.current = false;
  }, [data]);

  function handleEditClick(e, row) {
    e.stopPropagation();
    navigate(`/readers/edit/${row.original.uuid}`);
  }

  function handleRowClick(row) {
    navigate(row.original.exampleForNestedObject.readerSinglePageUrl);
  }

  if (loading) {
    return (
      <Spinner
        width="52px"
        height="52px"
        thickness="4px"
        speed="0.65s"
        emptyColor="quinaryBackground"
        mt={4}
        mx="auto"
      />
    );
  }

  if (data.length === 0 && !loading && query === '') {
    return (
      <EmptyState
        iconSvgAnimated={modeReadersIcon}
        heading="No readers have been added yet."
        text="Click on the button below and start adding Readers."
      >
        <Button
          alt="Add reader"
          size="sm"
          as={RouterLink}
          to="/readers/create"
          onClick={() => {
            // reset for add reader
            redirectCtx.updateAddReaderFromPassState(false);
            redirectCtx.updateAddReaderFromEditPassState(false);
          }}
        >
          Add reader
        </Button>
      </EmptyState>
    );
  } else if (data.length === 0 && !loading && query !== '') {
    return (
      <EmptyState
        iconSvgAnimated={modeReadersIcon}
        heading="No results found."
        text="Try adjusting your filters to find the readers that you are looking for."
      />
    );
  }

  return (
    <>
      <TableBox>
        <Heading size="lg" mb="16px">
          Pass readers{' '}
          <Text
            as="span"
            textStyle="headingFamilyMedium"
            fontSize="18px"
            fontWeight="500"
          >
            (
            <Box as="span" color="brand">
              {total}
              {/* {data.length} */}
            </Box>
            )
          </Text>
        </Heading>
        <Table {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <Th
                      {...column.getHeaderProps()}
                      {...column.getToggleHiddenProps()}
                      isNumeric={column.isNumeric}
                    >
                      {column.id === 'lastSeenAt' ? (
                        <Tooltip
                          label="Note: The 'Last seen at' status is currently only available for PassEntry readers."
                          hasArrow
                          placement="top"
                          fontSize="12px"
                          bg={bgTooltip}
                          color={textTooltip}
                          w="205px"
                          borderRadius="6px"
                          textAlign="center"
                          p="10px"
                        >
                          <Box mb={1} cursor="pointer">
                            {column.render('Header')}
                            <CustomExclamationMarkInverseIcon
                              boxSize="12px"
                              mode={'light'}
                              ml={1}
                            />
                          </Box>
                        </Tooltip>
                      ) : (
                        column.render('Header')
                      )}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>

          {data.length > 0 && (
            <Tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <Tr
                    {...row.getRowProps()}
                    onClick={() => handleRowClick(row)}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <Td
                          {...cell.getCellProps([
                            {
                              className: cell.column.className,
                              style: cell.column.style,
                            },
                            getColumnProps(cell.column),
                            getCellProps(cell),
                          ])}
                          isNumeric={cell.column.isNumeric}
                          textAlign={
                            (cell.column.id === 'actionZero' ||
                              cell.column.id === 'actionFirst') &&
                            'right'
                          }
                        >
                          {cell.column.id === 'name' ? (
                            <Box>
                              <HStack spacing={3}>
                                <Hide below="md">
                                  <Box>
                                    <Image
                                      src={modeReadersIconSrc}
                                      maxWidth="50px"
                                    />
                                  </Box>
                                </Hide>
                                <Text>{cell.value}</Text>
                              </HStack>
                            </Box>
                          ) : cell.column.id === 'status' ? (
                            <ReadersRenderStatus
                              loggedIn={cell.row.original.attributes.loggedIn}
                            />
                          ) : cell.column.id === 'lastSeenAt' ? (
                            <Box>
                              <Text>
                                {cell.value ? moment(cell.value).fromNow() : ''}
                              </Text>
                            </Box>
                          ) : cell.column.id === 'actionZero' &&
                            !!row.original.attributes.loggedIn ? (
                            // <Button variant="link" pt="5px">
                            //   <Flex justifyContent="center" alignItems="center">
                            //     {/* <Show below="sm">
                            //       <CustomVisibilityIcon
                            //         className={colorMode === 'dark' && 'multicolorDarkBg'}
                            //       />
                            //     </Show> */}
                            //     <Show above="xl">
                            //       <CustomVisibilityIcon
                            //         className={colorMode === 'dark' && 'multicolorDarkBg'}
                            //       />
                            //     </Show>
                            //     <Text
                            //       textStyle="headingFamilyMedium"
                            //       fontWeight="500"
                            //       pl="7px"
                            //       pt="1px"
                            //     >
                            //       View
                            //     </Text>
                            //   </Flex>
                            // </Button>
                            <Button
                              variant="secondary"
                              size="sm"
                              px="19px"
                              onClick={(e) => {
                                e.stopPropagation();
                                setReaderToLogOut(row.original.uuid);
                                onLogoutReaderOpen();
                              }}
                            >
                              <HStack zIndex={1}>
                                <Show below="sm">
                                  <CustomLogoutMultiIcon
                                    boxSize="17px"
                                    className="sideNavIcon"
                                  />
                                </Show>
                                <Show above="xl">
                                  <CustomLogoutMultiIcon
                                    boxSize="17px"
                                    className="sideNavIcon"
                                  />
                                </Show>
                                <Hide below="sm">
                                  <Text
                                    textStyle="headingFamilyMedium"
                                    fontWeight="500"
                                    lineHeight="28px"
                                    textTransform="none"
                                  >
                                    Log out
                                  </Text>
                                </Hide>
                              </HStack>
                            </Button>
                          ) : cell.column.id === 'actionFirst' ? (
                            <Button
                              size="sm"
                              px={{ base: '14px', md: '19px' }}
                              onClick={(e) => {
                                handleEditClick(e, row);
                              }}
                            >
                              <HStack zIndex={1}>
                                <Show below="md">
                                  <CustomEditIcon
                                    boxSize="20px"
                                    color1="#fff"
                                  />
                                </Show>
                                <Show above="xl">
                                  <CustomEditIcon
                                    boxSize="20px"
                                    color1="#fff"
                                  />
                                </Show>
                                <Hide below="md">
                                  <Text
                                    textStyle="headingFamilyMedium"
                                    fontWeight="500"
                                    lineHeight="28px"
                                    textTransform="none"
                                  >
                                    Edit reader
                                  </Text>
                                </Hide>
                              </HStack>
                            </Button>
                          ) : // : cell.column.id === 'actionSecond' ? (
                          //   <Box onClick={(e) => handleDeleteClick(row, e)}>
                          //     <HStack justifyContent="flex-end">
                          //       <Box className="sideNavSquare">
                          //         <CustomTrashcanMultiIcon
                          //           className={colorMode === 'dark' && 'multicolorDarkBg'}
                          //         />
                          //       </Box>
                          //       <Hide below="md">
                          //         <Text as="span" color="brand" pt="5px">
                          //           Delete
                          //         </Text>
                          //       </Hide>
                          //     </HStack>
                          //   </Box>
                          // )
                          cell.column.id === 'moreInfo' ? (
                            <ReadersListMoreInfo cell={cell}>
                              <ReadersRenderStatus
                                loggedIn={cell.row.original.attributes.loggedIn}
                              />
                            </ReadersListMoreInfo>
                          ) : cell.value !== null ? (
                            cell.render('Cell')
                          ) : (
                            <Text as="span" color="secondaryDark06">
                              N/D
                            </Text>
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>

        {data.length > 0 && (
          <Flex
            mt={6}
            flexDirection={{ base: 'column-reverse', '2xl': 'row' }}
            justifyContent={{ base: 'none', '2xl': 'space-between' }}
          >
            <Stack
              spacing="18px"
              direction={{ base: 'column', sm: 'row' }}
              // mt={{ base: '24px', '2xl': '0' }}
              shouldWrapChildren="true"
            ></Stack>

            <ReactTablePagination
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageOptions={pageOptions}
              pageCount={pageCount}
              gotoPage={gotoPage}
              nextPage={nextPage}
              previousPage={previousPage}
              setPageSize={setPageSize}
              pageIndex={pageIndex}
              pageSize={pageSize}
            />
          </Flex>
        )}
      </TableBox>
      <LogoutReaderModal
        isOpen={isLogoutReaderOpen}
        onClose={onLogoutReaderClose}
        readerId={readerToLogOut}
      />
    </>
  );
}
