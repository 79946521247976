import {
  Button,
  Center,
  Checkbox,
  FormControl,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

const MessagingTermsModal = ({ onClose, isOpen }) => {
  const modeBoxBg = useColorModeValue('white', 'secondaryDark');
  const modeTextBg = useColorModeValue('white', 'primaryDark06');
  const modeLabelBg = useColorModeValue('white', 'primaryDark03');
  const [confirmation, setConfirmation] = useState('');

  const {
    reset,
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setConfirmation(isChecked ? 'confirmed' : '');
  };

  const handleModalClose = () => {
    reset();
    onClose();
  };

  const onSubmit = () => {
    if (confirmation === 'confirmed') {
      reset();
      onClose();
    }
  };

  return (
    <>
      <Modal
        onClose={handleModalClose}
        isOpen={isOpen}
        size="xl"
        closeOnOverlayClick={false}
      >
        <ModalOverlay data-testid="modal-overlay" />
        <ModalContent bg={modeBoxBg} p="20px" mt={{ base: '30vh' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader bg={modeTextBg} pb="20px" borderRadius="15px 15px 0 0">
              <Heading
                fontSize={{ base: '24px', lg: '32px' }}
                lineHeight="130%"
                textAlign="center"
              >
                Terms and Conditions
              </Heading>
            </ModalHeader>
            <ModalBody bg={modeLabelBg}>
              <VStack spacing="10px" textAlign="center">
                <Text
                  fontSize="15px"
                  textStyle="bodyBold"
                  color="secondaryDark"
                >
                  By checking the box below, you agree PassEntry will not be
                  held responsible for any misuse of this feature.
                </Text>
                <FormControl mt="12px">
                  <Center>
                    <Checkbox
                      id="confirmation"
                      {...register('confirmation')}
                      onChange={handleCheckboxChange}
                    >
                      <Text
                        textStyle="bodyRegular"
                        color="secondaryDark"
                        fontSize="15px"
                      >
                        I agree to the terms and conditions
                      </Text>
                    </Checkbox>
                  </Center>
                </FormControl>
              </VStack>
            </ModalBody>
            <ModalFooter bg={modeTextBg} borderRadius="0 0 15px 15px">
              <Stack
                mt="15px"
                spacing="10px"
                direction={{ base: 'column', sm: 'row' }}
                width={{ base: 'full', sm: 'auto' }}
              >
                <Button
                  type="submit"
                  alt="Delete Reader"
                  width={{ base: 'full', sm: 'auto' }}
                  isLoading={isSubmitting}
                  isDisabled={!confirmation}
                >
                  Continue
                </Button>
              </Stack>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MessagingTermsModal;
