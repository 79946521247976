import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Show,
  Hide,
  Text,
  useColorModeValue,
  Tooltip,
  Link,
  HStack,
  Heading,
  Collapse,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import PassTemplateContext from '../../../store/client/PassTemplateContext';
import BannerImage from './BannerImage';
import { NUMBER_KEYS } from '../../../utils/consts';
import {
  CustomReactSelect,
  formatOptionLabelSelectPassType,
} from '../../common/CustomReactSelect';
import { CustomEditIcon } from '../../../theme/icons/CustomEditIcon';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { MeContext } from '../../../context/MeContext';

function StampDetails(props) {
  const {
    isEdit,
    activeStep,
    editedImages,
    setEditedImages,
    editedFields,
    setEditedFields,
    stampValuesError,
  } = props;
  const passTemplateCtx = useContext(PassTemplateContext);
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const modeFormControlBg = useColorModeValue('white', '');
  const modeLabelBg = useColorModeValue('#EFEFEF', 'primaryDark03');
  const modeHelperText = useColorModeValue('secondaryDark08', '#DDDDDD');
  const bgTooltip = useColorModeValue('secondaryDark', '#dddddd');
  const textTooltip = useColorModeValue('#ffffff', 'primaryDark');
  const [watermarkFieldsDisabled, setWatermarkFieldsDisabled] = useState(false);
  const [watermarkFieldsOpen, setWatermarkFieldsOpen] = useState(false);
  const meCtx = useContext(MeContext);
  const { watermarkEnabled } = meCtx.state;

  const watermarkColorOptions = [
    {
      label: 'Light',
      value: 'light',
    },
    {
      label: 'Dark',
      value: 'dark',
    },
  ];

  const watermarkPositionOptions = [
    {
      label: 'Top left',
      value: 'topLeft',
    },
    {
      label: 'Top right',
      value: 'topRight',
    },
    {
      label: 'Bottom left',
      value: 'bottomLeft',
    },
    {
      label: 'Bottom right',
      value: 'bottomRight',
    },
  ];

  useEffect(() => {
    let lowerCaseNumber = NUMBER_KEYS[activeStep || 0].toLowerCase();
    if (isEdit) {
      if (!editedImages[`stamp_${lowerCaseNumber}_image`]) {
        setWatermarkFieldsDisabled(true);
        passTemplateCtx.updateDisplayBannerWatermarkPreview(false);
      } else {
        setWatermarkFieldsDisabled(false);
        passTemplateCtx.updateDisplayBannerWatermarkPreview(true);
      }
    }
  }, [isEdit, activeStep, editedImages]);

  useEffect(() => {
    setWatermarkFieldsOpen(false);
  }, [activeStep]);

  return (
    <>
      <FormControl isRequired bg={modeFormControlBg}>
        <Flex>
          <Hide below="2xl">
            <Box pl="24px" flexShrink={0} w="240px">
              <FormLabel m={0} pt="18px">
                Banner image
              </FormLabel>
              <Text
                color={modeHelperText}
                textStyle="bodyFamilyRegular"
                fontSize="12px"
              >
                {activeStep === 0
                  ? 'Unstamped'
                  : `${activeStep} completed stamp${
                      activeStep === 1 ? '' : 's'
                    }`}
              </Text>
            </Box>
          </Hide>
          <Box p="14px" w="full" bg={modeLabelBg}>
            <Show below="2xl">
              <FormLabel>Banner image</FormLabel>
              <Text
                color={modeHelperText}
                textStyle="bodyFamilyRegular"
                fontSize="12px"
                mb={2}
              >
                {activeStep === 0
                  ? 'Unstamped'
                  : `${activeStep} completed stamp${
                      activeStep === 1 ? '' : 's'
                    }`}
              </Text>
            </Show>
            <BannerImage
              editedFields={editedFields}
              setEditedFields={setEditedFields}
              editedImages={editedImages}
              setEditedImages={setEditedImages}
              type={'stamp'}
              isEdit={isEdit}
              setWatermarkFieldsDisabled={setWatermarkFieldsDisabled}
            />
            {/*<CustomDropzone*/}
            {/*  parent="banner_image"*/}
            {/*  explanation="Recommended size: 1097x288 pixels. Recommended file type is jpg."*/}
            {/*/>*/}
          </Box>
        </Flex>
      </FormControl>
      <Divider borderColor={modeBorderColor} />
      {watermarkEnabled && (
        <Box>
          {!watermarkFieldsOpen ? (
            <>
              <FormControl isRequired={true} bg={modeFormControlBg}>
                <Flex alignItems="center">
                  <Hide below="2xl">
                    <Box pl="24px" flexShrink={0} w="240px">
                      <FormLabel m={0}>PassEntry watermark</FormLabel>
                    </Box>
                  </Hide>
                  <Box p="14px" w="full" bg={modeLabelBg}>
                    <Show below="2xl">
                      <FormLabel>PassEntry watermark</FormLabel>
                    </Show>
                    <Flex
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      onClick={() =>
                        setWatermarkFieldsOpen(!watermarkFieldsOpen)
                      }
                      cursor="pointer"
                      pr={3}
                    >
                      <>
                        <Box
                          as={Link}
                          onClick={() =>
                            setWatermarkFieldsOpen(!watermarkFieldsOpen)
                          }
                          cursor="pointer"
                        >
                          <HStack zIndex={1} padding="10px">
                            <>
                              <>
                                <CustomEditIcon color="brand" boxSize="14px" />
                                <Heading
                                  color="secondaryDark"
                                  fontSize="14px"
                                  top="1px"
                                  pos="relative"
                                >
                                  Edit
                                </Heading>
                              </>
                            </>
                          </HStack>
                        </Box>
                        <ChevronDownIcon color="secondaryDark" boxSize="20px" />
                      </>
                    </Flex>
                  </Box>
                </Flex>
              </FormControl>
              <Divider borderColor={modeBorderColor} />
            </>
          ) : (
            <FormControl isRequired={true} bg={modeFormControlBg}>
              <Flex alignItems="center">
                <Hide below="2xl">
                  <Box pl="24px" flexShrink={0} w="240px">
                    <FormLabel pt="18px">PassEntry watermark</FormLabel>
                  </Box>
                </Hide>
                <Box p="14px" w="full" bg={modeLabelBg}>
                  <Show below="2xl">
                    <Flex
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      onClick={() =>
                        setWatermarkFieldsOpen(!watermarkFieldsOpen)
                      }
                      cursor="pointer"
                      pr={3}
                    >
                      <ChevronUpIcon color="gray" boxSize="20px" />
                    </Flex>
                  </Show>
                  <Hide below="2xl">
                    <Flex
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      onClick={() =>
                        setWatermarkFieldsOpen(!watermarkFieldsOpen)
                      }
                      cursor="pointer"
                      pr={3}
                    >
                      <ChevronUpIcon color="gray" boxSize="20px" />
                    </Flex>
                  </Hide>
                </Box>
              </Flex>
            </FormControl>
          )}
          <Collapse in={watermarkFieldsOpen} animateOpacity>
            <FormControl
              isRequired={true}
              bg={modeFormControlBg}
              isDisabled={watermarkFieldsDisabled}
            >
              <Flex>
                <Hide below="2xl">
                  <Box pl="24px" flexShrink={0} w="240px">
                    <FormLabel m={0} pt="18px">
                      Colour
                    </FormLabel>
                  </Box>
                </Hide>
                <Box p="14px" w="full" bg={modeLabelBg}>
                  <Show below="2xl">
                    <FormLabel>Colour</FormLabel>
                  </Show>
                  <Tooltip
                    hidden={!watermarkFieldsDisabled}
                    label={
                      <Text>Upload a new banner image to enable editing</Text>
                    }
                    hasArrow
                    placement="top"
                    fontSize="12px"
                    bg={bgTooltip}
                    color={textTooltip}
                    w="205px"
                    borderRadius="6px"
                    textAlign="center"
                    p="10px"
                  >
                    <Box>
                      <CustomReactSelect
                        isDisabled={watermarkFieldsDisabled}
                        options={watermarkColorOptions}
                        formatOptionLabel={formatOptionLabelSelectPassType}
                        value={
                          passTemplateCtx[
                            `stamp${NUMBER_KEYS[activeStep]}WatermarkColor`
                          ]
                        }
                        variant="filledForDarkBg"
                        id="passType"
                        type="text"
                        placeholder={
                          watermarkColorOptions.find(
                            (color) =>
                              color.value ===
                              passTemplateCtx[
                                `stamp${NUMBER_KEYS[activeStep]}WatermarkColor`
                              ]
                          )?.label
                        }
                        onChange={(e) => {
                          passTemplateCtx[
                            `updateStamp${NUMBER_KEYS[activeStep]}WatermarkColor`
                          ](e.value);
                          if (props.isEdit) {
                            props.setEditedFields({
                              ...props.editedFields,
                              [`stamp_${NUMBER_KEYS[activeStep]}_watermark_color`]:
                                e.value,
                            });
                          }
                        }}
                        autoComplete="off"
                        passType
                      />
                    </Box>
                  </Tooltip>
                </Box>
              </Flex>
            </FormControl>
            <FormControl
              isRequired={true}
              bg={modeFormControlBg}
              isDisabled={watermarkFieldsDisabled}
            >
              <Flex>
                <Hide below="2xl">
                  <Box pl="24px" flexShrink={0} w="240px">
                    <FormLabel m={0} pt="18px">
                      Position
                    </FormLabel>
                  </Box>
                </Hide>
                <Box p="14px" w="full" bg={modeLabelBg}>
                  <Show below="2xl">
                    <FormLabel>Position</FormLabel>
                  </Show>
                  <Tooltip
                    hidden={!watermarkFieldsDisabled}
                    label={
                      <Text>Upload a new banner image to enable editing</Text>
                    }
                    hasArrow
                    placement="top"
                    fontSize="12px"
                    bg={bgTooltip}
                    color={textTooltip}
                    w="205px"
                    borderRadius="6px"
                    textAlign="center"
                    p="10px"
                  >
                    <Box>
                      <CustomReactSelect
                        isDisabled={watermarkFieldsDisabled}
                        options={watermarkPositionOptions}
                        formatOptionLabel={formatOptionLabelSelectPassType}
                        value={
                          passTemplateCtx[
                            `stamp${NUMBER_KEYS[activeStep]}WatermarkPosition`
                          ]
                        }
                        variant="filledForDarkBg"
                        id="passType"
                        type="text"
                        placeholder={
                          watermarkPositionOptions.find(
                            (position) =>
                              position.value ===
                              passTemplateCtx[
                                `stamp${NUMBER_KEYS[activeStep]}WatermarkPosition`
                              ]
                          )?.label
                        }
                        onChange={(e) => {
                          passTemplateCtx[
                            `updateStamp${NUMBER_KEYS[activeStep]}WatermarkPosition`
                          ](e.value);
                          if (props.isEdit) {
                            props.setEditedFields({
                              ...props.editedFields,
                              [`stamp_${NUMBER_KEYS[activeStep]}_watermark_position`]:
                                e.value,
                            });
                          }
                        }}
                        autoComplete="off"
                        passType
                      />
                    </Box>
                  </Tooltip>
                </Box>
              </Flex>
            </FormControl>
            <Divider borderColor={modeBorderColor} />
          </Collapse>
        </Box>
      )}

      {!!passTemplateCtx.stampShortKey && (
        <>
          <FormControl
            isRequired
            bg={modeFormControlBg}
            borderRadius="15px 15px 0 0"
          >
            <Flex alignItems="center">
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FormLabel m={0}>{passTemplateCtx.stampShortKey}</FormLabel>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel>{passTemplateCtx.stampShortKey}</FormLabel>
                </Show>
                <Input
                  value={
                    passTemplateCtx[`stamp${NUMBER_KEYS[activeStep]}ShortValue`]
                  }
                  variant="filledForDarkBg"
                  id="stampShortValue"
                  type="text"
                  placeholder={
                    passTemplateCtx.stampCount - activeStep === 0
                      ? 'e.g. All stamps completed!'
                      : `e.g. ${passTemplateCtx.stampCount - activeStep} stamp${
                          passTemplateCtx.stampCount - activeStep === 1
                            ? ''
                            : 's'
                        } to go!`
                  }
                  onChange={(e) => {
                    passTemplateCtx[
                      `updateStamp${NUMBER_KEYS[activeStep]}ShortValue`
                    ](e.target.value);
                    if (isEdit) {
                      setEditedFields({
                        ...editedFields,
                        [`stamp_${NUMBER_KEYS[activeStep]}_short_value`]:
                          e.target.value,
                      });
                    }
                  }}
                  autoComplete="off"
                  isInvalid={
                    !passTemplateCtx[
                      `stamp${NUMBER_KEYS[activeStep]}ShortValue`
                    ] && stampValuesError
                  }
                />
                <FormErrorMessage></FormErrorMessage>
              </Box>
            </Flex>
          </FormControl>
          <Divider borderColor={modeBorderColor} />
        </>
      )}
      {!!passTemplateCtx.stampLongKey && (
        <>
          <FormControl
            isRequired
            bg={modeFormControlBg}
            borderRadius="15px 15px 0 0"
          >
            <Flex alignItems="center">
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FormLabel
                    m={0}
                  >{`${passTemplateCtx.stampLongKey}`}</FormLabel>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel>{`${passTemplateCtx.stampLongKey}`}</FormLabel>
                </Show>
                <Input
                  value={
                    passTemplateCtx[`stamp${NUMBER_KEYS[activeStep]}LongValue`]
                  }
                  variant="filledForDarkBg"
                  id="stampLongValue"
                  type="text"
                  placeholder={
                    passTemplateCtx.stampCount - activeStep === 0
                      ? 'e.g. Congratulations on completing all of your stamps!'
                      : `e.g. Only ${
                          passTemplateCtx.stampCount - activeStep
                        } stamp${
                          passTemplateCtx.stampCount - activeStep === 1
                            ? ''
                            : 's'
                        } to go until you earn your free item!`
                  }
                  onChange={(e) => {
                    passTemplateCtx[
                      `updateStamp${NUMBER_KEYS[activeStep]}LongValue`
                    ](e.target.value);
                    if (isEdit) {
                      setEditedFields({
                        ...editedFields,
                        [`stamp_${NUMBER_KEYS[activeStep]}_long_value`]:
                          e.target.value,
                      });
                    }
                  }}
                  autoComplete="off"
                  isInvalid={
                    !passTemplateCtx[
                      `stamp${NUMBER_KEYS[activeStep]}LongValue`
                    ] && stampValuesError
                  }
                />
                <FormErrorMessage></FormErrorMessage>
              </Box>
            </Flex>
          </FormControl>
          <Divider borderColor={modeBorderColor} />
        </>
      )}
      <FormControl bg={modeFormControlBg} borderRadius="15px 15px 0 0">
        <Flex alignItems="center">
          <Hide below="2xl">
            <Box pl="24px" flexShrink={0} w="240px">
              <FormLabel m={0}>Push notification</FormLabel>
            </Box>
          </Hide>
          <Box p="14px" w="full" bg={modeLabelBg}>
            <Show below="2xl">
              <FormLabel>Push notification</FormLabel>
            </Show>
            <Input
              value={passTemplateCtx[`stamp${NUMBER_KEYS[activeStep]}Message`]}
              variant="filledForDarkBg"
              id="stampMessage"
              type="text"
              placeholder="Type a notification message here..."
              onChange={(e) => {
                passTemplateCtx[`updateStamp${NUMBER_KEYS[activeStep]}Message`](
                  e.target.value
                );
                if (isEdit) {
                  setEditedFields({
                    ...editedFields,
                    [`stamp_${NUMBER_KEYS[activeStep]}_message`]:
                      e.target.value,
                  });
                }
              }}
              autoComplete="off"
            />
            <FormErrorMessage></FormErrorMessage>
          </Box>
        </Flex>
      </FormControl>
    </>
  );
}

export default StampDetails;
