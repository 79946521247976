import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Show,
  Hide,
  Text,
  useColorModeValue,
  Tooltip,
  Stack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  IconButton,
  Button,
  Link,
  HStack,
  Heading,
  Collapse,
  Switch,
} from '@chakra-ui/react';
import { useContext, useEffect, useRef, useState } from 'react';
import PassTemplateContext from '../../../store/client/PassTemplateContext';
import CustomColorPicker from './CustomColorPicker';
import LogoImage from './LogoImage';
import IconLogo from './IconLogo';
import BannerImage from './BannerImage';
import { MeContext } from '../../../context/MeContext';
import {
  CustomReactSelect,
  formatOptionLabelSelectPassType,
} from '../../common/CustomReactSelect';
import ThumbnailImage from './ThumbnailImage';
import MonochromeBadge from './MonochromeBadge';
import { CustomExclamationMarkInverseIcon } from '../../../theme/icons/CustomExclamationMarkInverseIcon';
import placeholderBanner from '../../../assets/images/banner.png';
import { CustomPlusIcon } from '../../../theme/icons/CustomPlusIcon';
import { CustomChevronLeftIcon } from '../../../theme/icons/CustomChevronLeftIcon';
import LocationDetails from './LocationDetails';
import BeaconDetails from './BeaconDetails';
import GoogleBannerImage from './GoogleBannerImage';
import FieldsContainer from './FieldsContainer';
import { CustomEditIcon } from '../../../theme/icons/CustomEditIcon';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

function TemplateDetails(props) {
  const passTemplateCtx = useContext(PassTemplateContext);
  const meCtx = useContext(MeContext);
  const modeBoxBg = useColorModeValue('gradient', 'secondaryDark');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const modeFormControlBg = useColorModeValue('white', '');
  const modeLabelBg = useColorModeValue('#EFEFEF', 'primaryDark03');
  const modeHelperText = useColorModeValue('secondaryDark08', '#DDDDDD');
  const bgTooltip = useColorModeValue('secondaryDark', '#dddddd');
  const textTooltip = useColorModeValue('#ffffff', 'primaryDark');
  const [balanceTooltipOpen, setBalanceTooltipOpen] = useState(false);
  const [appLinkTooltipOpen, setAppLinkTooltipOpen] = useState(false);
  const [iconUrlTooltipOpen, setIconUrlTooltipOpen] = useState(false);
  const [locationsTooltipOpen, setLocationsTooltipOpen] = useState(false);
  const [beaconsTooltipOpen, setBeaconsTooltipOpen] = useState(false);
  const [idTooltipOpen, setIdTooltipOpen] = useState(false);
  const [labelTooltipOpen, setLabelTooltipOpen] = useState(false);
  const [valueTooltipOpen, setValueTooltipOpen] = useState(false);
  const [showCentralLabelValue, setShowCentralLabelValue] = useState(false);
  const modeTextBg = useColorModeValue('quaternaryBackground', 'primaryDark06');
  const modeFormBoxBg = useColorModeValue('gradient', 'secondaryDark');
  const entityList = meCtx.state?.entities;
  const modeStepperColor = useColorModeValue('primaryDark', 'white');
  const modePrevNext = useColorModeValue('secondaryDark08', '#DDDDDD');
  const [activeLocationStep, setActiveLocationStep] = useState(0);
  const [activeBeaconStep, setActiveBeaconStep] = useState(0);
  const { filterEntity } = meCtx.state;
  const entities = entityList.map((entity) => {
    return { name: `${entity.name}`, value: entity.access_id };
  });
  const [bannerWatermarkFieldDisabled, setBannerWatermarkFieldDisabled] =
    useState(false);
  const [
    googleBannerWatermarkFieldDisabled,
    setGoogleBannerWatermarkFieldDisabled,
  ] = useState(false);
  const [bannerWatermarkFieldOpen, setBannerWatermarkFieldOpen] =
    useState(false);
  const [googleBannerWatermarkFieldOpen, setGoogleBannerWatermarkFieldOpen] =
    useState(false);
  const { watermarkEnabled } = meCtx.state;

  // Pass type options for Apple
  const passTypes = [
    {
      label: 'Generic pass',
      value: 'generic',
    },
    {
      label: 'Loyalty pass',
      value: 'storeCard',
    },
    {
      label: 'Event ticket',
      value: 'eventTicket',
    },
    {
      label: 'Loyalty Stamp Card',
      value: 'stampCard',
    },
    // {
    //   label: 'Coupon',
    //   value: 'coupon',
    // },
    // {
    //   label: 'Boarding Pass',
    //   value: 'boarding_pass',
    // },
  ];

  const watermarkColorOptions = [
    {
      label: 'Light',
      value: 'light',
    },
    {
      label: 'Dark',
      value: 'dark',
    },
  ];

  const watermarkPositionOptions = [
    {
      label: 'Top left',
      value: 'topLeft',
    },
    {
      label: 'Top right',
      value: 'topRight',
    },
    {
      label: 'Bottom left',
      value: 'bottomLeft',
    },
    {
      label: 'Bottom right',
      value: 'bottomRight',
    },
  ];

  const tooltipRef = useRef();

  const showTooltipHandler = (e, tooltip) => {
    e.preventDefault();

    const tooltipToggles = {
      balance: () => setBalanceTooltipOpen((prev) => !prev),
      locations: () => setLocationsTooltipOpen((prev) => !prev),
      beacons: () => setBeaconsTooltipOpen((prev) => !prev),
      iconImageUrl: () => setIconUrlTooltipOpen((prev) => !prev),
      appLink: () => setAppLinkTooltipOpen((prev) => !prev),
      id: () => setIdTooltipOpen((prev) => !prev),
      label: () => setLabelTooltipOpen((prev) => !prev),
      value: () => setValueTooltipOpen((prev) => !prev),
    };

    // Check if the tooltip type exists in the dictionary and call the function
    if (tooltipToggles[tooltip]) {
      tooltipToggles[tooltip]();
    }
  };

  const handleLocationCountChange = () => {
    if (passTemplateCtx.locationCount) {
      setActiveLocationStep(passTemplateCtx.locationCount);
      passTemplateCtx.updateLocationCount(passTemplateCtx.locationCount + 1);
      passTemplateCtx.updateLocations((prev) => [
        ...prev,
        {
          name: '',
          address: '',
          latitude: '',
          longitude: '',
          radius: 100,
          message: '',
        },
      ]);
    } else {
      passTemplateCtx.updateLocationCount(1);
      setActiveLocationStep(0);
      passTemplateCtx.updateLocations([
        {
          name: '',
          address: '',
          latitude: '',
          longitude: '',
          radius: 100,
          message: '',
        },
      ]);
    }
  };

  const handleBeaconCountChange = () => {
    if (passTemplateCtx.beaconCount) {
      setActiveBeaconStep(passTemplateCtx.beaconCount);
      passTemplateCtx.updateBeaconCount(passTemplateCtx.beaconCount + 1);
    } else {
      passTemplateCtx.updateBeaconCount(1);
      setActiveBeaconStep(0);
    }
    passTemplateCtx.updateBeacons((prev) => [
      ...prev,
      {
        name: '',
        proximity_uuid: '',
        major: '',
        minor: '',
        message: '',
      },
    ]);
  };

  useEffect(() => {
    // Set template entity
    const entity = filterEntity || entities[0];
    passTemplateCtx.updateTemplateEntity(entity.value);
    passTemplateCtx.updateNotificationHeader(entity.name);
    passTemplateCtx.updateDescription(entity.name);
  }, [filterEntity]);

  useEffect(() => {
    if (!!passTemplateCtx && !props.isEdit) {
      if (passTemplateCtx.passType === 'generic') {
        if (!passTemplateCtx.primaryOneLabel) {
          passTemplateCtx.updatePrimaryOneLabel('Central label');
          passTemplateCtx.updatePrimaryOneId('centralLabel');
        }
      } else {
        passTemplateCtx.updatePrimaryOneId('');
        passTemplateCtx.updatePrimaryOneLabel('');
      }
    }
  }, [passTemplateCtx.passType]);

  useEffect(() => {
    if (passTemplateCtx.primaryOneLabel) {
      setShowCentralLabelValue(true);
    } else {
      setShowCentralLabelValue(false);
    }
  }, [passTemplateCtx.primaryOneLabel]);

  useEffect(() => {
    if (
      passTemplateCtx.passType !== 'generic' &&
      !passTemplateCtx.bannerImage
    ) {
      passTemplateCtx.resetBannerLogo();
      passTemplateCtx.updateBannerImage(placeholderBanner);
    }
  }, [passTemplateCtx.passType]);

  useEffect(() => {
    if (
      passTemplateCtx.passType === 'generic' &&
      !!passTemplateCtx.googleBannerImage
    ) {
      passTemplateCtx.resetGoogleBannerLogo();
      passTemplateCtx.updateGoogleBannerImage('');
    }
  }, [passTemplateCtx.passType]);

  useEffect(() => {
    passTemplateCtx.updateLocationCount(passTemplateCtx.locations.length);
  }, [passTemplateCtx.locations.length]);

  useEffect(() => {
    passTemplateCtx.updateBeaconCount(passTemplateCtx.beacons.length);
  }, [passTemplateCtx.beacons.length]);

  useEffect(() => {
    if (passTemplateCtx.locationCount) {
      if (activeLocationStep + 1 > passTemplateCtx.locationCount) {
        setActiveLocationStep(passTemplateCtx.locationCount - 1);
      }
    }
  }, [passTemplateCtx.locationCount]);

  useEffect(() => {
    if (props.isEdit) {
      passTemplateCtx.updateLocationCount(passTemplateCtx.locations.length);
    }
  }, [passTemplateCtx.locations]);

  useEffect(() => {
    if (passTemplateCtx.beaconCount) {
      if (activeBeaconStep + 1 > passTemplateCtx.beaconCount) {
        setActiveBeaconStep(passTemplateCtx.beaconCount - 1);
      }
    }
  }, [passTemplateCtx.beaconCount]);

  useEffect(() => {
    if (props.isEdit) {
      passTemplateCtx.updateBeaconCount(passTemplateCtx.beacons.length);
    }
  }, [passTemplateCtx.beacons]);

  useEffect(() => {
    if (props.isEdit && passTemplateCtx.passType !== 'stampCard') {
      setBannerWatermarkFieldDisabled(true);
      setGoogleBannerWatermarkFieldDisabled(true);
      passTemplateCtx.updateDisplayBannerWatermarkPreview(false);
      passTemplateCtx.updateDisplayGoogleBannerWatermarkPreview(false);
    }
  }, [props.isEdit]);

  useEffect(() => {
    if (props.isEdit && passTemplateCtx.passType !== 'stampCard') {
      if (
        passTemplateCtx.googleBannerImage &&
        !props.editedImages.google_banner_image
      ) {
        setGoogleBannerWatermarkFieldDisabled(true);
      } else {
        setGoogleBannerWatermarkFieldDisabled(false);
      }
    }
  }, [passTemplateCtx.googleBannerImage]);

  return (
    <>
      <Box className="autofillForDarkBg" borderRadius="15px">
        <Box
          className="primaryBoxShadow"
          bg={modeBoxBg}
          borderRadius="15px"
          overflow="hidden"
        >
          <FormControl
            isInvalid={props.templateNameError}
            bg={modeFormControlBg}
            borderRadius="15px 15px 0 0"
          >
            <Flex alignItems="center">
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FormLabel m={0}>
                    <span>Template name</span>
                    <span style={{ color: 'red' }}>*</span>
                  </FormLabel>
                </Box>
              </Hide>
              <Box
                p="14px"
                w="full"
                bg={modeLabelBg}
                borderRadius={{ base: '15px 15px 0 0', '2xl': '0 15px 0 0' }}
              >
                <Show below="2xl">
                  <FormLabel>Template name</FormLabel>
                </Show>
                <Input
                  value={props.templateName}
                  variant="filledForDarkBg"
                  id="templateName"
                  type="text"
                  placeholder="Type template name here..."
                  onChange={(e) => {
                    props.setTemplateName(e.target.value);
                    if (props.isEdit) {
                      props.setEditedFields({
                        ...props.editedFields,
                        name: e.target.value,
                      });
                    }
                  }}
                  onBlur={() => {
                    const value = props.templateName;
                    if (value.trim() === '') {
                      props.setTemplateName('');
                      if (props.isEdit) {
                        props.setEditedFields({
                          ...props.editedFields,
                          name: '',
                        });
                      }
                    }
                  }}
                  autoComplete="off"
                  maxLength={30}
                />
                <FormErrorMessage
                  style={{
                    fontSize: '12px',
                    paddingLeft: '22px',
                    paddingTop: '10px',
                    color: '#E53E3E',
                  }}
                >
                  {props.templateNameError}
                </FormErrorMessage>
              </Box>
            </Flex>
          </FormControl>
          <Divider borderColor={modeBorderColor} />
          {!props.isEdit && (
            <>
              <FormControl
                isDisabled={!!props.eventUuid}
                isRequired
                bg={modeFormControlBg}
                borderRadius="15px 15px 0 0"
              >
                <Flex alignItems="center">
                  <Hide below="2xl">
                    <Box pl="24px" flexShrink={0} w="240px">
                      <FormLabel m={0}>Pass type</FormLabel>
                    </Box>
                  </Hide>
                  <Box p="14px" w="full" bg={modeLabelBg}>
                    <Show below="2xl">
                      <FormLabel>Pass type</FormLabel>
                    </Show>
                    <CustomReactSelect
                      isDisabled={!!props.eventUuid}
                      options={passTypes}
                      formatOptionLabel={formatOptionLabelSelectPassType}
                      value={passTemplateCtx.passType}
                      variant="filledForDarkBg"
                      id="passType"
                      type="text"
                      placeholder={
                        passTypes.find(
                          (type) => type.value === passTemplateCtx.passType
                        )?.label
                      }
                      onChange={(e) => passTemplateCtx.updatePassType(e.value)}
                      autoComplete="off"
                      passType
                    />
                    <FormErrorMessage></FormErrorMessage>
                  </Box>
                </Flex>
              </FormControl>
              <Divider borderColor={modeBorderColor} />
            </>
          )}
          <FormControl isRequired bg={modeFormControlBg}>
            <Flex>
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FormLabel m={0} pt="18px">
                    Logo image
                  </FormLabel>
                  <Text
                    color={modeHelperText}
                    textStyle="bodyFamilyRegular"
                    fontSize="12px"
                  >
                    iOS version
                  </Text>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel>Logo image</FormLabel>
                  <Text
                    color={modeHelperText}
                    textStyle="bodyFamilyRegular"
                    fontSize="12px"
                  >
                    iOS version
                  </Text>
                </Show>
                <LogoImage
                  editedImages={props.editedImages}
                  setEditedImages={props.setEditedImages}
                />
              </Box>
            </Flex>
          </FormControl>
          {passTemplateCtx.passType === 'generic' && (
            <>
              <Divider borderColor={modeBorderColor} />
              <FormControl bg={modeFormControlBg}>
                <Flex>
                  <Hide below="2xl">
                    <Box pl="24px" flexShrink={0} w="240px">
                      <FormLabel m={0} pt="18px">
                        Thumbnail image
                      </FormLabel>
                      <Text
                        color={modeHelperText}
                        textStyle="bodyFamilyRegular"
                        fontSize="12px"
                      >
                        iOS version
                      </Text>
                    </Box>
                  </Hide>
                  <Box p="14px" w="full" bg={modeLabelBg}>
                    <Show below="2xl">
                      <FormLabel>Thumbnail image</FormLabel>
                      <Text
                        color={modeHelperText}
                        textStyle="bodyFamilyRegular"
                        fontSize="12px"
                      >
                        iOS version
                      </Text>
                    </Show>
                    <ThumbnailImage
                      editedImages={props.editedImages}
                      setEditedImages={props.setEditedImages}
                      removable={true}
                    />
                  </Box>
                </Flex>
              </FormControl>
            </>
          )}
          <Divider borderColor={modeBorderColor} />
          <FormControl isRequired bg={modeFormControlBg}>
            <Flex>
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FormLabel m={0} pt="18px">
                    Icon logo
                  </FormLabel>
                  <Text
                    color={modeHelperText}
                    textStyle="bodyFamilyRegular"
                    fontSize="12px"
                  >
                    Android version
                  </Text>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel>Icon logo</FormLabel>
                  <Text
                    color={modeHelperText}
                    textStyle="bodyFamilyRegular"
                    fontSize="12px"
                  >
                    Android version
                  </Text>
                </Show>
                <IconLogo
                  editedImages={props.editedImages}
                  setEditedImages={props.setEditedImages}
                />
              </Box>
            </Flex>
          </FormControl>
          <Divider borderColor={modeBorderColor} />
          {passTemplateCtx.passType !== 'stampCard' && (
            <>
              <FormControl
                isRequired={
                  passTemplateCtx.passType === 'generic' ? false : true
                }
                bg={modeFormControlBg}
              >
                <Flex>
                  <Hide below="2xl">
                    <Box pl="24px" flexShrink={0} w="240px">
                      <FormLabel m={0} pt="18px">
                        Banner image
                      </FormLabel>
                      {passTemplateCtx.passType === 'generic' && (
                        <Text
                          color={modeHelperText}
                          textStyle="bodyFamilyRegular"
                          fontSize="12px"
                        >
                          Android version
                        </Text>
                      )}
                    </Box>
                  </Hide>
                  <Box p="14px" w="full" bg={modeLabelBg}>
                    <Show below="2xl">
                      <FormLabel>Banner image</FormLabel>
                      {passTemplateCtx.passType === 'generic' && (
                        <Text
                          color={modeHelperText}
                          textStyle="bodyFamilyRegular"
                          fontSize="12px"
                        >
                          Android version
                        </Text>
                      )}
                    </Show>
                    <BannerImage
                      isEdit={props.isEdit}
                      setBannerWatermarkFieldDisabled={
                        setBannerWatermarkFieldDisabled
                      }
                      editedFields={props.editedFields}
                      setEditedFields={props.setEditedFields}
                      editedImages={props.editedImages}
                      setEditedImages={props.setEditedImages}
                      removable={passTemplateCtx.passType === 'generic'}
                      type="banner"
                    />
                  </Box>
                </Flex>
              </FormControl>
              <Divider borderColor={modeBorderColor} />
              {watermarkEnabled &&
                passTemplateCtx.passType !== 'eventTicket' && (
                  <Box>
                    {!bannerWatermarkFieldOpen ? (
                      <>
                        <FormControl isRequired={true} bg={modeFormControlBg}>
                          <Flex alignItems="center">
                            <Hide below="2xl">
                              <Box pl="24px" flexShrink={0} w="240px">
                                <FormLabel m={0}>PassEntry watermark</FormLabel>
                                <Text
                                  color={modeHelperText}
                                  textStyle="bodyFamilyRegular"
                                  fontSize="12px"
                                >
                                  Banner image
                                </Text>
                              </Box>
                            </Hide>
                            <Box p="14px" w="full" bg={modeLabelBg}>
                              <Show below="2xl">
                                <FormLabel>PassEntry watermark</FormLabel>
                                <Text
                                  color={modeHelperText}
                                  textStyle="bodyFamilyRegular"
                                  fontSize="12px"
                                >
                                  Banner image
                                </Text>
                              </Show>
                              <Flex
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                onClick={() =>
                                  setBannerWatermarkFieldOpen(
                                    !bannerWatermarkFieldOpen
                                  )
                                }
                                cursor="pointer"
                                pr={3}
                              >
                                <>
                                  <Box
                                    as={Link}
                                    onClick={() =>
                                      setBannerWatermarkFieldOpen(
                                        !bannerWatermarkFieldOpen
                                      )
                                    }
                                    cursor="pointer"
                                  >
                                    <HStack zIndex={1} padding="10px">
                                      <>
                                        <>
                                          <CustomEditIcon
                                            color="brand"
                                            boxSize="14px"
                                          />
                                          <Heading
                                            color="secondaryDark"
                                            fontSize="14px"
                                            top="1px"
                                            pos="relative"
                                          >
                                            Edit
                                          </Heading>
                                        </>
                                      </>
                                    </HStack>
                                  </Box>
                                  <ChevronDownIcon
                                    color="secondaryDark"
                                    boxSize="20px"
                                  />
                                </>
                              </Flex>
                            </Box>
                          </Flex>
                        </FormControl>
                        <Divider borderColor={modeBorderColor} />
                      </>
                    ) : (
                      <FormControl isRequired={true} bg={modeFormControlBg}>
                        <Flex alignItems="center">
                          <Hide below="2xl">
                            <Box pl="24px" flexShrink={0} w="240px">
                              <FormLabel>PassEntry watermark</FormLabel>
                              <Text
                                color={modeHelperText}
                                textStyle="bodyFamilyRegular"
                                fontSize="12px"
                              >
                                Banner image
                              </Text>
                            </Box>
                          </Hide>
                          <Box p="14px" w="full" bg={modeLabelBg}>
                            <Show below="2xl">
                              <Flex
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                onClick={() =>
                                  setBannerWatermarkFieldOpen(
                                    !bannerWatermarkFieldOpen
                                  )
                                }
                                cursor="pointer"
                                pr={3}
                              >
                                <Box>
                                  <FormLabel>PassEntry watermark</FormLabel>
                                  <Text
                                    color={modeHelperText}
                                    textStyle="bodyFamilyRegular"
                                    fontSize="12px"
                                  >
                                    Banner image
                                  </Text>
                                </Box>
                                <ChevronUpIcon color="gray" boxSize="20px" />
                              </Flex>
                            </Show>
                            <Hide below="2xl">
                              <Flex
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                onClick={() =>
                                  setBannerWatermarkFieldOpen(
                                    !bannerWatermarkFieldOpen
                                  )
                                }
                                cursor="pointer"
                                pr={3}
                              >
                                <ChevronUpIcon color="gray" boxSize="20px" />
                                <Box h="50px" />
                              </Flex>
                            </Hide>
                          </Box>
                        </Flex>
                      </FormControl>
                    )}
                    <Collapse in={bannerWatermarkFieldOpen} animateOpacity>
                      <FormControl
                        isRequired={true}
                        bg={modeFormControlBg}
                        isDisabled={bannerWatermarkFieldDisabled}
                      >
                        <Flex>
                          <Hide below="2xl">
                            <Box pl="24px" flexShrink={0} w="240px">
                              <FormLabel m={0} pt="18px">
                                Colour
                              </FormLabel>
                            </Box>
                          </Hide>
                          <Box p="14px" w="full" bg={modeLabelBg}>
                            <Show below="2xl">
                              <FormLabel>Colour</FormLabel>
                            </Show>
                            <Tooltip
                              hidden={!bannerWatermarkFieldDisabled}
                              label={
                                <Text>
                                  Upload a new banner image to enable editing
                                </Text>
                              }
                              hasArrow
                              placement="top"
                              fontSize="12px"
                              bg={bgTooltip}
                              color={textTooltip}
                              w="205px"
                              borderRadius="6px"
                              textAlign="center"
                              p="10px"
                            >
                              <Box>
                                <CustomReactSelect
                                  isDisabled={bannerWatermarkFieldDisabled}
                                  options={watermarkColorOptions}
                                  formatOptionLabel={
                                    formatOptionLabelSelectPassType
                                  }
                                  value={passTemplateCtx.watermarkColor}
                                  variant="filledForDarkBg"
                                  id="passType"
                                  type="text"
                                  placeholder={
                                    watermarkColorOptions.find(
                                      (color) =>
                                        color.value ===
                                        passTemplateCtx.watermarkColor
                                    )?.label
                                  }
                                  onChange={(e) => {
                                    passTemplateCtx.updateWatermarkColor(
                                      e.value
                                    );
                                    if (props.isEdit) {
                                      props.setEditedFields({
                                        ...props.editedFields,
                                        watermark_color: e.value,
                                      });
                                    }
                                  }}
                                  autoComplete="off"
                                  passType
                                />
                              </Box>
                            </Tooltip>
                          </Box>
                        </Flex>
                      </FormControl>
                      <FormControl
                        isRequired={true}
                        bg={modeFormControlBg}
                        isDisabled={bannerWatermarkFieldDisabled}
                      >
                        <Flex>
                          <Hide below="2xl">
                            <Box pl="24px" flexShrink={0} w="240px">
                              <FormLabel m={0} pt="18px">
                                Position
                              </FormLabel>
                            </Box>
                          </Hide>
                          <Box p="14px" w="full" bg={modeLabelBg}>
                            <Show below="2xl">
                              <FormLabel>Position</FormLabel>
                            </Show>
                            <Tooltip
                              hidden={!bannerWatermarkFieldDisabled}
                              label={
                                <Text>
                                  Upload a new banner image to enable editing
                                </Text>
                              }
                              hasArrow
                              placement="top"
                              fontSize="12px"
                              bg={bgTooltip}
                              color={textTooltip}
                              w="205px"
                              borderRadius="6px"
                              textAlign="center"
                              p="10px"
                            >
                              <Box>
                                <CustomReactSelect
                                  isDisabled={bannerWatermarkFieldDisabled}
                                  options={watermarkPositionOptions}
                                  formatOptionLabel={
                                    formatOptionLabelSelectPassType
                                  }
                                  value={passTemplateCtx.watermarkPosition}
                                  variant="filledForDarkBg"
                                  id="passType"
                                  type="text"
                                  placeholder={
                                    watermarkPositionOptions.find(
                                      (position) =>
                                        position.value ===
                                        passTemplateCtx.watermarkPosition
                                    )?.label
                                  }
                                  onChange={(e) => {
                                    passTemplateCtx.updateWatermarkPosition(
                                      e.value
                                    );
                                    if (props.isEdit) {
                                      props.setEditedFields({
                                        ...props.editedFields,
                                        watermark_position: e.value,
                                      });
                                    }
                                  }}
                                  autoComplete="off"
                                  passType
                                />
                              </Box>
                            </Tooltip>
                          </Box>
                        </Flex>
                      </FormControl>
                      <Divider borderColor={modeBorderColor} />
                    </Collapse>
                  </Box>
                )}
              {passTemplateCtx.passType !== 'generic' && (
                <>
                  <FormControl isRequired={false} bg={modeFormControlBg}>
                    <Flex>
                      <Hide below="2xl">
                        <Box pl="24px" flexShrink={0} w="240px">
                          <FormLabel m={0} pt="18px">
                            Google banner image
                          </FormLabel>
                          <Text
                            color={modeHelperText}
                            textStyle="bodyFamilyRegular"
                            fontSize="12px"
                            pb="10px"
                          >
                            Android version
                          </Text>
                        </Box>
                      </Hide>
                      <Box p="14px" w="full" bg={modeLabelBg}>
                        <Show below="2xl">
                          <FormLabel>Google banner image</FormLabel>
                          <Text
                            color={modeHelperText}
                            textStyle="bodyFamilyRegular"
                            fontSize="12px"
                          >
                            Android version
                          </Text>
                        </Show>
                        <GoogleBannerImage
                          isEdit={props.isEdit}
                          editedFields={props.editedFields}
                          setEditedFields={props.setEditedFields}
                          editedImages={props.editedImages}
                          setEditedImages={props.setEditedImages}
                          removable={true}
                          type="banner"
                          setGoogleBannerWatermarkFieldDisabled={
                            setGoogleBannerWatermarkFieldDisabled
                          }
                        />
                      </Box>
                    </Flex>
                  </FormControl>
                  <Divider borderColor={modeBorderColor} />
                  {watermarkEnabled &&
                    passTemplateCtx.googleBannerImage &&
                    passTemplateCtx.passType !== 'eventTicket' && (
                      <Box>
                        {!googleBannerWatermarkFieldOpen ? (
                          <>
                            <FormControl
                              isRequired={true}
                              bg={modeFormControlBg}
                            >
                              <Flex alignItems="center">
                                <Hide below="2xl">
                                  <Box pl="24px" flexShrink={0} w="240px">
                                    <FormLabel m={0}>
                                      PassEntry watermark
                                    </FormLabel>
                                    <Text
                                      color={modeHelperText}
                                      textStyle="bodyFamilyRegular"
                                      fontSize="12px"
                                    >
                                      Google banner image
                                    </Text>
                                  </Box>
                                </Hide>
                                <Box p="14px" w="full" bg={modeLabelBg}>
                                  <Show below="2xl">
                                    <FormLabel>PassEntry watermark</FormLabel>
                                    <Text
                                      color={modeHelperText}
                                      textStyle="bodyFamilyRegular"
                                      fontSize="12px"
                                    >
                                      Google banner image
                                    </Text>
                                  </Show>
                                  <Flex
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    onClick={() =>
                                      setGoogleBannerWatermarkFieldOpen(
                                        !googleBannerWatermarkFieldOpen
                                      )
                                    }
                                    cursor="pointer"
                                    pr={3}
                                  >
                                    <>
                                      <Box
                                        as={Link}
                                        onClick={() =>
                                          setGoogleBannerWatermarkFieldOpen(
                                            !googleBannerWatermarkFieldOpen
                                          )
                                        }
                                        cursor="pointer"
                                      >
                                        <HStack zIndex={1} padding="10px">
                                          <>
                                            <>
                                              <CustomEditIcon
                                                color="brand"
                                                boxSize="14px"
                                              />
                                              <Heading
                                                color="secondaryDark"
                                                fontSize="14px"
                                                top="1px"
                                                pos="relative"
                                              >
                                                Edit
                                              </Heading>
                                            </>
                                          </>
                                        </HStack>
                                      </Box>
                                      <ChevronDownIcon
                                        color="secondaryDark"
                                        boxSize="20px"
                                      />
                                    </>
                                  </Flex>
                                </Box>
                              </Flex>
                            </FormControl>
                            <Divider borderColor={modeBorderColor} />
                          </>
                        ) : (
                          <FormControl isRequired={true} bg={modeFormControlBg}>
                            <Flex alignItems="center">
                              <Hide below="2xl">
                                <Box pl="24px" flexShrink={0} w="240px">
                                  <FormLabel>PassEntry watermark</FormLabel>
                                  <Text
                                    color={modeHelperText}
                                    textStyle="bodyFamilyRegular"
                                    fontSize="12px"
                                  >
                                    Google banner image
                                  </Text>
                                </Box>
                              </Hide>
                              <Box p="14px" w="full" bg={modeLabelBg}>
                                <Show below="2xl">
                                  <Flex
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    onClick={() =>
                                      setGoogleBannerWatermarkFieldOpen(
                                        !googleBannerWatermarkFieldOpen
                                      )
                                    }
                                    cursor="pointer"
                                    pr={3}
                                  >
                                    <Box>
                                      <FormLabel>PassEntry watermark</FormLabel>
                                      <Text
                                        color={modeHelperText}
                                        textStyle="bodyFamilyRegular"
                                        fontSize="12px"
                                      >
                                        Google banner image
                                      </Text>
                                    </Box>
                                    <ChevronUpIcon
                                      color="gray"
                                      boxSize="20px"
                                    />
                                  </Flex>
                                </Show>
                                <Hide below="2xl">
                                  <Flex
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    onClick={() =>
                                      setGoogleBannerWatermarkFieldOpen(
                                        !googleBannerWatermarkFieldOpen
                                      )
                                    }
                                    cursor="pointer"
                                    pr={3}
                                  >
                                    <ChevronUpIcon
                                      color="gray"
                                      boxSize="20px"
                                    />
                                    <Box h="50px" />
                                  </Flex>
                                </Hide>
                              </Box>
                            </Flex>
                          </FormControl>
                        )}
                        <Collapse
                          in={googleBannerWatermarkFieldOpen}
                          animateOpacity
                        >
                          <FormControl
                            isRequired={true}
                            bg={modeFormControlBg}
                            isDisabled={googleBannerWatermarkFieldDisabled}
                          >
                            <Flex>
                              <Hide below="2xl">
                                <Box pl="24px" flexShrink={0} w="240px">
                                  <FormLabel m={0} pt="18px">
                                    Colour
                                  </FormLabel>
                                </Box>
                              </Hide>
                              <Box p="14px" w="full" bg={modeLabelBg}>
                                <Show below="2xl">
                                  <FormLabel>Colour</FormLabel>
                                </Show>
                                <Tooltip
                                  hidden={!googleBannerWatermarkFieldDisabled}
                                  label={
                                    <Text>
                                      Upload a new google banner image to enable
                                      editing
                                    </Text>
                                  }
                                  hasArrow
                                  placement="top"
                                  fontSize="12px"
                                  bg={bgTooltip}
                                  color={textTooltip}
                                  w="205px"
                                  borderRadius="6px"
                                  textAlign="center"
                                  p="10px"
                                >
                                  <Box>
                                    <CustomReactSelect
                                      isDisabled={
                                        googleBannerWatermarkFieldDisabled
                                      }
                                      options={watermarkColorOptions}
                                      formatOptionLabel={
                                        formatOptionLabelSelectPassType
                                      }
                                      value={
                                        passTemplateCtx.googleBannerWatermarkColor
                                      }
                                      variant="filledForDarkBg"
                                      id="passType"
                                      type="text"
                                      placeholder={
                                        watermarkColorOptions.find(
                                          (color) =>
                                            color.value ===
                                            passTemplateCtx.googleBannerWatermarkColor
                                        )?.label
                                      }
                                      onChange={(e) => {
                                        passTemplateCtx.updateGoogleBannerWatermarkColor(
                                          e.value
                                        );
                                        if (props.isEdit) {
                                          props.setEditedFields({
                                            ...props.editedFields,
                                            google_banner_watermark_color:
                                              e.value,
                                          });
                                        }
                                      }}
                                      autoComplete="off"
                                      passType
                                    />
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Flex>
                          </FormControl>
                          <FormControl
                            isRequired={true}
                            bg={modeFormControlBg}
                            isDisabled={googleBannerWatermarkFieldDisabled}
                          >
                            <Flex>
                              <Hide below="2xl">
                                <Box pl="24px" flexShrink={0} w="240px">
                                  <FormLabel m={0} pt="18px">
                                    Position
                                  </FormLabel>
                                </Box>
                              </Hide>
                              <Box p="14px" w="full" bg={modeLabelBg}>
                                <Show below="2xl">
                                  <FormLabel>Position</FormLabel>
                                </Show>
                                <Tooltip
                                  hidden={!googleBannerWatermarkFieldDisabled}
                                  label={
                                    <Text>
                                      Upload a new google banner image to enable
                                      editing
                                    </Text>
                                  }
                                  hasArrow
                                  placement="top"
                                  fontSize="12px"
                                  bg={bgTooltip}
                                  color={textTooltip}
                                  w="205px"
                                  borderRadius="6px"
                                  textAlign="center"
                                  p="10px"
                                >
                                  <Box>
                                    <CustomReactSelect
                                      isDisabled={
                                        googleBannerWatermarkFieldDisabled
                                      }
                                      options={watermarkPositionOptions}
                                      formatOptionLabel={
                                        formatOptionLabelSelectPassType
                                      }
                                      value={
                                        passTemplateCtx.googleBannerWatermarkPosition
                                      }
                                      variant="filledForDarkBg"
                                      id="passType"
                                      type="text"
                                      placeholder={
                                        watermarkPositionOptions.find(
                                          (position) =>
                                            position.value ===
                                            passTemplateCtx.googleBannerWatermarkPosition
                                        )?.label
                                      }
                                      onChange={(e) => {
                                        passTemplateCtx.updateGoogleBannerWatermarkPosition(
                                          e.value
                                        );
                                        if (props.isEdit) {
                                          props.setEditedFields({
                                            ...props.editedFields,
                                            google_banner_watermark_position:
                                              e.value,
                                          });
                                        }
                                      }}
                                      autoComplete="off"
                                      passType
                                    />
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Flex>
                          </FormControl>
                          <Divider borderColor={modeBorderColor} />
                        </Collapse>
                      </Box>
                    )}
                </>
              )}
            </>
          )}
          <FormControl bg={modeFormControlBg}>
            <Flex alignItems="center">
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FormLabel m={0}>Pass background colour</FormLabel>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel>Pass background colour</FormLabel>
                </Show>
                <CustomColorPicker
                  color={passTemplateCtx.passBackgroundColor}
                  onChange={passTemplateCtx.updatePassBackgroundColor}
                  hexError={props.backgroundHexError}
                />
              </Box>
            </Flex>
          </FormControl>
          <Divider borderColor={modeBorderColor} />
          <FormControl bg={modeFormControlBg}>
            <Flex alignItems="center">
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FormLabel m={0}>
                    Title font colour
                    <Text
                      color={modeHelperText}
                      textStyle="bodyFamilyRegular"
                      fontSize="12px"
                    >
                      Available only for iOS
                    </Text>
                  </FormLabel>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel>
                    Title font colour
                    <Text
                      color={modeHelperText}
                      textStyle="bodyFamilyRegular"
                      fontSize="12px"
                    >
                      Available only for iOS
                    </Text>
                  </FormLabel>
                </Show>
                <CustomColorPicker
                  color={passTemplateCtx.titleFontColor}
                  onChange={passTemplateCtx.updateTitleFontColor}
                  hexError={props.titleHexError}
                />
              </Box>
            </Flex>
          </FormControl>
          <Divider borderColor={modeBorderColor} />
          <FormControl bg={modeFormControlBg} borderRadius="0 15px 0 0">
            <Flex alignItems="center">
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FormLabel m={0}>
                    Body font colour
                    <Text
                      color={modeHelperText}
                      textStyle="bodyFamilyRegular"
                      fontSize="12px"
                    >
                      Available only for iOS
                    </Text>
                  </FormLabel>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel>
                    Body font colour
                    <Text
                      color={modeHelperText}
                      textStyle="bodyFamilyRegular"
                      fontSize="12px"
                    >
                      Available only for iOS
                    </Text>
                  </FormLabel>
                </Show>
                <CustomColorPicker
                  color={passTemplateCtx.bodyFontColor}
                  onChange={passTemplateCtx.updateBodyFontColor}
                  hexError={props.bodyHexError}
                />
              </Box>
            </Flex>
          </FormControl>
          <Divider borderColor={modeBorderColor} />
          <FormControl
            isInvalid={props.descriptionError}
            bg={modeFormControlBg}
            isRequired={passTemplateCtx.passType !== 'stampCard'}
            borderRadius="0 15px 0 0"
          >
            <Flex alignItems="center">
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FormLabel m={0}>
                    {passTemplateCtx.passType !== 'stampCard' ? (
                      'Description'
                    ) : (
                      <>
                        <span>Description</span>
                        <span style={{ color: 'red' }}>*</span>
                      </>
                    )}
                  </FormLabel>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel>Description</FormLabel>
                </Show>
                <Input
                  value={passTemplateCtx.description}
                  variant="filledForDarkBg"
                  id="description"
                  type="text"
                  placeholder="Type a description here..."
                  onChange={(e) => {
                    passTemplateCtx.updateDescription(e.target.value);
                    if (props.isEdit) {
                      props.setEditedFields({
                        ...props.editedFields,
                        description: e.target.value,
                      });
                    }
                  }}
                  onBlur={() => {
                    const value = passTemplateCtx.description;
                    if (value.trim() === '') {
                      passTemplateCtx.updateDescription('');
                      if (props.isEdit) {
                        props.setEditedFields({
                          ...props.editedFields,
                          description: '',
                        });
                      }
                    }
                  }}
                  autoComplete="off"
                  maxLength={40}
                />
                <FormErrorMessage
                  style={{
                    fontSize: '12px',
                    paddingLeft: '22px',
                    paddingTop: '10px',
                    color: '#E53E3E',
                  }}
                >
                  {props.descriptionError}
                </FormErrorMessage>
              </Box>
            </Flex>
          </FormControl>
          <Divider borderColor={modeBorderColor} />
          <FormControl
            bg={modeFormControlBg}
            display={
              passTemplateCtx.passType === 'storeCard' ||
              passTemplateCtx.passType === 'stampCard' ||
              passTemplateCtx.passType === 'eventTicket'
                ? ''
                : 'none'
            }
          >
            <Flex alignItems="center">
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FormLabel m={0}>
                    Central title
                    <Text
                      color={modeHelperText}
                      textStyle="bodyFamilyRegular"
                      fontSize="12px"
                    >
                      Android version
                    </Text>
                  </FormLabel>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel>
                    Central title
                    <Text
                      color={modeHelperText}
                      textStyle="bodyFamilyRegular"
                      fontSize="12px"
                    >
                      Android version
                    </Text>
                  </FormLabel>
                </Show>
                <Input
                  value={passTemplateCtx.centralTitle}
                  variant="filledForDarkBg"
                  id="centralTitle"
                  type="text"
                  placeholder="Type a central title here..."
                  onChange={(e) => {
                    passTemplateCtx.updateCentralTitle(e.target.value);
                    if (props.isEdit) {
                      props.setEditedFields({
                        ...props.editedFields,
                        central_title: e.target.value,
                      });
                    }
                  }}
                  onBlur={() => {
                    const value = passTemplateCtx.centralTitle;
                    if (value.trim() === '') {
                      passTemplateCtx.updateCentralTitle('');
                      if (props.isEdit) {
                        props.setEditedFields({
                          ...props.editedFields,
                          central_title: '',
                        });
                      }
                    }
                  }}
                  autoComplete="off"
                  maxLength={40}
                />
                <FormErrorMessage></FormErrorMessage>
              </Box>
            </Flex>
          </FormControl>
          {passTemplateCtx.passType !== 'generic' && (
            <Divider borderColor={modeBorderColor} />
          )}
          {passTemplateCtx.passType === 'storeCard' && (
            <FormControl bg={modeFormControlBg}>
              <Flex alignItems="center">
                <Hide below="2xl">
                  <Box pl="24px" flexShrink={0} w="240px">
                    <FormLabel m={0}>Enable Google NFC</FormLabel>
                  </Box>
                </Hide>
                <Box p="14px" w="full" bg={modeLabelBg}>
                  <Show below="2xl">
                    <FormLabel>Enable Google NFC</FormLabel>
                  </Show>
                  <Flex
                    direction="row"
                    gap={3}
                    alignItems="center"
                    ml={3}
                    py={1.5}
                  >
                    <Switch
                      id="googleNfcEnabled"
                      isChecked={passTemplateCtx.googleNfcEnabled}
                      onChange={() => {
                        passTemplateCtx.updateGoogleNfcEnabled(
                          !passTemplateCtx.googleNfcEnabled
                        );
                        if (props.isEdit) {
                          props.setEditedFields({
                            ...props.editedFields,
                            google_nfc_enabled:
                              !passTemplateCtx.googleNfcEnabled,
                          });
                        }
                      }}
                    />
                  </Flex>
                  <FormErrorMessage></FormErrorMessage>
                </Box>
              </Flex>
            </FormControl>
          )}
        </Box>

        <FieldsContainer
          isEdit={props.isEdit}
          editedFields={props.editedFields}
          setEditedFields={props.setEditedFields}
          showTooltipHandler={showTooltipHandler}
          headerError={props.headerError}
        />

        <Box
          className="autofillForDarkBg"
          borderRadius="15px"
          overflow="hidden"
          w="full"
          mt="20px"
        >
          <Box className="primaryBoxShadow" bg={modeFormBoxBg} w="full">
            <Stack
              p="14px 14px 14px 24px"
              bg={modeTextBg}
              direction="row"
              align="center"
            >
              <Flex direction="row" gap={1} align="center">
                <Text fontSize="18px" textStyle="headingFamilyMedium">
                  App Link
                </Text>
                <Tooltip
                  label={
                    <Text>
                      Display a link to your App Store
                      <Text
                        display="inline"
                        position="relative"
                        top="-1"
                        fontSize="7px"
                      >
                        ®
                      </Text>{' '}
                      app on apple passes and/or to your Google Play Store
                      <Text
                        display="inline"
                        position="relative"
                        top="-1"
                        fontSize="7px"
                      >
                        ®
                      </Text>{' '}
                      or web app on android passes.
                    </Text>
                  }
                  hasArrow
                  placement="top"
                  fontSize="12px"
                  bg={bgTooltip}
                  color={textTooltip}
                  w="205px"
                  borderRadius="6px"
                  textAlign="center"
                  p="10px"
                >
                  <Box
                    onClick={(e) => showTooltipHandler(e, 'appLink')}
                    ref={tooltipRef}
                    mb={1}
                    cursor="pointer"
                  >
                    <CustomExclamationMarkInverseIcon
                      boxSize="14px"
                      mode={'light'}
                    />
                  </Box>
                </Tooltip>
              </Flex>
            </Stack>
            <Divider borderColor={modeBorderColor} />
            <FormControl bg={modeFormControlBg} borderRadius="15px">
              <Flex alignItems="center">
                <Hide below="2xl">
                  <Box pl="24px" flexShrink={0} w="240px">
                    <Flex
                      direction="row"
                      alignContent="center"
                      alignItems="center"
                    >
                      <FormLabel m={0}>
                        App Store
                        <Text
                          display="inline"
                          position="relative"
                          top="-1"
                          fontSize="9px"
                        >
                          ®
                        </Text>{' '}
                        URL
                      </FormLabel>
                    </Flex>
                    <Text
                      color={modeHelperText}
                      textStyle="bodyFamilyRegular"
                      fontSize="12px"
                    >
                      iOS version
                    </Text>
                  </Box>
                </Hide>
                <Box p="14px" w="full" bg={modeLabelBg}>
                  <Show below="2xl">
                    <Flex
                      direction="row"
                      alignContent="center"
                      alignItems="center"
                      gap={1}
                    >
                      <FormLabel m={0}>
                        App Store
                        <Text
                          display="inline"
                          position="relative"
                          top="-1"
                          fontSize="9px"
                        >
                          ®
                        </Text>{' '}
                        URL
                      </FormLabel>
                    </Flex>
                    <Text
                      color={modeHelperText}
                      textStyle="bodyFamilyRegular"
                      fontSize="12px"
                    >
                      iOS version
                    </Text>
                  </Show>
                  <Input
                    mt={2}
                    value={passTemplateCtx.appleAppLink}
                    variant="filledForDarkBg"
                    id="appleAppLink"
                    type="text"
                    placeholder="Type a valid https url here..."
                    onChange={(e) => {
                      passTemplateCtx.updateAppleAppLink(e.target.value);
                      if (props.isEdit) {
                        props.setEditedFields({
                          ...props.editedFields,
                          apple_app_link: e.target.value,
                        });
                      }
                    }}
                    onBlur={() => {
                      const value = passTemplateCtx.appleAppLink;
                      if (value.trim() === '') {
                        passTemplateCtx.updateAppleAppLink('');
                        if (props.isEdit) {
                          props.setEditedFields({
                            ...props.editedFields,
                            apple_app_link: '',
                          });
                        }
                      }
                    }}
                    autoComplete="off"
                  />
                  <FormErrorMessage></FormErrorMessage>
                </Box>
              </Flex>
            </FormControl>
            <>
              <Divider borderColor={modeBorderColor} />
              <FormControl bg={modeFormControlBg} borderRadius="15px">
                <Flex alignItems="center">
                  <Hide below="2xl">
                    <Box pl="24px" flexShrink={0} w="240px">
                      <Flex
                        direction="row"
                        align="center"
                        alignContent="center"
                        alignItems="center"
                        gap={1}
                      >
                        <Flex
                          direction="row"
                          alignContent="center"
                          alignItems="center"
                          gap={1}
                        >
                          <FormLabel m={0}>
                            Google Play Store
                            <Text
                              display="inline"
                              position="relative"
                              top="-1"
                              fontSize="9px"
                            >
                              ®
                            </Text>{' '}
                            app
                          </FormLabel>
                        </Flex>
                      </Flex>
                      <Text
                        color={modeHelperText}
                        textStyle="bodyFamilyRegular"
                        fontSize="12px"
                      >
                        Android version
                      </Text>
                    </Box>
                  </Hide>
                  <Box p="14px" w="full" bg={modeLabelBg}>
                    <Show below="2xl">
                      <Flex
                        direction="row"
                        align="center"
                        alignContent="center"
                        alignItems="center"
                        gap={1}
                      >
                        <Flex
                          direction="row"
                          alignContent="center"
                          alignItems="center"
                          gap={1}
                        >
                          <FormLabel m={0}>
                            Google Play Store
                            <Text
                              display="inline"
                              position="relative"
                              top="-1"
                              fontSize="9px"
                            >
                              ®
                            </Text>{' '}
                            app
                          </FormLabel>
                        </Flex>
                      </Flex>
                      <Text
                        color={modeHelperText}
                        textStyle="bodyFamilyRegular"
                        fontSize="12px"
                      >
                        Android version
                      </Text>
                    </Show>
                    <Box pt={3}>
                      <Flex direction="row" w="full" px={{ base: 0, '2xl': 2 }}>
                        <Text
                          color={modeHelperText}
                          textStyle="bodyFamilyRegular"
                          fontSize="12px"
                        >
                          Enter the URL or package name of your app on the
                          Google Play Store
                          <Text
                            display="inline"
                            position="relative"
                            top="-1"
                            fontSize="8px"
                          >
                            ®
                          </Text>
                        </Text>
                      </Flex>
                    </Box>
                    <FormErrorMessage></FormErrorMessage>
                  </Box>
                </Flex>
              </FormControl>
              <FormControl bg={modeFormControlBg} borderRadius="15px">
                <Flex alignItems="center">
                  <Hide below="2xl">
                    <Box pl="24px" flexShrink={0} w="240px">
                      <FormLabel m={0}>URL</FormLabel>
                    </Box>
                  </Hide>
                  <Box p="5px 14px 14px 14px" w="full" bg={modeLabelBg}>
                    <Show below="2xl">
                      <FormLabel>URL</FormLabel>
                    </Show>
                    <Input
                      mt={2}
                      value={passTemplateCtx.googleAppLinkPlayStoreUrl}
                      variant="filledForDarkBg"
                      id="googleAppLinkPlayStoreUrl"
                      type="text"
                      placeholder="Type a valid https url here..."
                      onChange={(e) => {
                        passTemplateCtx.updateGoogleAppLinkPlayStoreUrl(
                          e.target.value
                        );
                        if (props.isEdit) {
                          props.setEditedFields({
                            ...props.editedFields,
                            google_app_link_play_store_url: e.target.value,
                          });
                        }
                      }}
                      onBlur={() => {
                        const value = passTemplateCtx.googleAppLinkPlayStoreUrl;
                        if (value.trim() === '') {
                          passTemplateCtx.updateGoogleAppLinkPlayStoreUrl('');
                          if (props.isEdit) {
                            props.setEditedFields({
                              ...props.editedFields,
                              google_app_link_play_store_url: '',
                            });
                          }
                        }
                      }}
                      autoComplete="off"
                      disabled={
                        passTemplateCtx.googleAppLinkPackageName !== '' ||
                        passTemplateCtx.googleAppLinkWebAppUrl !== ''
                      }
                    />
                    <FormErrorMessage></FormErrorMessage>
                  </Box>
                </Flex>
              </FormControl>
              <FormControl bg={modeFormControlBg} borderRadius="15px">
                <Flex alignItems="center">
                  <Hide below="2xl">
                    <Box pl="24px" flexShrink={0} w="240px">
                      <Flex direction="row" gap={1} align="center">
                        <FormLabel m={0}>Package name</FormLabel>
                        <Tooltip
                          label={
                            <Text>
                              The unique identifier of your app on the Google
                              Play Store.
                              <Text
                                display="inline"
                                position="relative"
                                top="-1"
                                fontSize="7px"
                              >
                                ®
                              </Text>{' '}
                              (e.g. com.example.app).
                            </Text>
                          }
                          hasArrow
                          placement="top"
                          fontSize="12px"
                          bg={bgTooltip}
                          color={textTooltip}
                          w="205px"
                          borderRadius="6px"
                          textAlign="center"
                          p="10px"
                        >
                          <Box
                            onClick={(e) => showTooltipHandler(e, 'appLink')}
                            ref={tooltipRef}
                            mb={1}
                            cursor="pointer"
                          >
                            <CustomExclamationMarkInverseIcon
                              boxSize="14px"
                              mode={'light'}
                            />
                          </Box>
                        </Tooltip>
                      </Flex>
                    </Box>
                  </Hide>
                  <Box p="5px 14px 14px 14px" w="full" bg={modeLabelBg}>
                    <Show below="2xl">
                      <FormLabel>Package name</FormLabel>
                    </Show>
                    <Input
                      mt={2}
                      value={passTemplateCtx.googleAppLinkPackageName}
                      variant="filledForDarkBg"
                      id="googleAppLinkPackageName"
                      type="text"
                      placeholder="Type a package name here..."
                      onChange={(e) => {
                        passTemplateCtx.updateGoogleAppLinkPackageName(
                          e.target.value
                        );
                        if (props.isEdit) {
                          props.setEditedFields({
                            ...props.editedFields,
                            google_app_link_package_name: e.target.value,
                          });
                        }
                      }}
                      onBlur={() => {
                        const value = passTemplateCtx.googleAppLinkPackageName;
                        if (value.trim() === '') {
                          passTemplateCtx.updateGoogleAppLinkPackageName('');
                          if (props.isEdit) {
                            props.setEditedFields({
                              ...props.editedFields,
                              google_app_link_package_name: '',
                            });
                          }
                        }
                      }}
                      autoComplete="off"
                      disabled={
                        passTemplateCtx.googleAppLinkPlayStoreUrl !== '' ||
                        passTemplateCtx.googleAppLinkWebAppUrl !== ''
                      }
                    />
                    <FormErrorMessage></FormErrorMessage>
                  </Box>
                </Flex>
              </FormControl>
              <Divider borderColor={modeBorderColor} />
              <FormControl bg={modeFormControlBg} borderRadius="15px">
                <Flex alignItems="center">
                  <Hide below="2xl">
                    <Box pl="24px" flexShrink={0} w="240px">
                      <Flex
                        direction="row"
                        align="center"
                        alignContent="center"
                        alignItems="center"
                        gap={1}
                      >
                        <FormLabel m={0}>Web app URL</FormLabel>
                      </Flex>
                      <Text
                        color={modeHelperText}
                        textStyle="bodyFamilyRegular"
                        fontSize="12px"
                      >
                        Android version
                      </Text>
                    </Box>
                  </Hide>
                  <Box p="14px" w="full" bg={modeLabelBg}>
                    <Show below="2xl">
                      <Flex
                        direction="row"
                        align="center"
                        alignContent="center"
                        alignItems="center"
                        gap={1}
                      >
                        <FormLabel m={0}>Web app URL</FormLabel>
                      </Flex>
                      <Text
                        color={modeHelperText}
                        textStyle="bodyFamilyRegular"
                        fontSize="12px"
                      >
                        Android version
                      </Text>
                    </Show>
                    <Input
                      mt={2}
                      value={passTemplateCtx.googleAppLinkWebAppUrl}
                      variant="filledForDarkBg"
                      id="googleAppLinkWebAppUrl"
                      type="text"
                      placeholder="Type a valid https url here..."
                      onChange={(e) => {
                        passTemplateCtx.updateGoogleAppLinkWebAppUrl(
                          e.target.value
                        );
                        if (props.isEdit) {
                          props.setEditedFields({
                            ...props.editedFields,
                            google_app_link_web_app_url: e.target.value,
                          });
                        }
                      }}
                      onBlur={() => {
                        const value = passTemplateCtx.googleAppLinkWebAppUrl;
                        if (value.trim() === '') {
                          passTemplateCtx.updateGoogleAppLinkWebAppUrl('');
                          if (props.isEdit) {
                            props.setEditedFields({
                              ...props.editedFields,
                              google_app_link_web_app_url: '',
                            });
                          }
                        }
                      }}
                      autoComplete="off"
                      disabled={
                        passTemplateCtx.googleAppLinkPlayStoreUrl !== '' ||
                        passTemplateCtx.googleAppLinkPackageName !== ''
                      }
                    />
                    <FormErrorMessage></FormErrorMessage>
                  </Box>
                </Flex>
              </FormControl>
            </>
          </Box>
        </Box>
        <Box
          className="autofillForDarkBg"
          borderRadius="15px 15px 0 0"
          w="full"
          mt="20px"
        >
          <Box
            className="primaryBoxShadow"
            bg={modeFormBoxBg}
            borderRadius="15px"
            w="full"
          >
            <Stack
              p="14px 14px 14px 24px"
              borderRadius="15px 15px 0 0"
              bg={modeTextBg}
              direction={{ base: 'column', '2xl': 'row' }}
            >
              <Text fontSize="18px" textStyle="headingFamilyMedium">
                Notifications
              </Text>
            </Stack>
            <Divider borderColor={modeBorderColor} />
            <FormControl isRequired bg={modeFormControlBg}>
              <Flex>
                <Hide below="2xl">
                  <Box pl="24px" flexShrink={0} w="240px">
                    <FormLabel m={0} pt="18px">
                      Notification badge
                    </FormLabel>
                    <Text
                      color={modeHelperText}
                      textStyle="bodyFamilyRegular"
                      fontSize="12px"
                    >
                      Android version
                    </Text>
                  </Box>
                </Hide>
                <Box p="14px" w="full" bg={modeLabelBg}>
                  <Show below="2xl">
                    <FormLabel>Notification badge</FormLabel>
                    <Text
                      color={modeHelperText}
                      textStyle="bodyFamilyRegular"
                      fontSize="12px"
                    >
                      Android version
                    </Text>
                  </Show>
                  <MonochromeBadge
                    editedImages={props.editedImages}
                    setEditedImages={props.setEditedImages}
                  />
                </Box>
              </Flex>
            </FormControl>
            <Divider borderColor={modeBorderColor} />
            <FormControl isRequired bg={modeFormControlBg} borderRadius="15px">
              <Flex alignItems="center">
                <Hide below="2xl">
                  <Box pl="24px" flexShrink={0} w="240px">
                    <FormLabel m={0}>Message header</FormLabel>
                  </Box>
                </Hide>
                <Box
                  p="14px"
                  w="full"
                  bg={modeLabelBg}
                  borderRadius={{ base: '0 0 15px 15px', '2xl': '0 0 15px 0' }}
                >
                  <Show below="2xl">
                    <FormLabel>Message header</FormLabel>
                  </Show>
                  <Input
                    value={passTemplateCtx.notificationHeader}
                    variant="filledForDarkBg"
                    id="notificationHeader"
                    type="text"
                    placeholder="Type a message header here..."
                    onChange={(e) => {
                      passTemplateCtx.updateNotificationHeader(e.target.value);
                      if (props.isEdit) {
                        props.setEditedFields({
                          ...props.editedFields,
                          notification_header: e.target.value,
                        });
                      }
                    }}
                    onBlur={() => {
                      const value = passTemplateCtx.notificationHeader;
                      if (value.trim() === '') {
                        passTemplateCtx.updateNotificationHeader('');
                        if (props.isEdit) {
                          props.setEditedFields({
                            ...props.editedFields,
                            notification_header: '',
                          });
                        }
                      }
                    }}
                    autoComplete="off"
                    maxLength={40}
                  />
                  <FormErrorMessage></FormErrorMessage>
                </Box>
              </Flex>
            </FormControl>
          </Box>
        </Box>
        <Box className="autofillForDarkBg" mt="20px" borderRadius="15px">
          <Box className="primaryBoxShadow" bg={modeBoxBg} borderRadius="15px">
            <Stack
              p="14px 14px 14px 24px"
              borderRadius={
                passTemplateCtx.locationCount > 0 ? '15px 15px 0 0' : '15px'
              }
              bg={modeTextBg}
              direction={{ base: 'column', '2xl': 'row' }}
            >
              <Flex
                direction="row"
                justifyContent="space-between"
                align="center"
                w="full"
                pr={3}
              >
                <Flex direction="row" align="center" gap={1.5}>
                  <Text fontSize="18px" textStyle="headingFamilyMedium">
                    Locations
                  </Text>
                  <Tooltip
                    label={
                      'Pass holders will receive a notification when they are within a set distance radius of the location. Maximum 10 locations.'
                    }
                    hasArrow
                    placement="top"
                    fontSize="12px"
                    bg={bgTooltip}
                    color={textTooltip}
                    w="205px"
                    borderRadius="6px"
                    textAlign="center"
                    p="10px"
                  >
                    <Box
                      onClick={(e) => showTooltipHandler(e, 'locations')}
                      ref={tooltipRef}
                      cursor="pointer"
                    >
                      <CustomExclamationMarkInverseIcon
                        boxSize="15px"
                        mode={'light'}
                      />
                    </Box>
                  </Tooltip>
                  <Text
                    color={modeHelperText}
                    textStyle="bodyFamilyRegular"
                    fontSize="13px"
                    pt={1}
                  >
                    iOS devices only
                  </Text>
                </Flex>
                {passTemplateCtx.locationCount === 0 ? (
                  <Button
                    size="xs"
                    onClick={handleLocationCountChange}
                    w="100px"
                    isDisabled={
                      (!passTemplateCtx.locations[activeLocationStep] &&
                        passTemplateCtx.locationCount > 0) ||
                      (!!passTemplateCtx.locations[activeLocationStep] &&
                        !passTemplateCtx.locations[activeLocationStep].name)
                    }
                  >
                    <CustomPlusIcon />
                    <Text ml={1.5}>Add</Text>
                  </Button>
                ) : (
                  <Text fontSize="12px">
                    Location {activeLocationStep + 1} of{' '}
                    {passTemplateCtx.locationCount}
                  </Text>
                )}
              </Flex>
            </Stack>
            <Divider borderColor={modeBorderColor} />
            <Box
              w="full"
              bg={modeTextBg}
              borderRadius={passTemplateCtx.locationCount > 0 ? '15px' : '15px'}
            >
              {passTemplateCtx.locationCount > 0 && (
                <>
                  <LocationDetails
                    isEdit={props.isEdit}
                    activeStep={activeLocationStep}
                    editedFields={props.editedFields}
                    setEditedFields={props.setEditedFields}
                    handleLocationCountChange={handleLocationCountChange}
                  />
                  <Flex
                    alignItems="center"
                    bg={modeFormControlBg}
                    borderRadius="0 0 15px 15px"
                  >
                    <Hide below="2xl">
                      <Box
                        pl="24px"
                        flexShrink={0}
                        w="240px"
                        borderRadius="15px"
                      ></Box>
                    </Hide>
                    <Box
                      px="14px"
                      w="full"
                      bg={modeLabelBg}
                      borderRadius={{
                        base: '0 0 15px 15px',
                        '2xl': '0 0 15px 0',
                      }}
                    >
                      <Show below="2xl"></Show>
                      <Flex
                        direction="row"
                        justifyContent="space-between"
                        pt={4}
                        pb={6}
                        px={1.5}
                      >
                        <Flex order={{ base: 2, md: 1 }}>
                          <IconButton
                            onClick={() =>
                              setActiveLocationStep(activeLocationStep - 1)
                            }
                            isDisabled={activeLocationStep === 0}
                            icon={
                              <CustomChevronLeftIcon
                                w="24px"
                                h="24px"
                                color={modePrevNext}
                              />
                            }
                            variant="white"
                            size="sm"
                            w="36px"
                          />
                        </Flex>
                        <Flex
                          order={2}
                          width={{ base: '100%', md: 'auto' }}
                          justifyContent={{ base: 'center', md: 'none' }}
                          mb={{ base: '12px', md: '0' }}
                          alignItems="center"
                          fontSize="12px"
                        >
                          <Hide below="sm">
                            <Text flexShrink="0">Go to:</Text>{' '}
                          </Hide>
                          <NumberInput
                            size="sm"
                            ml={2}
                            w="72px"
                            min={1}
                            max={passTemplateCtx.locationCount}
                            onChange={(value) => {
                              setActiveLocationStep(Number(value) - 1);
                            }}
                            value={activeLocationStep + 1}
                          >
                            <NumberInputField />
                            <NumberInputStepper>
                              <NumberIncrementStepper
                                children={
                                  <CustomChevronLeftIcon
                                    transform="rotate(90deg)"
                                    w="18px"
                                    h="18px"
                                    color={modeStepperColor}
                                  />
                                }
                              />
                              <NumberDecrementStepper
                                children={
                                  <CustomChevronLeftIcon
                                    transform="rotate(270deg)"
                                    w="18px"
                                    h="18px"
                                    color={modeStepperColor}
                                  />
                                }
                              />
                            </NumberInputStepper>
                          </NumberInput>
                        </Flex>
                        <Flex order={{ base: 3, md: 3 }}>
                          <IconButton
                            onClick={() =>
                              setActiveLocationStep(activeLocationStep + 1)
                            }
                            isDisabled={
                              activeLocationStep ===
                              passTemplateCtx.locationCount - 1
                            }
                            icon={
                              <CustomChevronLeftIcon
                                transform="rotate(180deg)"
                                w="24px"
                                h="24px"
                                color={modePrevNext}
                              />
                            }
                            variant="white"
                            size="sm"
                            w="36px"
                          />
                        </Flex>
                      </Flex>
                    </Box>
                  </Flex>
                </>
              )}
            </Box>
          </Box>
        </Box>
        <Box className="autofillForDarkBg" mt="20px" borderRadius="15px">
          <Box className="primaryBoxShadow" bg={modeBoxBg} borderRadius="15px">
            <Stack
              p="14px 14px 14px 24px"
              borderRadius={
                passTemplateCtx.beaconCount > 0 ? '15px 15px 0 0' : '15px'
              }
              bg={modeTextBg}
              direction={{ base: 'column', '2xl': 'row' }}
            >
              <Flex
                direction="row"
                justifyContent="space-between"
                align="center"
                w="full"
                pr={3}
              >
                <Flex direction="row" align="center" gap={1.5}>
                  <Text fontSize="18px" textStyle="headingFamilyMedium">
                    Beacons
                  </Text>
                  <Tooltip
                    label={
                      'Pass holders will receive a notification when they are within the vicinity of the beacon. Maximum 10 beacons.'
                    }
                    hasArrow
                    placement="top"
                    fontSize="12px"
                    bg={bgTooltip}
                    color={textTooltip}
                    w="205px"
                    borderRadius="6px"
                    textAlign="center"
                    p="10px"
                  >
                    <Box
                      onClick={(e) => showTooltipHandler(e, 'beacons')}
                      ref={tooltipRef}
                      cursor="pointer"
                    >
                      <CustomExclamationMarkInverseIcon
                        boxSize="15px"
                        mode={'light'}
                      />
                    </Box>
                  </Tooltip>
                  <Text
                    color={modeHelperText}
                    textStyle="bodyFamilyRegular"
                    fontSize="13px"
                    pt={1}
                  >
                    iOS devices only
                  </Text>
                </Flex>
                {!passTemplateCtx.beacons.length ||
                passTemplateCtx.beaconCount === 0 ? (
                  <Button size="xs" onClick={handleBeaconCountChange} w="100px">
                    <CustomPlusIcon />
                    <Text ml={1.5}>Add</Text>
                  </Button>
                ) : (
                  <Text fontSize="12px">
                    Beacon {activeBeaconStep + 1} of{' '}
                    {passTemplateCtx.beaconCount}
                  </Text>
                )}
              </Flex>
            </Stack>
            <Divider borderColor={modeBorderColor} />
            <Box
              w="full"
              bg={modeTextBg}
              borderRadius={passTemplateCtx.beaconCount > 0 ? '15px' : '15px'}
            >
              {passTemplateCtx.beaconCount > 0 && (
                <>
                  <BeaconDetails
                    isEdit={props.isEdit}
                    activeStep={activeBeaconStep}
                    editedFields={props.editedFields}
                    setEditedFields={props.setEditedFields}
                    handleBeaconCountChange={handleBeaconCountChange}
                  />
                  <Flex
                    alignItems="center"
                    bg={modeFormControlBg}
                    borderRadius="0 0 15px 15px"
                  >
                    <Hide below="2xl">
                      <Box
                        pl="24px"
                        flexShrink={0}
                        w="240px"
                        borderRadius="15px"
                      ></Box>
                    </Hide>
                    <Box
                      px="14px"
                      w="full"
                      bg={modeLabelBg}
                      borderRadius={{
                        base: '0 0 15px 15px',
                        '2xl': '0 0 15px 0',
                      }}
                    >
                      <Show below="2xl"></Show>
                      <Flex
                        direction="row"
                        justifyContent="space-between"
                        pt={4}
                        pb={6}
                        px={1.5}
                      >
                        <Flex order={{ base: 2, md: 1 }}>
                          <IconButton
                            onClick={() =>
                              setActiveBeaconStep(activeBeaconStep - 1)
                            }
                            isDisabled={activeBeaconStep === 0}
                            icon={
                              <CustomChevronLeftIcon
                                w="24px"
                                h="24px"
                                color={modePrevNext}
                              />
                            }
                            variant="white"
                            size="sm"
                            w="36px"
                          />
                        </Flex>
                        <Flex
                          order={2}
                          width={{ base: '100%', md: 'auto' }}
                          justifyContent={{ base: 'center', md: 'none' }}
                          mb={{ base: '12px', md: '0' }}
                          alignItems="center"
                          fontSize="12px"
                        >
                          <Hide below="sm">
                            <Text flexShrink="0">Go to:</Text>{' '}
                          </Hide>
                          <NumberInput
                            size="sm"
                            ml={2}
                            w="72px"
                            min={1}
                            max={passTemplateCtx.beaconCount}
                            onChange={(value) => {
                              setActiveBeaconStep(Number(value) - 1);
                            }}
                            value={activeBeaconStep + 1}
                          >
                            <NumberInputField />
                            <NumberInputStepper>
                              <NumberIncrementStepper
                                children={
                                  <CustomChevronLeftIcon
                                    transform="rotate(90deg)"
                                    w="18px"
                                    h="18px"
                                    color={modeStepperColor}
                                  />
                                }
                              />
                              <NumberDecrementStepper
                                children={
                                  <CustomChevronLeftIcon
                                    transform="rotate(270deg)"
                                    w="18px"
                                    h="18px"
                                    color={modeStepperColor}
                                  />
                                }
                              />
                            </NumberInputStepper>
                          </NumberInput>
                        </Flex>
                        <Flex order={{ base: 3, md: 3 }}>
                          <IconButton
                            onClick={() =>
                              setActiveBeaconStep(activeBeaconStep + 1)
                            }
                            isDisabled={
                              activeBeaconStep ===
                              passTemplateCtx.beaconCount - 1
                            }
                            icon={
                              <CustomChevronLeftIcon
                                transform="rotate(180deg)"
                                w="24px"
                                h="24px"
                                color={modePrevNext}
                              />
                            }
                            variant="white"
                            size="sm"
                            w="36px"
                          />
                        </Flex>
                      </Flex>
                    </Box>
                  </Flex>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default TemplateDetails;
