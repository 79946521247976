import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Hide,
  Show,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import moment from 'moment';
import { useContext } from 'react';
import ReadersContext from '../../../store/client/ReadersContext';
import ReadersRenderStatus from './ReadersRenderStatus';
import ReaderField from './ReaderField';

const ReadersFieldsView = () => {
  const readersCtx = useContext(ReadersContext);
  const modeBoxBg = useColorModeValue('gradient', 'secondaryDark');
  const modeTextBg = useColorModeValue('quaternaryBackground', 'primaryDark06');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const modeFormControlBg = useColorModeValue('#fff', 'secondaryDark');
  const modeLabelBg = useColorModeValue('#EFEFEF', 'primaryDark03');

  let readerFields = [
    { name: 'Reader name', value: readersCtx.readerState?.name },
    { name: 'Reader ID', value: readersCtx.readerState?.uuid },
    { name: 'Reader type', value: readersCtx.readerState?.type },
  ];

  let passentryReaderFields = [
    {
      name: 'Scanning',
      value: readersCtx.readerState?.scanEnabled ? 'Enabled' : 'Disabled',
    },
    {
      name: 'Redeeming',
      value: readersCtx.readerState?.redeemEnabled ? 'Enabled' : 'Disabled',
    },
    {
      name: 'Adding Points',
      value: readersCtx.readerState?.addPointsEnabled ? 'Enabled' : 'Disabled',
    },
    {
      name: 'QR scanning',
      value: readersCtx.readerState?.qrEnabled ? 'Enabled' : 'Disabled',
    },
    {
      name: 'Mifare Classic NFC',
      value: readersCtx.readerState?.mifareClassicNfcEnabled
        ? 'Enabled'
        : 'Disabled',
    },
  ];

  return (
    <Box
      className="autofillForDarkBg"
      w="full"
      borderRadius="15px"
      overflow="hidden"
    >
      <Box className="primaryBoxShadow" bg={modeBoxBg} borderRadius="15px">
        <Text
          fontSize="18px"
          textStyle="headingFamilyMedium"
          p="14px 14px 14px 24px"
          bg={modeTextBg}
          borderRadius="15px 15px 0 0"
        >
          Reader info
        </Text>
        <Divider borderColor={modeBorderColor} />

        {readersCtx.readerState.type === 'PassentryReader' && (
          <>
            <FormControl
              borderTopLeftRadius="15px"
              borderTopRightRadius="15px"
              bg={modeFormControlBg}
            >
              <Flex alignItems="center">
                <Hide below="2xl">
                  <Box pl="24px" flexShrink={0} w="240px">
                    <FormLabel m={0}>Reader status</FormLabel>
                  </Box>
                </Hide>
                <Box p="14px" pl="24px" w="full" bg={modeLabelBg}>
                  <Show below="2xl">
                    <FormLabel>Reader status</FormLabel>
                  </Show>

                  <ReadersRenderStatus
                    loggedIn={readersCtx.readerState.loggedIn}
                  />
                </Box>
              </Flex>
            </FormControl>
            <Divider borderColor={modeBorderColor} />
            {readersCtx.readerState.loggedIn && (
              <>
                <FormControl
                  borderTopLeftRadius="15px"
                  borderTopRightRadius="15px"
                  bg={modeFormControlBg}
                >
                  <Flex alignItems="center">
                    <Hide below="2xl">
                      <Box pl="24px" flexShrink={0} w="240px">
                        <FormLabel m={0}>Last seen at</FormLabel>
                      </Box>
                    </Hide>
                    <Box p="14px" pl="24px" w="full" bg={modeLabelBg}>
                      <Show below="2xl">
                        <FormLabel>Last seen at</FormLabel>
                      </Show>

                      <Text>
                        {readersCtx.readerState.lastSeenAt
                          ? moment(readersCtx.readerState.lastSeenAt).fromNow()
                          : ''}
                      </Text>
                    </Box>
                  </Flex>
                </FormControl>
                <Divider borderColor={modeBorderColor} />
              </>
            )}
          </>
        )}

        {readerFields.map((field, index) => {
          return (
            <ReaderField
              key={index}
              field={field}
              index={index}
              readerFields={readerFields}
            />
          );
        })}
        {readersCtx.readerState.type === 'PassentryReader' &&
          passentryReaderFields.map((field, index) => {
            return (
              <ReaderField
                key={index}
                field={field}
                index={index}
                readerFields={passentryReaderFields}
              />
            );
          })}
      </Box>
    </Box>
  );
};
export default ReadersFieldsView;
