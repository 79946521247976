import {
  Heading,
  Input,
  Center,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  VStack,
  Button,
  Box,
  Text,
  useToast,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import CardForm from '../../components/common/CardForm';
import {
  REGEX_CONTAINS_BOTH_LOWER_AND_UPPERCASE,
  REGEX_HAS_AT_LEAST_ONE_NUMBER,
  REGEX_HAS_SYMBOL,
  lengthInBetween,
  hasLowerAndUpperCase,
  hasSymbol,
  hasDigit,
} from '../../utils/validations';
import { CustomCheckIcon } from '../../theme/icons/CustomCheckIcon';
import { CustomExclamationMarkIcon } from '../../theme/icons/CustomExclamationMarkIcon';
import { CustomVisibilityIcon } from '../../theme/icons/CustomVisibilityIcon';
import { CustomVisibilityOffIcon } from '../../theme/icons/CustomVisibilityOffIcon';
import { FORM_PADDING_TOP_XL } from '../../utils/consts';
import { useParams } from 'react-router';
import { useContext, useState } from 'react';
import CustomToast from '../../common/CustomToast';
import { getErrorResponsePayload } from '../../utils/ajax';
import { HttpContext } from '../../context/HttpContext';

function ResetPasswordPage() {
  const { resetToken } = useParams();
  const toast = useToast();
  const { publicAxios } = useContext(HttpContext);
  const [show, setShow] = useState(false);

  const showPasswordClickHandler = () => setShow(!show);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required()
      .min(6)
      .max(30)
      .matches(REGEX_CONTAINS_BOTH_LOWER_AND_UPPERCASE)
      .matches(REGEX_HAS_AT_LEAST_ONE_NUMBER)
      .matches(REGEX_HAS_SYMBOL),
    passwordConfirm: Yup.string()
      .required('Please re-enter your password')
      .oneOf([Yup.ref('password'), null], 'Password doesn’t match. Try again.'),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
    watch,
    getFieldState,
    setError,
  } = useForm(formOptions);

  const watchPassword = watch('password');
  const fieldStatePassword = getFieldState('password');

  function onSubmit(values) {
    return new Promise((resolve) => {
      const data = {
        plainPassword: values.password,
        token: resetToken,
      };

      publicAxios
        .post(`accounts/me/password/reset`, data)
        .then((res) => {
          const { message } = res.data;
          // if (token) {
          //   setAuthState({ token, account, refreshToken: refresh_token });
          //   reset();
          // }
          if (message === 'Password updated successfully') {
            toast({
              render: (props) => (
                <CustomToast
                  status="success"
                  title="Password updated successfully."
                  description="You can now login with your new password."
                  onClose={props.onClose}
                  id={props.id}
                />
              ),
            });
          }
        })
        .catch((onError) => {
          console.log(onError);
          const { errors } = getErrorResponsePayload(onError);
          if (errors && errors.plainPassword && errors.plainPassword.errors) {
            const message = errors.plainPassword.errors[0];
            setError('password', { type: 'server', message });
          } else if (errors && errors.token && errors.token.errors) {
            const message = errors.token.errors[0];
            toast({
              render: (props) => (
                <CustomToast
                  status="error"
                  title="Something went wrong"
                  description={message}
                  onClose={props.onClose}
                  id={props.id}
                />
              ),
            });
          }
        });
      // .finally(() => {
      //   reset();
      // });
      resolve();
    });
  }

  return (
    <Center pt={FORM_PADDING_TOP_XL} pb="10px">
      <CardForm>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={5} alignItems="flex-start">
            <VStack spacing={2} alignItems="flex-start">
              <Heading size="2xl">Reset password</Heading>
              <Text textStyle="bodyRoman" color="secondaryDark08">
                Great passwords use upper &amp; lower case letters, combined with numbers
                and special characters.
              </Text>
            </VStack>
            <FormControl isRequired isInvalid={errors.password}>
              <FormLabel>New password</FormLabel>
              <InputGroup>
                <Input
                  id="password"
                  type={show ? 'text' : 'password'}
                  placeholder="Your password"
                  {...register('password')}
                />
                <InputRightElement>
                  {show ? (
                    <CustomVisibilityOffIcon
                      onClick={showPasswordClickHandler}
                      boxSize="18px"
                      cursor="pointer"
                      color="secondaryDark06"
                    />
                  ) : (
                    <CustomVisibilityIcon
                      onClick={showPasswordClickHandler}
                      boxSize="18px"
                      cursor="pointer"
                      color="secondaryDark06"
                    />
                  )}
                </InputRightElement>
              </InputGroup>
              {fieldStatePassword.invalid && (
                <FormHelperText>
                  <VStack spacing={0} alignItems="left">
                    <Box color="error">Please enter your password:</Box>
                    <Box
                      color={lengthInBetween(watchPassword, 6, 30) ? 'success' : 'error'}
                    >
                      {lengthInBetween(watchPassword, 6, 30) ? (
                        <CustomCheckIcon boxSize={2} mr={1} />
                      ) : (
                        <CustomExclamationMarkIcon boxSize={2} mr={1} />
                      )}
                      contains at least 6 and maximum 30 characters
                    </Box>
                    <Box
                      color={hasLowerAndUpperCase(watchPassword) ? 'success' : 'error'}
                    >
                      {hasLowerAndUpperCase(watchPassword, 6, 30) ? (
                        <CustomCheckIcon boxSize={2} mr={1} />
                      ) : (
                        <CustomExclamationMarkIcon boxSize={2} mr={1} />
                      )}
                      contains both lower (a-z) and upper case letters (A-Z)
                    </Box>
                    <Box
                      color={
                        hasDigit(watchPassword) && hasSymbol(watchPassword)
                          ? 'success'
                          : 'error'
                      }
                    >
                      {hasDigit(watchPassword) && hasSymbol(watchPassword) ? (
                        <CustomCheckIcon boxSize={2} mr={1} />
                      ) : (
                        <CustomExclamationMarkIcon boxSize={2} mr={1} />
                      )}
                      contains at least one number (0-9) and a symbol
                    </Box>
                  </VStack>
                </FormHelperText>
              )}
            </FormControl>
            <FormControl isRequired isInvalid={errors.passwordConfirm}>
              <FormLabel>Re-enter new password</FormLabel>
              <InputGroup>
                <Input
                  id="passwordConfirm"
                  type={show ? 'text' : 'password'}
                  placeholder="Re-enter your password"
                  {...register('passwordConfirm')}
                />
                <InputRightElement>
                  {show ? (
                    <CustomVisibilityOffIcon
                      onClick={showPasswordClickHandler}
                      boxSize="18px"
                      cursor="pointer"
                      color="secondaryDark06"
                    />
                  ) : (
                    <CustomVisibilityIcon
                      onClick={showPasswordClickHandler}
                      boxSize="18px"
                      cursor="pointer"
                      color="secondaryDark06"
                    />
                  )}
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>
                {errors.passwordConfirm?.message || errors.passwordConfirm}
              </FormErrorMessage>
            </FormControl>
            <Box w="full">
              <Button
                type="submit"
                isFullWidth
                isLoading={isSubmitting}
                disabled={!isDirty || !isValid || isSubmitting}
                mt={3}
                alt="Reset password"
              >
                Reset password
              </Button>
            </Box>
          </VStack>
        </form>
      </CardForm>
    </Center>
  );
}

export default ResetPasswordPage;
