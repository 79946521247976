import appsignal from '../../../appsignal';
import {
  useMemo,
  useContext,
  useRef,
  useCallback,
  useState,
  useEffect,
} from 'react';
import PassesListTable from './PassesListTable';
import PassesListContext from '../../../store/client/PassesListContext';
import { useMediaQuery, useToast } from '@chakra-ui/react';
import CustomToast from '../../../common/CustomToast';
import { getErrorResponsePayload } from '../../../utils/ajax';
import { HttpContext } from '../../../context/HttpContext';
import { MeContext } from '../../../context/MeContext';
// import makeData from './makeData';

const PassesList = () => {
  const { authAxios } = useContext(HttpContext);
  const passesListCtx = useContext(PassesListContext);
  const {
    passState,
    passState: { items, total, pageCount, loading },
    updatePassState,
  } = useContext(PassesListContext);
  const [query, setQuery] = useState('');
  const [isLargerThan1000px] = useMediaQuery('(max-width: 1000px)');
  const toast = useToast();
  const fetchIdRef = useRef(0);
  const passListCtx = useContext(PassesListContext);
  const meCtx = useContext(MeContext);
  const { filterEntity } = meCtx.state;

  const buildExistingQueryString = (filters) => {
    const queryParams = [
      'id',
      'extId',
      'passTemplate',
      'groupTag',
      'passType',
      'deviceType',
      'status',
    ]
      .map((key) => (filters[key] ? `&${key}=${filters[key]}` : ''))
      .filter((param) => param)
      .join('');

    return queryParams;
  };

  //Initialize filter values
  useEffect(() => {
    const initialFilters = [
      'id',
      'extId',
      'status',
      'passTemplate',
      'passType',
      'groupTag',
      'deviceType',
    ].reduce(
      (acc, key) => ({ ...acc, [key]: passListCtx.filterValues[key] || '' }),
      {}
    );
    passListCtx.updateFilterValues(initialFilters);
  }, []);

  useEffect(() => {
    const createQueryPart = ([key, value]) => {
      const queryKeys = {
        id: 'uuid',
        extId: 'externalId',
        passTemplate: 'passTemplateName',
        groupTag: 'groupTag',
        passType: 'passType',
        deviceType: 'deviceType',
        status: 'status',
      };
      return value !== '' ? `&${queryKeys[key] || key}=${value}` : '';
    };
    const query = Object.entries(passesListCtx.filterValues)
      .map(createQueryPart)
      .filter((part) => part)
      .join('');

    setQuery(query);
  }, [passesListCtx.filterValues]);

  const columns = useMemo(
    () => [
      {
        Header: 'External ID',
        accessor: 'externalId',
        className: 'modeTdColor',
        style: {
          fontFamily: 'Inter Bold, sans-serif',
          fontSize: '16px',
          wordBreak: 'break-word',
          minWidth: isLargerThan1000px ? '50px' : '100px',
          width: '15%',
        },
      },
      {
        Header: 'Pass template',
        accessor: 'passTemplate',
        style: {
          minWidth: isLargerThan1000px ? '50px' : '100px',
          width: '150px',
          wordBreak: 'break-word',
        },
      },
      {
        Header: 'Issued on',
        accessor: 'issuedAt',
        style: {
          minWidth: isLargerThan1000px ? '50px' : '100px',
          width: '150px',
          wordBreak: 'break-word',
        },
      },
      {
        Header: 'Device Type',
        accessor: 'deviceType',
        style: {
          minWidth: isLargerThan1000px ? '50px' : '100px',
          width: '150px',
          wordBreak: 'break-word',
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        style: {
          width: '30px',
        },
      },
      {
        Header: 'Added to wallet',
        accessor: 'addedToWallet',
        style: {
          width: '130px',
          whiteSpace: 'nowrap',
        },
      },
      // {
      //   Header: 'Last scanned',
      //   accessor: 'lastScan',
      //   style: {
      //     width: '120px',
      //     whiteSpace: 'nowrap',
      //   },
      // },
      {
        Header: '',
        accessor: 'actionFirst',
        style: {
          width: '50px',
          paddingRight: '20px',
        },
      },
      {
        Header: '',
        accessor: 'actionSecond',
        style: {
          width: '10%',
        },
      },
      {
        Header: '',
        accessor: 'moreInfo',
        style: {
          width: '10px',
          padding: 0,
        },
      },
    ],
    []
  );

  useEffect(() => {
    const existingFilters = JSON.parse(sessionStorage.getItem('filterValues'));
    if (existingFilters) {
      const filters = {
        id: existingFilters.id || '',
        extId: existingFilters.extId || '',
        passTemplate: existingFilters.passTemplate || '',
        groupTag: existingFilters.groupTag || '',
        passType: existingFilters.passType || '',
        deviceType: existingFilters.deviceType || '',
        status: existingFilters.status || '',
      };
      passListCtx.updateFilterValues(filters);
      let countableFilterValues = [];
      Object.entries(existingFilters).forEach(([key, value]) => {
        if (!key.startsWith('by') && value !== false) {
          countableFilterValues = countableFilterValues.concat(value);
        }
      });
      passListCtx.updateTotalActiveFilters(
        countableFilterValues.filter((val) => val.length !== 0).length
      );
    }
  }, []);

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      updatePassState({
        ...passState,
        loading: true,
        status: 'pending',
      });

      setTimeout(async () => {
        if (fetchId === fetchIdRef.current) {
          try {
            let existingQuery = '';
            if (
              sessionStorage.getItem('navigatedFromPassPage') === 'true' &&
              passListCtx.totalActiveFilters > 0
            ) {
              const filters = JSON.parse(
                sessionStorage.getItem('filterValues')
              );
              existingQuery = buildExistingQueryString(filters);
            }
            const effectiveQuery = query || existingQuery || '';
            const { data } = filterEntity
              ? await authAxios.get(
                  `api/v1/entities/${filterEntity.uuid}/passes?perPage=${pageSize}&page=${
                    pageIndex + 1
                  }${effectiveQuery}`
                )
              : await authAxios.get(
                  `api/v1/passes?perPage=${pageSize}&page=${
                    pageIndex + 1
                  }${effectiveQuery}`
                );
            const passes = data?.data || []; //Need to normalize data here so each pass has correct structure
            const total = data?.meta?.totalCount ?? 0;
            updatePassState({
              ...passState,
              items: passes,
              total,
              pageCount: Math.ceil(total / pageSize),
              loading: false,
              status: 'resolved',
            });
          } catch (error) {
            appsignal.sendError(error);
            console.log(error);
            updatePassState({
              ...passState,
              items: [],
              rowNumber: 0,
              total: 0,
              pageCount: 0,
              loading: false,
              error: 'Something went wrong',
              status: 'rejected',
            });
            const { message, code } = getErrorResponsePayload(error);
            code !== 401 &&
              toast({
                render: (props) => (
                  <CustomToast
                    status="error"
                    title={message ? message : `Something went wrong`}
                    description={!message && 'Please try again later'}
                    onClose={props.onClose}
                  />
                ),
              });
          }
        }
      }, 0);
    },

    [authAxios, filterEntity, query]
  );

  const normalizedData = (data) => {
    if (data?.length > 0) {
      return data.map((item) => {
        // Format item.created_at
        const timestamp = new Date(item.attributes.createdAt);
        const formattedTimestamp = timestamp.toLocaleString('en-UK', {
          year: '2-digit',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        });
        const addedTimestamp = new Date(item.attributes.addedAt);
        const formattedAddedTimestamp = addedTimestamp.toLocaleString('en-UK', {
          year: '2-digit',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        });
        return {
          ...item,
          udi: item.attributes.extId,
          passUuid: item.id,
          passType: item.attributes.passType,
          templateName: item.attributes.passTemplateName,
          issuedAt: formattedTimestamp,
          deviceType: item.attributes.deviceType
            ? item.attributes.deviceType === 'iphone'
              ? 'Apple'
              : 'Android'
            : 'n/a',
          addedToWallet: item.attributes.addedAt
            ? formattedAddedTimestamp
            : 'n/a',
          // lastScan: item?.lastScan?.createdAt,
          exampleForNestedObject: {
            passesSinglePageUrl: '/passes/' + item.id,
          },
        };
      });
    }
    return [];
  };

  const formattedData = useMemo(() => normalizedData(items), [items]);

  // const data = useMemo(() => makeData(3), []);

  return (
    <PassesListTable
      columns={columns}
      data={formattedData}
      // data={data}
      query={query}
      fetchData={fetchData}
      loading={loading}
      pageCount={pageCount}
      total={total}
    />
  );
};

export default PassesList;
