import Intro from './styleguide/Intro';
import SectionTitle from './styleguide/SectionTitle';
import Colors from './styleguide/Colors';
import Fonts from './styleguide/Fonts';
import MonocolorIcons from './styleguide/MonocolorIcons';
import MulticolorIcons from './styleguide/MulticolorIcons';
import Illustrations from './styleguide/Illustrations';
import Buttons from './styleguide/Buttons';
import FormControls from './styleguide/FormControls';
import FlashMessages from './styleguide/FlashMessages';
import Modals from './styleguide/Modals';
import Badges from './styleguide/Badges';
import Other from './styleguide/Other';
import StaticPages from './styleguide/StaticPages';

function Styleguide() {
  return (
    <>
      <Intro />

      <SectionTitle title="Colors" />
      <Colors />

      <SectionTitle title="Typography" />
      <Fonts />

      <SectionTitle title="Monocolor icons" />
      <MonocolorIcons />

      <SectionTitle title="Multicolor icons" />
      <MulticolorIcons />

      <SectionTitle title="Illustrations" />
      <Illustrations />

      <SectionTitle title="Buttons" />
      <Buttons />

      <SectionTitle title="Form Controls" />
      <FormControls />

      <SectionTitle title="Flash Messages" />
      <FlashMessages />

      <SectionTitle title="Modals" />
      <Modals />

      <SectionTitle title="Badges" />
      <Badges />

      <SectionTitle title="Other" />
      <Other />

      {process.env.REACT_APP_STYLEGUIDE_PAGES === 'show' && (
        <>
          <SectionTitle title="Static pages" />
          <StaticPages />
        </>
      )}
    </>
  );
}

export default Styleguide;
