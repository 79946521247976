import { Button, Flex, Heading, Spacer } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import useOrganizationStatus from '../../../hooks/useOrganisationStatus';

const EventListHeader = () => {
  const { shouldRender } = useOrganizationStatus();

  return (
    <Flex
      flexDirection={{ base: 'column', sm: 'row' }}
      alignItems="center"
      flexWrap="wrap"
      mt="15px"
      py="9px"
      minH="54px"
    >
      <Heading alignSelf={{ base: 'flex-start', sm: 'center' }}>Events</Heading>
      <Spacer />
      {shouldRender && (
        <Button
          alignSelf={{ base: 'flex-start', sm: 'center' }}
          mt={{ base: '12px', sm: '0' }}
          alt="Create event"
          size="sm"
          as={RouterLink}
          to="/events/create"
          onClick={() => {
            // reset temp event state for create event
            // redirectCtx.updateEventAddedExternally(false);
            // redirectCtx.updateReaderAddedExternally(false);
            // redirectCtx.updatePassTemplateFromEventState(false);
            // redirectCtx.updatePassTemplateFromEditEventState(false);
            // redirectCtx.updateAddReaderFromPassState(false);
            // redirectCtx.updateAddReaderFromEditPassState(false);
            // redirectCtx.updateReadersArray([]);
            // redirectCtx.updateAddEventFromPassState(false);
            // redirectCtx.updateAddEventFromEditPassState(false);
          }}
        >
          Create event
        </Button>
      )}
      {/* {isSetUpConfirmed && <EventsListFilter />} */}
    </Flex>
  );
};
export default EventListHeader;
