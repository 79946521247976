import { Container, Flex, Image, Box, Link, useColorModeValue } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import logoSrc from '../assets/vectors/logo.svg';
import logoDarkModeSrc from '../assets/vectors/logoDarkMode.svg';

const HeaderPlain = (props) => {
  const modeLogoSrc = useColorModeValue(logoSrc, logoDarkModeSrc);

  return (
    <Box
      as="header"
      w="full"
      position={props.passIssuePage ? 'absolute' : 'fixed'}
      zIndex="10"
      top={props.top}
      pointerEvents="none"
    >
      <Container maxW={props.passIssuePage ? '1900px' : '1592px'}>
        <Flex
          // className="primaryBoxShadow blurFilterFallback"
          alignItems="center"
          // bg="gradient"
          h="70px"
          px="24px"
          // borderRadius="15px"
          justifyContent="space-between"
          // backdropFilter="blur(8px)"
        >
          <Link as={RouterLink} to="/" pointerEvents="auto">
            <Image h="42px" src={modeLogoSrc} alt="PassEntry" loading="lazy" />
          </Link>
        </Flex>
      </Container>
    </Box>
  );
};

export default HeaderPlain;
