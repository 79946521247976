import {
  IconButton,
  Link,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Grid,
  GridItem,
  Box,
  Divider,
  Button,
  Text,
  useDisclosure,
  useColorModeValue,
  Flex,
} from '@chakra-ui/react';
import moment from 'moment';
import { useContext, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ReadersContext from '../../../store/admin/ReadersContext';
import { CustomChevronLeftIcon } from '../../../theme/icons/CustomChevronLeftIcon';
import LogoutReaderModal from '../../modals/LogoutReaderModal';

function ReadersListMoreInfo({ cell, children }) {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const modeIconColor = useColorModeValue('secondaryDark08', '#DDDDDD');
  const modeBoxBg = useColorModeValue('white', 'secondaryDark');
  const modeTextBg = useColorModeValue('white', 'primaryDark06');
  const modeLabelBg = useColorModeValue('white', 'primaryDark03');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const {
    isOpen: isLogoutReaderOpen,
    onOpen: onLogoutReaderOpen,
    onClose: onLogoutReaderClose,
  } = useDisclosure();

  function handleMoreInfoClick(e) {
    e.stopPropagation();
    onOpen();
  }

  return (
    <>
      <IconButton
        onClick={handleMoreInfoClick}
        icon={
          <CustomChevronLeftIcon
            w="20px"
            h="20px"
            color={modeIconColor}
            transform="rotate(180deg)"
          />
        }
        variant="white"
        size="sm"
        w="36px"
        mr={1}
      />
      <Drawer onClose={onClose} isOpen={isOpen} size="md">
        <DrawerOverlay />
        <DrawerContent bg={modeBoxBg}>
          <DrawerCloseButton />
          <DrawerHeader
            borderBottomWidth="1px"
            borderBottomColor={modeBorderColor}
            pt={{ base: '16px', lg: '56px' }}
            pb={3}
            bg={modeTextBg}
          >
            Reader overview
          </DrawerHeader>
          <DrawerBody p="0" bg={modeLabelBg}>
            <Grid
              templateColumns={{ base: '150px 1fr', md: '192px 1fr' }}
              gap={0}
            >
              <GridItem
                fontFamily="Inter Medium, sans-serif"
                fontSize="12px"
                color={modeIconColor}
                textTransform="uppercase"
                lineHeight="21px"
                padding="16px 4px 16px 22px"
              >
                Reader name
              </GridItem>
              <GridItem
                fontFamily="Inter Bold, sans-serif"
                fontSize="14px"
                padding="16px 4px 16px 22px"
                bg="quinaryBackground"
              >
                <Text wordBreak="break-word">{cell.row.original.name}</Text>
              </GridItem>
              <GridItem colSpan={2} w="full">
                <Divider borderColor={modeBorderColor} />
              </GridItem>

              <GridItem
                fontFamily="Inter Medium, sans-serif"
                fontSize="12px"
                color={modeIconColor}
                textTransform="uppercase"
                lineHeight="21px"
                padding="16px 4px 16px 22px"
              >
                Type
              </GridItem>

              <GridItem
                fontFamily="Inter Medium, sans-serif"
                fontSize="14px"
                padding="16px 4px 16px 22px"
                bg="quinaryBackground"
                wordBreak="break-word"
              >
                {cell.row.original.type}
              </GridItem>
              <GridItem colSpan={2} w="full">
                <Divider borderColor={modeBorderColor} />
              </GridItem>

              <GridItem
                fontFamily="Inter Medium, sans-serif"
                fontSize="12px"
                color={modeIconColor}
                textTransform="uppercase"
                lineHeight="21px"
                padding="16px 4px 16px 22px"
              >
                Reader ID
              </GridItem>
              <GridItem
                fontSize="14px"
                padding="16px 4px 16px 22px"
                bg="quinaryBackground"
              >
                <Text wordBreak="break-word">{cell.row.original.uuid}</Text>
              </GridItem>
              <GridItem colSpan={2} w="full">
                <Divider borderColor={modeBorderColor} />
              </GridItem>

              {cell.row.original.type === 'PassentryReader' && (
                <>
                  <GridItem
                    fontFamily="Inter Medium, sans-serif"
                    fontSize="12px"
                    color={modeIconColor}
                    textTransform="uppercase"
                    lineHeight="21px"
                    padding="16px 4px 16px 22px"
                  >
                    Scanning
                  </GridItem>
                  <GridItem
                    fontSize="14px"
                    padding="16px 4px 16px 22px"
                    bg="quinaryBackground"
                  >
                    <Text wordBreak="break-word">
                      {cell.row.original.attributes.scanEnabled
                        ? 'Enabled'
                        : 'Disabled'}
                    </Text>
                  </GridItem>
                  <GridItem colSpan={2} w="full">
                    <Divider borderColor={modeBorderColor} />
                  </GridItem>

                  <GridItem
                    fontFamily="Inter Medium, sans-serif"
                    fontSize="12px"
                    color={modeIconColor}
                    textTransform="uppercase"
                    lineHeight="21px"
                    padding="16px 4px 16px 22px"
                  >
                    Redeeming
                  </GridItem>
                  <GridItem
                    fontSize="14px"
                    padding="16px 4px 16px 22px"
                    bg="quinaryBackground"
                  >
                    <Text wordBreak="break-word">
                      {cell.row.original.attributes.redeemEnabled
                        ? 'Enabled'
                        : 'Disabled'}
                    </Text>
                  </GridItem>
                  <GridItem colSpan={2} w="full">
                    <Divider borderColor={modeBorderColor} />
                  </GridItem>

                  <GridItem
                    fontFamily="Inter Medium, sans-serif"
                    fontSize="12px"
                    color={modeIconColor}
                    textTransform="uppercase"
                    lineHeight="21px"
                    padding="16px 4px 16px 22px"
                  >
                    Adding Points
                  </GridItem>
                  <GridItem
                    fontSize="14px"
                    padding="16px 4px 16px 22px"
                    bg="quinaryBackground"
                  >
                    <Text wordBreak="break-word">
                      {cell.row.original.attributes.addPointsEnabled
                        ? 'Enabled'
                        : 'Disabled'}
                    </Text>
                  </GridItem>
                  <GridItem colSpan={2} w="full">
                    <Divider borderColor={modeBorderColor} />
                  </GridItem>

                  <GridItem
                    fontFamily="Inter Medium, sans-serif"
                    fontSize="12px"
                    color={modeIconColor}
                    textTransform="uppercase"
                    lineHeight="21px"
                    padding="16px 4px 16px 22px"
                  >
                    QR scanning
                  </GridItem>
                  <GridItem
                    fontSize="14px"
                    padding="16px 4px 16px 22px"
                    bg="quinaryBackground"
                  >
                    <Text wordBreak="break-word">
                      {cell.row.original.attributes.qrEnabled
                        ? 'Enabled'
                        : 'Disabled'}
                    </Text>
                  </GridItem>
                  <GridItem colSpan={2} w="full">
                    <Divider borderColor={modeBorderColor} />
                  </GridItem>

                  <GridItem
                    fontFamily="Inter Medium, sans-serif"
                    fontSize="12px"
                    color={modeIconColor}
                    textTransform="uppercase"
                    lineHeight="21px"
                    padding="16px 4px 16px 22px"
                  >
                    Mifare Classic NFC
                  </GridItem>
                  <GridItem
                    fontSize="14px"
                    padding="16px 4px 16px 22px"
                    bg="quinaryBackground"
                  >
                    <Text wordBreak="break-word">
                      {cell.row.original.attributes.mifareClassicNfcEnabled
                        ? 'Enabled'
                        : 'Disabled'}
                    </Text>
                  </GridItem>
                  <GridItem colSpan={2} w="full">
                    <Divider borderColor={modeBorderColor} />
                  </GridItem>

                  <GridItem
                    fontFamily="Inter Medium, sans-serif"
                    fontSize="12px"
                    color={modeIconColor}
                    textTransform="uppercase"
                    lineHeight="21px"
                    padding="16px 4px 16px 22px"
                  >
                    Status
                  </GridItem>
                  <GridItem
                    fontSize="14px"
                    padding="16px 4px 16px 22px"
                    bg="quinaryBackground"
                  >
                    <Box display="inline-block">{children}</Box>
                  </GridItem>

                  <GridItem colSpan={2} w="full">
                    <Divider borderColor={modeBorderColor} />
                  </GridItem>

                  <GridItem
                    fontFamily="Inter Medium, sans-serif"
                    fontSize="12px"
                    color={modeIconColor}
                    textTransform="uppercase"
                    lineHeight="21px"
                    padding="16px 4px 16px 22px"
                  >
                    Last Seen At
                  </GridItem>
                  <GridItem
                    fontSize="14px"
                    padding="16px 4px 16px 22px"
                    bg="quinaryBackground"
                  >
                    <Text wordBreak="break-word">
                      {cell.row.original.attributes.lastSeenAt
                        ? moment(
                            cell.row.original.attributes.lastSeenAt
                          ).fromNow()
                        : ''}
                    </Text>
                  </GridItem>
                  <GridItem colSpan={2} w="full">
                    <Divider borderColor={modeBorderColor} />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      padding="16px 0px 16px 22px"
                      flexWrap="wrap"
                    >
                      {!!cell.row.original.attributes.loggedIn && (
                        <Button
                          alt="Delete reader"
                          size="xs"
                          px="30px"
                          variant="link"
                          color="brand"
                          onClick={onLogoutReaderOpen}
                        >
                          Log out reader
                        </Button>
                      )}
                      <Button
                        size="sm"
                        alt="Edit reader"
                        mr="20px"
                        ml="auto"
                        onClick={() => {
                          navigate(`/readers/edit/${cell.row.original.uuid}`);
                        }}
                      >
                        Edit reader
                      </Button>
                    </Flex>
                  </GridItem>

                  <GridItem colSpan={2} w="full">
                    <Divider borderColor={modeBorderColor} />
                  </GridItem>
                </>
              )}
            </Grid>
          </DrawerBody>
          <DrawerFooter
            borderTopWidth="1px"
            borderTopColor={modeBorderColor}
            bg={modeTextBg}
          >
            <Link
              color={useColorModeValue('secondaryDark08', '#DDDDDD')}
              fontSize="14px"
              onClick={onClose}
              mr="30px"
            >
              <Text textStyle="headingFamilyMedium">Back to list</Text>
            </Link>
            <Button
              alt="Go to reader page"
              size="sm"
              px="30px"
              alignSelf="flex-start"
              as={RouterLink}
              to={cell.row.original.exampleForNestedObject.readerSinglePageUrl}
            >
              Go to reader page
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <LogoutReaderModal
        isOpen={isLogoutReaderOpen}
        onClose={onLogoutReaderClose}
        readerId={cell.row.original.uuid}
      />
    </>
  );
}

export default ReadersListMoreInfo;
