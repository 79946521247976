import { Icon } from '@chakra-ui/react';

export const CustomDuplicateIcon = (props) => (
  <Icon viewBox="0 0 26 28" fill="none" {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="28"
      viewBox="0 0 26 28"
      fill="none"
    >
      <rect
        x="1"
        y="1"
        width="17"
        height="20"
        rx="2"
        stroke="url(#paint0_linear_9730_1554)"
        strokeWidth="2"
      />
      <rect
        x="8"
        y="7"
        width="17"
        height="20"
        rx="2"
        fill="#E8E7E7"
        stroke="#3B4851"
        strokeWidth="2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9730_1554"
          x1="0.490323"
          y1="22"
          x2="17.1417"
          y2="19.882"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF8477" />
          <stop offset="1" stop-color="#EC6759" />
        </linearGradient>
      </defs>
    </svg>
  </Icon>
);
